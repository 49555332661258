import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex, Form, Input } from 'antd';
import Button from 'components/buttons/button';
import PasswordRequirements from 'components/password-requirements';
import CustomizedRequiredMark from 'components/required-mark';
import { useLogout } from 'core/features/auth/hooks/useLogout';
import { useUpdatePassword } from 'core/features/user/hooks/useUpdatePassword';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useEffect, useState } from 'react';

function ChangePassword() {
  const [form] = Form.useForm();
  const { mutate: handleSave } = useUpdatePassword();
  const { handleLogout } = useLogout();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const password = Form.useWatch('newPassword', form);

  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const _handleSave = (values: { password: string; newPassword: string }) => {
    handleSave(
      { password: values.password, newPassword: values.newPassword },
      {
        onSuccess: () => {
          notificateSucess(t('app.mensagemAlteracaoSenha'));
          handleLogout();
        },
        onError: (err: any) => {
          if (
            err?.response?.data?.error ===
            'A senha atual inserida não corresponde à senha correta.'
          ) {
            form.setFields([
              {
                name: 'password',
                errors: [t('app.mensagemErroSenhaAtual')],
              },
            ]);
          } else {
            notificateError(t('app.mensagemErroAlteracaoSenha'));
          }
        },
      }
    );
  };

  return (
    <Form
      onSubmitCapture={(e) => {
        e.preventDefault();
        form.validateFields().then(() => _handleSave(values));
      }}
      layout="vertical"
      requiredMark={CustomizedRequiredMark}
      form={form}
    >
      <Form.Item
        label={t('app.senhaAtual')}
        rules={[
          {
            required: true,
            message: t('app.mensagemErroSenhaAtualObrigatorio'),
          },
        ]}
        name="tempPassword"
        initialValue=""
      >
        <Input.Password id="id-tour-my-profile-current-password" size="large" />
      </Form.Item>
      <Form.Item
        label={t('app.novaSenha')}
        rules={[
          {
            required: true,
            message: t('app.mensagemErroNovaSenhaObrigatorio'),
          },
          {
            min: 12,
            message: t('app.mensagemErroSenhaMinimoCaracteres'),
          },
          {
            pattern:
              /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()])[\w!@#$%^&*()]+$/,
            message: t('app.mensagemErroSenhaCaracteresEspeciais'),
          },
        ]}
        name="newPassword"
        initialValue=""
      >
        <Input.Password id="id-tour-my-profile-new-password" size="large" />
      </Form.Item>
      <PasswordRequirements password={password} />
      <Form.Item
        label={t('app.confirmarSenha')}
        rules={[
          {
            required: true,
            message: t('app.mensagemErroSenhaConfirmacao'),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error(t('app.mensagemErroSenhasDiferentes'))
              );
            },
          }),
        ]}
        name="confirmPassword"
        validateDebounce={1000}
      >
        <Input.Password id="id-tour-my-profile-confirm-password" size="large" />
      </Form.Item>
      <Flex justify="center">
        <Button
          size="md"
          id="id-tour-my-profile-button-save"
          disabled={!submittable}
          htmlType={'submit'}
        >
          {t('app.confirmarAlteracao')}
        </Button>
      </Flex>
    </Form>
  );
}

export default ChangePassword;
