import { Flex } from 'antd';
import Text from 'components/typography/text';
import { PiPencilThin } from 'react-icons/pi';
import { FiTrash2 } from 'react-icons/fi';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { StoryRoute } from 'core/features/stories/typings';

interface RouteBoxProps {
  route?: StoryRoute;
  selected: boolean;
  onSelect: () => void;
  onRemove: () => void;
  onEdit: () => void;
}

function RouteBox({
  route,
  selected,
  onSelect,
  onRemove,
  onEdit,
}: RouteBoxProps) {
  const { colorPrimary } = useU4heroColors();
  if (!route) {
    return null;
  }
  return (
    <Flex vertical gap="8px" style={{ cursor: 'pointer' }} onClick={onSelect}>
      <Flex
        style={{
          borderRadius: '10px',
          border: `1px solid ${colorPrimary}`,
          background: selected
            ? hex2rgba(colorPrimary, 0.5)
            : hex2rgba(colorPrimary, 0.2),
          padding: '8px',
        }}
        vertical
        align="center"
        justify="center"
        gap="8px"
      >
        <Text
          size="md"
          bold
          style={{
            borderRadius: '50%',
            width: `30px`,
            height: `30px`,
            background: colorPrimary,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          align="center"
          color="white"
        >
          {route.index + 1}
        </Text>
        <Text size="sm" bold color={colorPrimary}>
          {route.name}
        </Text>
      </Flex>
      <Flex justify="center" align="center" gap="16px">
        <PiPencilThin
          size={30}
          color={'white'}
          style={{
            backgroundColor: colorPrimary,
            borderRadius: '50%',
            padding: '4px',
            cursor: 'pointer',
          }}
          onClick={onEdit}
        />
        <FiTrash2
          onClick={onRemove}
          size={30}
          color="white"
          style={{
            backgroundColor: colorPrimary,
            borderRadius: '50%',
            cursor: 'pointer',
            padding: '4px',
          }}
        />
      </Flex>
    </Flex>
  );
}

export default RouteBox;
