import Layout from 'components/layout';
import { useMediaQuery } from '@chakra-ui/react';
import history from 'infra/helpers/history';
import { useEffect, useState } from 'react';
import { Flex, Form } from 'antd';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import {
  notificateError,
  notificateSucess,
  openNotification,
  showConfirm,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import CustomizedRequiredMark from 'components/required-mark';
import Button from 'components/buttons/button';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import { FiMove } from 'react-icons/fi';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { useChangeAdaptativeOrder } from 'core/features/competence/hooks/useChangeAdaptativeOrder';

function AdaptativeModeOrder() {
  const { data } = useTagsCompetence({
    skill: true,
    adaptativeMode: true,
  });
  const { mutate: changeAdaptativeOrder } = useChangeAdaptativeOrder();

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyBorderAndFont, colorGreyCard } =
    useU4heroColors();
  const [form] = Form.useForm();
  const skills = Form.useWatch('skills', form);

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const reorderedSkills = Array.from(skills);
    const [removed] = reorderedSkills.splice(result.source.index, 1);
    reorderedSkills.splice(result.destination.index, 0, removed);

    form.setFieldsValue({ skills: reorderedSkills });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        skills: data.map((skill) => ({
          id: skill.id,
          name: skill.name['pt-br'],
        })),
      });
    }
  }, [data]);

  const onSubmit = (values: any) => {
    changeAdaptativeOrder(values.skills, {
      onSuccess: () => {
        notificateSucess('Ordem adaptativa salva com sucesso!');
      },
      onError: () => {
        notificateError('Erro ao salvar ordem adaptativa!');
      },
    });
  };

  return (
    <Layout.Container>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data
            ? {
                skills: [
                  ...(data.map((skill) => ({
                    id: skill.id,
                    name: skill.name,
                  })) ?? []),
                ],
              }
            : {
                skills: [],
              }
        }
      >
        <Flex
          gap={isMobile ? '12px' : '24px'}
          align="center"
          style={{ marginBottom: '12px' }}
        >
          <BackButton
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({
                  colorPrimary,
                  isMobile,
                  onClose: () => history.goBack(),
                });
              } else {
                history.goBack();
              }
            }}
          />
          <Title primary>Gerenciar Ordem Adaptativa</Title>
        </Flex>
        <Flex vertical gap={'24px'}>
          <Flex gap="16px" justify="end" align="center">
            <Button
              size="md"
              id="id-tour-initial-tour-manager-save-button"
              htmlType="submit"
              // loading={isLoadingSave}
              loading={false}
            >
              SALVAR
            </Button>
          </Flex>

          <Form.List name="skills">
            {(fields, { add, remove }) => (
              <Flex vertical gap="16px">
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="tours">
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          display: 'flex',
                          gap: '16px',
                          flexDirection: 'column',
                        }}
                      >
                        {fields.map((field, index) => (
                          <Draggable
                            key={field.key}
                            draggableId={field.key.toString()}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Flex
                                  style={{
                                    borderRadius: '10px',
                                    background: '#F5F5F5',
                                    boxShadow:
                                      '0px 3px 4px 0px rgba(0, 0, 0, 0.10)',
                                  }}
                                  align="center"
                                  justify="space-between"
                                >
                                  <Flex gap="46px" align="center">
                                    <Flex
                                      justify="center"
                                      align="center"
                                      style={{
                                        background: hex2rgba(colorPrimary, 0.5),
                                        width: '47px',
                                        height: '44px',
                                        borderRadius: '10px 10px 10px 0px',
                                      }}
                                    >
                                      <Text
                                        size="lg"
                                        color="white"
                                        style={{ fontWeight: 600 }}
                                      >
                                        {index + 1}
                                      </Text>
                                    </Flex>
                                    <Text size="lg">
                                      {skills?.[index]?.name}
                                    </Text>
                                  </Flex>
                                  <FiMove
                                    size={isMobile ? 20 : 24}
                                    style={{ marginRight: '30px' }}
                                  />
                                </Flex>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Flex>
            )}
          </Form.List>
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default AdaptativeModeOrder;
