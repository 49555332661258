import { useMediaQuery } from '@chakra-ui/media-query';
import { Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function GroupButton({
  value,
  onChange,
  options,
}: {
  value: string | number;
  onChange: (v: string | number) => void;
  options: { label: string; value: string | number }[];
}) {
  const { colorSecondaryLight, colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex
      style={{
        borderRadius: '10px',
        background: colorSecondaryLight,
        fontSize: '12px',
        fontWeight: 400,
        padding: '4px 8px',
        marginRight: '15px',
        alignSelf: 'end',
        overflowX: 'auto',
        overflowY: 'hidden',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        whiteSpace: 'nowrap',
      }}
      gap="8px"
    >
      {options.map((item, index) => (
        <button
          type="button"
          key={index}
          style={{
            padding: '4px 8px',
            borderRadius: '10px',
            background: value === item.value ? colorPrimary : undefined,
            color: value === item.value ? 'white' : 'black',
            fontWeight: value === item.value ? 600 : 400,
            flexShrink: 0, // Prevent button from shrinking
          }}
          onClick={() => onChange(item.value)}
        >
          {item.label}
        </button>
      ))}
    </Flex>
  );
}

export default GroupButton;
