import { Flex, Form, Radio } from 'antd';

import { ITag } from 'core/features/tags/types';
import SvgIcon from 'components/SvgIcon';
import LoadingDots from 'components/V2/loading-dots';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { CgUnavailable } from 'react-icons/cg';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import { TagType } from 'core/features/competence/typings';
import { t } from 'core/resources/strings';
function PickSkill({
  value,
  onChange,
  hasTwoCompetences,
}: {
  value: {
    id: string;
    name: string;
  };
  onChange: (v: { id: string; name: string }) => void;
  hasTwoCompetences: boolean;
}) {
  const form = Form.useFormInstance();
  const tagType = Form.useWatch('tagType', form);

  const competence = Form.useWatch('competence', form);

  const { data: dataSkills } = useTagsCompetence({
    tag: competence?.id,
    disableQuery: competence === undefined,
  });
  const { colorPrimary } = useU4heroColors();

  return (
    <>
      {competence === undefined || tagType === undefined ? (
        <Flex
          gap={13}
          vertical
          style={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <LoadingDots />
          <Text color={colorPrimary} size={'sm'}>
            {tagType === TagType.Competências
              ? t('app.mensagemErroCompetencia')
              : tagType === TagType['Clima']
              ? t('app.mensagemErroClimaEscolar')
              : tagType === TagType.Treinamento
              ? t('app.mensagemErroTreinamento')
              : t('app.mensagemErroAreaDefoco')}
          </Text>
        </Flex>
      ) : null}
      {hasTwoCompetences ? (
        <Flex vertical align="center" justify="center">
          <CgUnavailable size={30} />
          <p>{t('app.mensagemDuasCompetencias')}</p>
        </Flex>
      ) : (
        <Radio.Group
          value={value?.id}
          onChange={(e) => {
            const skill = dataSkills?.find(
              (item) => item.id === e.target.value
            );
            onChange({
              id: skill?.id as string,
              name: skill?.name['pt-br'] as string,
            });
          }}
          id="id-tour-question-creation-input-skill"
        >
          <Flex wrap={'wrap'}>
            {competence !== undefined &&
              dataSkills?.map((skill: ITag) => (
                <Radio value={skill.id} key={skill.id}>
                  <Flex gap={8} align={'center'}>
                    {skill.icon ? (
                      <SvgIcon url={skill.icon} color="black" size={35} />
                    ) : null}{' '}
                    <p style={{ fontSize: '14px' }}>{skill.name['pt-br']}</p>
                  </Flex>
                </Radio>
              ))}
          </Flex>
        </Radio.Group>
      )}
    </>
  );
}

export default PickSkill;
