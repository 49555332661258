import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import { safeSpaceFlags } from 'infra/helpers/types';
import { useCurrentUser } from 'core/features/user/store';
import supportClassification from 'assets/images/white-label/support-classification.png';

function ModalClassifications({ onClose }: { onClose: () => void }) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();

  const flagsToShow = user?.whiteLabel
    ? safeSpaceFlags.map((flag) => ({
        ...flag,
        image: flag.value === 5 ? supportClassification : flag.image,
      }))
    : safeSpaceFlags;

  return (
    <Modal
      title={
        <SubTitle primary style={{ padding: '16px' }}>
          {t('app.classificacoes')}
        </SubTitle>
      }
      open={true}
      onCancel={onClose}
      width={isMobile ? '90%' : 936}
      footer={[
        <Flex align="center" justify="center" style={{ padding: '16px' }}>
          <Button
            id="id-tour-modal-practice-suggestions-close-button"
            size="md"
            onClick={onClose}
          >
            {t('app.fechar')}
          </Button>
        </Flex>,
      ]}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      className="custom-modal-initial-tour"
    >
      {flagsToShow.map((flag) => (
        <Flex vertical key={flag.value} align="center" justify="center">
          <Text
            size="lg"
            bold
            align="center"
            color="white"
            style={{ background: flag.color, width: '100%', padding: '8px' }}
          >
            "{flag.label}"
          </Text>
          <Flex
            vertical={isMobile}
            gap="16px"
            style={{ padding: '16px' }}
            align="center"
          >
            <img src={flag.image} alt={flag.label} width="122px" />
            <Text size="md" align="justify">
              {flag.description}
            </Text>
          </Flex>
        </Flex>
      ))}
    </Modal>
  );
}

export default ModalClassifications;
