import { useMediaQuery } from '@chakra-ui/react';
import { Tabs } from 'antd';
import Layout from 'components/layout';
import { useState } from 'react';
import Title from 'components/typography/title';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';
import SafeSpaceTable from 'ui/pages/app/safeSpace/components/SafeSpaceTable';

function SafeSpace() {
  const [isSmallMobile] = useMediaQuery('(max-width: 380px)');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSelected, setIsSelected] = useState('3');
  const { colorGreyBorderAndFont } = useU4heroColors();

  const items = [
    {
      key: '1',
      label: (
        <p
          style={
            isMobile
              ? {
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '1' !== isSelected ? colorGreyBorderAndFont : '',
                }
              : {
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '1' !== isSelected ? colorGreyBorderAndFont : '',
                }
          }
        >
          {t('app.naoLidas').toUpperCase()}
        </p>
      ),
      children: <SafeSpaceTable unseen />,
    },
    {
      key: '2',
      label: (
        <p
          style={
            isMobile
              ? {
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '2' !== isSelected ? colorGreyBorderAndFont : '',
                }
              : {
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '2' !== isSelected ? colorGreyBorderAndFont : '',
                }
          }
        >
          {t('app.emProcessamento').toUpperCase()}
        </p>
      ),
      children: <SafeSpaceTable showNotProcessed />,
    },
    {
      key: '3',
      label: (
        <p
          style={
            isMobile
              ? {
                  fontSize: '14px',
                  fontWeight: 700,
                  color: '3' !== isSelected ? colorGreyBorderAndFont : '',
                }
              : {
                  fontSize: '24px',
                  fontWeight: 700,
                  color: '3' !== isSelected ? colorGreyBorderAndFont : '',
                }
          }
        >
          {t('app.classificacoes').toUpperCase()}
        </p>
      ),
      children: <SafeSpaceTable showClassification />,
    },
  ];

  return (
    <Layout.Container>
      <Title primary>{t('app.meuCantinho')}</Title>
      <Tabs
        centered
        style={
          isSmallMobile ? { height: '100%', width: '100%' } : { height: '100%' }
        }
        onTabClick={(event) => setIsSelected(event)}
        size="large"
        defaultActiveKey={'3'}
        items={items}
      />
    </Layout.Container>
  );
}

export default SafeSpace;
