import http from "infra/http";
import { useMutation } from "react-query";
import { TenantForm } from "../typings";

export function useSaveTenant() {
  return useMutation(
    (data: TenantForm) => {
      const id = data.id
      delete data.id
      delete data.active
      if(data.tempMode && !id){
        return http.post(`/v1/tenant/temp`, data);
      }
      if (id) {
        return http.put(`/v1/tenant/${id}`, data);
      }
      return http.post(`/v1/tenant/`, data);
    },
    { retry: false }
  );
}
