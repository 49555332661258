import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Spin } from 'antd';
import SubTitle from 'components/typography/subTitle';
import CarouselV2 from 'components/V2/carousel';
import useGames from 'core/features/games/hooks/useGames';
import useGamesHistory from 'core/features/games/hooks/useGamesHistory';
import moment from 'moment';
import characters from 'assets/images/u4hero-characters.png';
import studentOnComputer from 'assets/images/white-label/student-on-computer.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import styles from './index.module.css';
import { useHistory } from 'react-router';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';

function GamesCarousels() {
  const { data, isLoading: isLoadingNews } = useGames({
    page: 1,
    limit: 100,
    startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
  });

  const { data: dataHistory, isLoading: isLoadingHistory } = useGamesHistory({
    startDate: moment().startOf('year').format('YYYY-MM-DD'),
  });
  const { colorPrimary, colorPrimaryMedium } = useU4heroColors();

  const { user } = useCurrentUser();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const slidesToShow = isMobile ? 2 : 5;
  const totalSlidesNews = data?.total ?? 0;
  const totalSlidesHistory = dataHistory?.total ?? 0;

  const isLoading = isLoadingHistory || isLoadingNews;
  return (
    <Flex vertical gap={24}>
      {isLoading && <Spin size="large" />}
      {totalSlidesNews !== 0 && (
        <Flex vertical gap={16}>
          <SubTitle primary>{t('app.novidades')}</SubTitle>

          <CarouselV2 totalSlides={totalSlidesNews} slidesToShow={slidesToShow}>
            {data?.data.map((item) => (
              <div
                key={item.id}
                className={styles.imageContainer}
                onClick={() => history.push(`/game/${item.id}`)}
              >
                <img
                  className={styles.image}
                  src={item.coverImageUrl}
                  alt={`imagem-cover-${item.name}`}
                />
              </div>
            ))}
          </CarouselV2>
        </Flex>
      )}
      {totalSlidesHistory !== 0 && (
        <Flex vertical gap={16}>
          <SubTitle primary>{t('app.historico')}</SubTitle>

          <CarouselV2
            totalSlides={totalSlidesHistory}
            slidesToShow={slidesToShow}
          >
            {dataHistory?.data.map((item, index) => (
              <div
                key={index}
                className={styles.imageContainer}
                onClick={() => history.push(`/game/${item.game.id}`)}
              >
                <img
                  className={styles.image}
                  src={item.game.coverImageUrl}
                  alt={`imagem-cover-${item.game.name}`}
                />
              </div>
            ))}
          </CarouselV2>
        </Flex>
      )}
      {!totalSlidesNews && !totalSlidesHistory && !isLoading && (
        <Flex vertical align="center" justify="center">
          <img
            src={user?.whiteLabel ? studentOnComputer : characters}
            width="395px"
          />
          <Flex
            style={{
              borderRadius: '50px 10px',
              background: colorPrimary,
              borderRight: `4px solid ${colorPrimaryMedium}`,
              borderBottom: `4px solid ${colorPrimaryMedium}`,
              padding: '15px',
              maxWidth: '533px',
              marginTop: '-48px',
            }}
          >
            <Text size="sm" color="white">
              {t('app.mensagemCarroselJogos')}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

export default GamesCarousels;
