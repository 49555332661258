import SelectBase, { SelectBaseProps } from 'components/inputs/select-base';
import { t } from 'core/resources/strings';

export interface SelectActivityTypeProps<T = any> extends SelectBaseProps<T> {
  value: any;
  onChange: (v: any) => void;
}

function SelectActivityType({
  value,
  onChange,
  ...rest
}: SelectActivityTypeProps) {
  const types = [
    {
      label: t('app.selfAvaliation'),
      value: 1,
    },
    {
      label: t('app.avaliation'),
      value: 2,
    },

    {
      label: t('app.activity'),
      value: 3,
    },
  ];
  return (
    <SelectBase
      value={value}
      onChange={(ev) => {
        onChange(ev);
      }}
      options={types.map((item) => ({
        label: item?.label,
        value: item.value,
      }))}
      {...rest}
    />
  );
}

export default SelectActivityType;
