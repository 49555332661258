import { useQuery } from 'react-query';
import axios from 'infra/http';

function useStoriesAuthors() {
  const fetchData = async (): Promise<{ id: string; name: string }[]> => {
    const { data } = await axios.get<{ id: string; name: string }[]>(
      `/v2/stories/authors`
    );

    return data;
  };

  return useQuery([`/v2/stories/authors`], () => fetchData());
}

export default useStoriesAuthors;
