import { useMediaQuery } from '@chakra-ui/media-query';
import { Divider, Flex } from 'antd';
import avaliationImage from 'assets/images/avaliation.png';
import initialAvaliationImage from 'assets/images/initial-avaliation.png';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import Tag from 'components/V2/tag';
import { PiCalendarDotsLight, PiMedalLight, PiUserLight } from 'react-icons/pi';
interface TaskCardProps {
  typeId: number;
  progress: number;
  startDate: string;
  endDate: string;
  gameImageUrl?: string;
  responsibleName: string;
  competenceName?: string;
  countClasses?: number;
  countStudents?: number;
  onOpenReport: (v: 'simplified' | 'complete') => void;
}

function TaskCard({
  progress,
  startDate,
  endDate,
  gameImageUrl,
  typeId,
  responsibleName,
  competenceName,
  countClasses,
  countStudents,
  onOpenReport,
}: TaskCardProps) {
  const { colorSecondary, colorPrimary, colorGreyBorderAndFont } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const getProgressStatus = () => {
    if (progress === 0) {
      return {
        text: t('app.naoIniciada'),
        color: colorGreyBorderAndFont,
      };
    }
    if (progress === 100) {
      return {
        text: t('app.concluido') + ' - 100%',
        color: colorPrimary,
      };
    }
    return {
      text: t('app.emAndamento') + ' - ' + progress + '%',
      color: colorSecondary,
    };
  };

  const { text, color } = getProgressStatus();

  return (
    <Flex
      vertical
      gap={16}
      style={{
        padding: 8,
        height: '100%',
      }}
      className="box-shadow-generic-card"
      justify="space-between"
    >
      <div style={{ position: 'relative' }}>
        <img
          src={
            gameImageUrl
              ? gameImageUrl
              : typeId === 1
              ? initialAvaliationImage
              : avaliationImage
          }
          alt="Avaliação"
          style={{
            borderRadius: '15px',
            aspectRatio: '16/9',
          }}
          width={'100%'}
        />

        {(countClasses !== undefined || countStudents !== undefined) && (
          <Flex
            style={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1,
              background: 'white',
              borderRadius: '5px',
            }}
          >
            {countClasses ? (
              <Tag
                text={`${countClasses} ${t('app.turmas')}`}
                color="primary"
              />
            ) : (
              <Tag
                text={`${countStudents} ${t('app.estudantes')}`}
                color="primary"
              />
            )}
          </Flex>
        )}

        <Flex
          style={{
            width: '100%',
            position: 'relative',
            bottom: isMobile ? 18 : 10,
          }}
          justify="center"
        >
          <div
            style={{
              borderRadius: '5px',
              fontSize: '12px',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: color,
              fontWeight: 700,
              color: 'white',
            }}
          >
            {text}
          </div>
        </Flex>
      </div>
      <Flex vertical gap={8}>
        {competenceName && (
          <>
            <Flex gap={8}>
              <PiMedalLight size={24} color="black" />
              <Text size="md">{competenceName}</Text>
            </Flex>
            <Divider style={{ margin: 0 }} />
          </>
        )}
        <Flex gap={8}>
          <PiUserLight size={24} color="black" />
          <Text size="md">{responsibleName}</Text>
        </Flex>
        <Divider style={{ margin: 0 }} />
        <Flex gap={8}>
          <PiCalendarDotsLight size={24} color="black" />
          <Text size="md">
            {startDate} - {endDate}
          </Text>
        </Flex>
      </Flex>
      <Flex gap={16} justify="center" align="center">
        <Button
          size="md"
          disabled={progress === 0}
          onClick={() => onOpenReport('simplified')}
        >
          {t('app.simplificado')}
        </Button>
        <Button
          size="md"
          disabled={progress === 0}
          onClick={() => onOpenReport('complete')}
        >
          {t('app.completo')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default TaskCard;
