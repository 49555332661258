import { useMediaQuery } from '@chakra-ui/react';
import { Col, Collapse, Flex, Row, Spin } from 'antd';
import BackButton from 'components/buttons/back-button';
import Layout from 'components/layout';
import SvgIcon from 'components/SvgIcon';
import useTagsWithoutParent from 'core/features/tags/hooks/useTagsWithoutParent';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory } from 'react-router';
import CompetenceItem from './components/competence-item';
import SkillItem from './components/skill-item';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import { TagDetails } from 'core/features/tags/types';
import React from 'react';
import { TagType } from 'core/features/competence/typings';
import tagsImage from 'assets/images/white-label/tags.png';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';

function Tags() {
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useTagsWithoutParent({ active: true });
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();

  const groupedTags = data?.reduce((acc, tag) => {
    if (!acc[tag.type]) {
      acc[tag.type] = [];
    }
    acc[tag.type].push(tag);
    return acc;
  }, {} as Record<number, TagDetails[]>);

  const names = {
    [TagType.Competências]: t('app.competencias'),
    [TagType.Clima]: t('app.clima'),
    [TagType.Treinamento]: t('app.treinamento'),
    [TagType.Pesquisa]: t('app.pesquisa'),
  };

  const renderTagsByType = (type: number) => (
    <React.Fragment key={type}>
      <Title primary>{names[type as keyof typeof names]}</Title>
      {isMobile ? (
        <Flex vertical gap="16px" style={{ marginBottom: '16px' }}>
          {groupedTags?.[type]
            .sort((a, b) =>
              a.name?.[currentLocale]?.localeCompare(b.name?.[currentLocale])
            )
            .map((item) => (
              <CompetenceItem
                key={item.id}
                name={item.name?.[currentLocale]}
                icon={item.icon ?? undefined}
                description={
                  item.description ? item.description?.[currentLocale] : ''
                }
                id={item.id}
              />
            ))}
        </Flex>
      ) : (
        groupedTags?.[type]
          .sort((a, b) =>
            a.name?.[currentLocale]?.localeCompare(b.name?.[currentLocale])
          )
          .map((item) => (
            <Collapse
              key={item.id}
              items={[
                {
                  key: item.id,
                  label: (
                    <Flex vertical gap="4px">
                      <Flex gap="16px" align="center">
                        <SvgIcon url={item.icon ?? ''} color="#000" size={50} />
                        <p
                          style={{
                            fontSize: '18px',
                            color: 'black',
                            fontWeight: 400,
                          }}
                        >
                          {item.name?.[currentLocale]}
                        </p>
                      </Flex>
                      <p
                        style={{
                          fontSize: isMobile ? '14px' : '18px',
                          fontWeight: 400,
                          textAlign: 'justify',
                        }}
                      >
                        {item.description
                          ? item.description?.[currentLocale]
                          : ''}
                      </p>
                    </Flex>
                  ),
                  children: (
                    <Row gutter={[24, 16]} style={{ marginTop: '16px' }}>
                      {item.children.map((child) => (
                        <Col span={8} key={child.id}>
                          <SkillItem
                            name={child.name?.[currentLocale]}
                            description={
                              child.description
                                ? child.description?.[currentLocale]
                                : ''
                            }
                            icon={child.icon ?? undefined}
                          />
                        </Col>
                      ))}
                    </Row>
                  ),
                },
              ]}
              expandIconPosition="end"
            />
          ))
      )}
    </React.Fragment>
  );
  return (
    <Layout.Container>
      <Flex vertical gap="24px">
        <Flex gap={isMobile ? '12px' : '24px'} align="center">
          <BackButton onClick={() => history.push('/')} />
          <Title primary style={{ width: 'fit-content' }}>
            {t('app.camposDeConhecimentosEHabilidades')}
          </Title>
        </Flex>
        <Flex
          justify="space-between"
          style={{
            background: colorPrimary,
            borderRadius: '20px',
            position: 'relative',
          }}
        >
          {isMobile ? (
            <Flex vertical>
              <Flex justify="space-between" align="center">
                <Title
                  color="white"
                  align="center"
                  style={{ lineHeight: 'normal' }}
                >
                  {t('app.camposDeConhecimentosEHabilidades')}
                </Title>
              </Flex>
              <Text style={{ padding: '8px' }} color="white" align="justify">
                {t('app.camposDeConhecimentoExplicacao')}
              </Text>
            </Flex>
          ) : (
            <>
              <Flex
                vertical
                gap="12px"
                style={{
                  paddingLeft: '50px',
                  paddingTop: '18px',
                  paddingBottom: '16px',
                }}
                justify="center"
              >
                <Title
                  color="white"
                  style={{
                    maxWidth: '570px',
                  }}
                >
                  {t('app.camposDeConhecimentosEHabilidades')}
                </Title>
                <p
                  style={{
                    color: 'white',
                    fontSize: '18px',
                    fontWeight: 400,
                    textAlign: 'justify',
                    maxWidth: '574px',
                  }}
                >
                  {t('app.camposDeConhecimentoExplicacao')}
                </p>
              </Flex>
              <img
                alt={user?.whiteLabel ? 'ilustração' : 'lumi-lendo'}
                src={user?.whiteLabel ? tagsImage : '/lumi-lendo.png'}
                style={{
                  width: user?.whiteLabel ? '466px' : '366px',
                  height: user?.whiteLabel ? 'auto' : '335px',
                  borderRadius: '20px',
                  objectFit: 'contain',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                }}
              />
            </>
          )}
        </Flex>
        {isLoading && <Spin size="large" />}

        {groupedTags &&
          Object.keys(groupedTags).map((type) =>
            renderTagsByType(Number(type))
          )}
      </Flex>
    </Layout.Container>
  );
}

export default Tags;
