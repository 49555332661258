import {
  ConversationContainer,
  MessageContainer,
  UserProfile,
} from '../styles';
import { Image } from '@chakra-ui/image';
import { Message } from 'core/features/chat/types';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import moment from 'moment';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import U4Avatar from 'components/u4avatar';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';

const Conversation = ({ messages }: { messages: Message[] }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorSecondary } = useU4heroColors();
  return (
    <ConversationContainer>
      {messages.map((m, index) => (
        <MessageContainer
          key={index}
          incomingMessage={m.responsibleId !== m.senderId}
        >
          {m.senderId === m.responsibleId && m.responsibleImage !== null ? (
            <Image
              bg="white"
              w={isMobile ? '38px' : '48px'}
              h={isMobile ? '38px' : '48px'}
              rounded="32px"
              src={m.responsibleImage}
              mr="20px"
            />
          ) : (
            <U4Avatar
              frame={m.avatar ? m.avatar?.frame?.imageUrl : defaultFrame}
              background={
                m.avatar ? m.avatar?.background?.imageUrl : defaultBackground
              }
              icon={m.avatar ? m.avatar?.icon?.imageUrl : defaultIcon}
              size="small"
            />
            // <UserProfile
            //   content={
            //     m.senderId === m.responsibleId
            //       ? m.responsibleName
            //       : m.studentName
            //   }
            // />
          )}
          <Flex
            vertical
            style={{
              borderRadius: '10px',
              borderRight:
                m.senderId === m.responsibleId
                  ? `3px solid ${hex2rgba(colorPrimary, 0.5)} `
                  : '',
              borderLeft:
                m.senderId !== m.responsibleId
                  ? `3px solid ${hex2rgba(colorSecondary, 0.5)}`
                  : '',
              borderBottom: `3px solid ${
                m.senderId === m.responsibleId
                  ? hex2rgba(colorPrimary, 0.5)
                  : hex2rgba(colorSecondary, 0.5)
              }`,
              background:
                m.senderId === m.responsibleId
                  ? hex2rgba(colorPrimary, 0.7)
                  : hex2rgba(colorSecondary, 0.7),
              padding: '4px 8px',
              width: '100%',
            }}
          >
            <Flex justify="space-between">
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                }}
              >
                {m.senderId === m.responsibleId ? 'Eu' : m.studentName}
              </p>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 600,
                }}
              >
                {moment(m.createdAt).format('HH[h]mm')}
              </p>
            </Flex>
            {m.message}
          </Flex>
        </MessageContainer>
      ))}
    </ConversationContainer>
  );
};

export default Conversation;
