import {
  Col,
  ColorPicker,
  Flex,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
} from 'antd';
import { useSaveTag } from 'core/features/competence/hooks/useSaveTag';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import useTag from 'core/features/competence/hooks/useTag';
import SubTitle from 'components/typography/subTitle';
import { useMediaQuery } from '@chakra-ui/media-query';
import Button from 'components/buttons/button';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import CustomizedRequiredMark from 'components/required-mark';
import { IoClose } from 'react-icons/io5';
import SelectCompetence from './select-competence';
import { TagForm, TagType } from 'core/features/competence/typings';
import UploadImagem from 'components/inputs/upload-imagem';
import { useQueryClient } from 'react-query';
import './style.css';
import { currentLocale } from 'core/resources/strings/polyglot';
const { TextArea } = Input;

function ModalTag({
  onClose,
  id,
  type,
}: {
  onClose: () => void;
  id?: string;
  type: 'tag' | 'skill';
}) {
  const history = useHistory();
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveTag();
  const { data } = useTag(id);
  const { colorPrimary } = useU4heroColors();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [locale, setLocale] = useState('pt-br');
  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: TagForm) => {
    handleSave(
      {
        ...values,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao criar ${
              type === 'skill' ? 'a habilidade' : 'o campo de conhecimento'
            }. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          const notificationMessage =
            type === 'skill'
              ? `Habilidade ${id ? 'atualizada' : 'criada'}`
              : `Campo de conhecimento ${id ? 'atualizado' : 'criado'}`;
          notificateSucess(notificationMessage);
          onClose();
          queryClient.invalidateQueries('/v1/tags');
          history.push(`/competences?tab=${type === 'skill' ? '2' : '1'}`);
        },
      }
    );
  };

  const locales = [
    {
      label: 'Português',
      value: 'pt-br',
    },
    {
      label: 'Espanhol',
      value: 'es',
    },
    {
      label: 'Inglês',
      value: 'en',
    },
  ];

  const options = [
    {
      label: 'Competências',
      value: TagType.Competências,
    },
    {
      label: 'Clima',
      value: TagType['Clima'],
    },
    {
      label: 'Treinamento',
      value: TagType.Treinamento,
    },
    {
      label: 'Pesquisa',
      value: TagType.Pesquisa,
    },
  ];

  return (
    <Modal
      title={
        <SubTitle primary>
          {id ? 'Editar' : 'Criar'}{' '}
          {type === 'skill' ? 'habilidade' : 'Campo de Conhecimento'}
        </SubTitle>
      }
      open={true}
      onCancel={onClose}
      footer={null}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
      width={isMobile ? '90%' : 470}
    >
      <Form
        id="student-creation-modal"
        onFinish={(ev) => onSubmit(ev)}
        validateTrigger="onSubmit"
        layout="vertical"
        form={form}
        initialValues={
          data || {
            icon: undefined,
            name: {
              en: undefined,
              es: undefined,
              'pt-br': undefined,
            },
            description: {
              en: undefined,
              es: undefined,
              'pt-br': undefined,
            },
            locale: currentLocale,
            active: true,
          }
        }
        requiredMark={CustomizedRequiredMark}
      >
        <Form.Item hidden name="id" />
        <Form.Item name="icon" style={{ marginTop: '16px' }}>
          {/* @ts-ignore */}
          <UploadImagem
            formats={['svg']}
            sizeLimitMB={1}
            buttonText={'CARREGAR ÍCONE'}
            placeholderImg={'icon'}
            apiPath="/v1/file/generic-upload?folderKey=tags&bucketName=u4hero-files"
          />
        </Form.Item>

        <Form.Item
          name={['name', 'pt-br']}
          label="Nome"
          hidden={locale !== 'pt-br'}
          rules={[
            {
              required: true,
              message: `Por favor, insira o nome da ${
                type === 'skill' ? 'habilidade' : 'competência'
              }!`,
            },
          ]}
        >
          <Input
            id="id-tour-competences-create-modal-input-name"
            placeholder={`Insira o nome da ${
              type === 'skill' ? 'habilidade' : 'competência'
            }`}
            size={isMobile ? 'middle' : 'large'}
          />
        </Form.Item>

        <Form.Item
          name={['name', 'en']}
          label="Nome"
          hidden={locale !== 'en'}
          rules={[
            {
              required: locale === 'en',
              message: `Por favor, insira o nome da ${
                type === 'skill' ? 'habilidade' : 'competência'
              }!`,
            },
          ]}
        >
          <Input
            id="id-tour-competences-create-modal-input-name"
            placeholder={`Insira o nome da ${
              type === 'skill' ? 'habilidade' : 'competência'
            }`}
            size={isMobile ? 'middle' : 'large'}
          />
        </Form.Item>

        <Form.Item
          name={['name', 'es']}
          label="Nome"
          hidden={locale !== 'es'}
          rules={[
            {
              required: locale === 'es',
              message: `Por favor, insira o nome da ${
                type === 'skill' ? 'habilidade' : 'competência'
              }!`,
            },
          ]}
        >
          <Input
            id="id-tour-competences-create-modal-input-name"
            placeholder={`Insira o nome da ${
              type === 'skill' ? 'habilidade' : 'competência'
            }`}
            size={isMobile ? 'middle' : 'large'}
          />
        </Form.Item>

        {type === 'tag' && (
          <Form.Item
            name={'type'}
            label="Área de análise"
            rules={[
              {
                required: true,
                message: `Selecione a área de análise`,
              },
            ]}
          >
            <Select
              options={options}
              size={isMobile ? 'middle' : 'large'}
              placeholder={'Defina o que está sendo analisado'}
              id="id-tour-competences-create-modal-input-tag-type"
            />
          </Form.Item>
        )}

        <Form.Item
          name={['description', 'pt-br']}
          label="Descrição"
          hidden={locale !== 'pt-br'}
          rules={[
            {
              required: true,
              message: 'Por favor, crie uma descrição!',
            },
          ]}
        >
          <TextArea
            placeholder={`Descreva o que é a ${
              type === 'skill' ? 'habilidade' : 'competência'
            }, destacando suas principais características e importância.`}
            id="id-tour-competences-create-modal-input-description"
            size={isMobile ? 'middle' : 'large'}
            rows={3}
          />
        </Form.Item>

        <Form.Item
          name={['description', 'en']}
          label="Descrição"
          hidden={locale !== 'en'}
          rules={[
            {
              required: locale === 'en',
              message: 'Por favor, crie uma descrição!',
            },
          ]}
        >
          <TextArea
            placeholder={`Descreva o que é a ${
              type === 'skill' ? 'habilidade' : 'competência'
            }, destacando suas principais características e importância.`}
            id="id-tour-competences-create-modal-input-description"
            size={isMobile ? 'middle' : 'large'}
            rows={3}
          />
        </Form.Item>

        <Form.Item
          name={['description', 'es']}
          label="Descrição"
          hidden={locale !== 'es'}
          rules={[
            {
              required: locale === 'es',
              message: 'Por favor, crie uma descrição!',
            },
          ]}
        >
          <TextArea
            placeholder={`Descreva o que é a ${
              type === 'skill' ? 'habilidade' : 'competência'
            }, destacando suas principais características e importância.`}
            id="id-tour-competences-create-modal-input-description"
            size={isMobile ? 'middle' : 'large'}
            rows={3}
          />
        </Form.Item>

        {type === 'skill' ? (
          <Form.Item
            name="parentId"
            label="Campo de Conhecimento"
            rules={[
              {
                required: true,
                message: 'Por favor, selecione um campo de conhecimento!',
              },
            ]}
          >
            {/* @ts-ignore */}
            <SelectCompetence id="id-tour-competences-create-modal-input-parentId" />
          </Form.Item>
        ) : null}

        <Form.Item
          name="color"
          label="Atribuição de cor"
          getValueFromEvent={(color, hex) => hex}
        >
          <ColorPicker
            className={'color-picker'}
            showText
            style={{ width: '100%' }}
          />
        </Form.Item>
        <Form.Item label="Idioma">
          <Radio.Group
            id="id-tour-competences-create-modal-input-language"
            style={{ width: '100%' }}
            onChange={(e) => {
              setLocale(e.target.value);
            }}
            value={locale}
          >
            <Row
              gutter={[isMobile ? 30 : 40, 0]}
              justify="space-between"
              style={{ width: '100%' }}
            >
              {locales.map((item) => (
                <Col key={item.label} span={8}>
                  <Radio
                    value={item.value}
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {item.label}
                  </Radio>
                </Col>
              ))}
            </Row>
          </Radio.Group>
        </Form.Item>

        {type === 'skill' && (
          <Form.Item name={'adaptativeOrder'} label="Ordem modo adaptativo">
            <Input
              id="id-tour-competences-create-modal-input-adaptative-order"
              placeholder={`Insira a ordem adaptativa`}
              size={isMobile ? 'middle' : 'large'}
              type="number"
            />
          </Form.Item>
        )}

        <Flex justify="center" gap="16px">
          <Button
            variant="outline"
            size="md"
            disabled={isLoadingSave}
            onClick={() => {
              if (form.isFieldsTouched()) {
                openNotification({ onClose, colorPrimary, isMobile });
              } else {
                onClose();
              }
            }}
            id="id-tour-competences-create-modal-button-cancel"
          >
            CANCELAR
          </Button>

          <Button
            htmlType="submit"
            size="md"
            loading={isLoadingSave}
            id="id-tour-competences-create-modal-button-save"
          >
            SALVAR
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ModalTag;
