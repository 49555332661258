import {
  Box,
  Flex,
  Grid,
  GridItem,
  Link,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import useNotifications from 'core/features/notification/hooks/useNotifications';
import moment from 'moment';
import './style.css';
import { Badge, Switch } from 'antd';
import UserOutlined from '@ant-design/icons/lib/icons/UserOutlined';
import { Divider } from 'antd';
import { useState } from 'react';
import { useToggleNotification } from 'core/features/notification/hooks/useToggleNotification';
import { useHistory } from 'react-router';
import { AiOutlineMessage, AiOutlineSafetyCertificate } from 'react-icons/ai';
import { Image } from '@chakra-ui/image';
import noNotifications from '../../../assets/images/não-ha-notificacoes.png';
import noNotificationsWl from '../../../assets/images/white-label/não-ha-notificacoes.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';

function NotificationBox({
  onClose,
  refetchCount,
  isSideBarExpanded,
}: {
  onClose: () => void;
  refetchCount: () => void;
  isSideBarExpanded?: boolean;
}) {
  const { data, refetch } = useNotifications();
  const [filter, setFilter] = useState(false);
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimaryMedium, colorSecondary } = useU4heroColors();
  const { user } = useCurrentUser();

  const filteredNotifications = filter
    ? data?.data.filter((item) => !item.viewed)
    : data?.data;

  const { mutate: handleToggle } = useToggleNotification();

  return (
    <Box
      width={isMobile ? '75vw' : '300px'}
      height={'80vh'}
      borderRadius={'20px'}
      p={'16px 24px'}
      bg={colorPrimaryMedium}
      style={{
        position: 'relative',
        left: isSideBarExpanded ? '180px' : '290px',
        top: isMobile ? '130px' : '250px',
        fontFamily: 'Lato',
        color: 'white',
      }}
    >
      <Flex direction="column" color="white" gridGap="8px">
        <h2 style={{ color: 'white', marginBottom: '0px' }}>
          {t('app.notificacoes')}
        </h2>
        <Text style={{ alignSelf: 'end' }} fontFamily="Lato">
          {t('app.mostrarApenasNaoLidas')}
          <Switch onChange={(v) => setFilter(v)} />
        </Text>
      </Flex>

      <Divider style={{ background: 'white' }} />
      {filteredNotifications?.length ? (
        <Flex direction="column">
          <Text fontSize={16} fontWeight="700">
            {t('app.maisRecentes').toUpperCase()}
          </Text>
          <Link
            fontSize={12}
            onClick={() =>
              handleToggle(
                filteredNotifications?.map((item) => item.id),
                {
                  onSuccess: () => {
                    refetch();
                    refetchCount();
                  },
                }
              )
            }
            _hover={{
              color: colorSecondary,
              textDecoration: 'underline',
            }}
            alignSelf="end"
          >
            {t('app.marcarTodasComoLidas')}
          </Link>
        </Flex>
      ) : (
        <div>
          <Text fontSize={16} fontWeight="700">
            {t('app.naoHaNotificacoes').toUpperCase()}
          </Text>
          <Image src={user?.whiteLabel ? noNotificationsWl : noNotifications} />
        </div>
      )}

      <div style={{ overflow: 'auto', maxHeight: '60vh' }}>
        {filteredNotifications?.map((item) => (
          <Box
            key={item.id}
            className="notifications-item"
            mb={'10px'}
            _active={{
              border: `2px solid ${colorSecondary}`,
            }}
            onClick={() => {
              handleToggle([item.id], {
                onSuccess: () => {
                  refetch();
                  refetchCount();
                },
              });
              history.push(item.url);
              onClose();
            }}
          >
            <div className="text">
              <Grid gap={4} templateColumns="repeat(10, 1fr)">
                <GridItem colSpan={1} alignSelf="center">
                  {item.type === 0 ? (
                    <AiOutlineSafetyCertificate size={20} />
                  ) : item.type === 1 ? (
                    <AiOutlineMessage size={20} />
                  ) : (
                    <UserOutlined style={{ fontSize: 20 }} />
                  )}
                </GridItem>
                <GridItem colSpan={8}>
                  <Text
                    fontWeight={!item.viewed ? 'bold' : 'normal'}
                    size={'16px'}
                  >
                    {item.title['pt-br']}
                    <p
                      style={{
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: 700,
                      }}
                    >
                      {moment(item.createdAt).fromNow()}
                    </p>
                  </Text>
                </GridItem>
                <GridItem colSpan={1} alignSelf="center">
                  {!item.viewed ? (
                    <Badge color={colorSecondary} status="processing" />
                  ) : null}
                </GridItem>
              </Grid>
            </div>
          </Box>
        ))}
      </div>
    </Box>
  );
}

export default NotificationBox;
