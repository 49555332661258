import { useMediaQuery } from '@chakra-ui/react';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import SearchBar from 'components/V2/SearchBar';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Divider, Flex, Spin } from 'antd';
import cosmo from 'assets/images/cosmo-with-books.png';
import firtSteps from 'assets/images/white-label/first-steps.png';
import { Link, useHistory, useLocation } from 'react-router-dom';
import BackButton from 'components/buttons/back-button';
import useHelpers from 'core/features/helpers/hooks/useHelpers';
import LinkBox from 'components/link-box';
import { useCurrentUser } from 'core/features/user/store';

function FirstSteps() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState<string>();
  const history = useHistory();
  const { user } = useCurrentUser();

  let location = useLocation();
  useEffect(() => {
    if (location.search.includes('search')) {
      const queryParams = new URLSearchParams(location.search);
      setSearch(queryParams.get('search') as string);
    }
  }, []);

  const { data, isLoading } = useHelpers({
    page: 1,
    limit: 100,
    classifications: ['Primeiros Passos'],
    search,
    active: true,
  });

  return (
    <Layout.Container>
      <Flex vertical gap={isMobile ? '24px' : '32px'}>
        <BackButton onClick={() => history.goBack()} />
        <div
          style={{
            backgroundImage: "url('/estrelas_1.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: colorPrimary,
            borderRadius: '20px',
            height: '204px',
            position: 'relative',
            padding: '16px',
            marginBottom: '24px',
          }}
        >
          <Flex style={{ width: '100%' }} justify="space-between">
            <Flex
              vertical
              gap={isMobile ? '16px' : '8px'}
              style={{ maxWidth: 413 }}
            >
              {isMobile ? (
                <Flex justify="space-between">
                  <Title
                    style={{
                      color: 'white',
                      fontSize: isMobile ? '30px' : '40px',
                      maxWidth: '170px',
                    }}
                  >
                    Primeiros Passos
                  </Title>
                  <img
                    src={user?.whiteLabel ? firtSteps : cosmo}
                    style={{
                      position: 'absolute',
                      top: -20,
                      right: 0,
                      overflow: 'visible',
                    }}
                    width={'155px'}
                  />
                </Flex>
              ) : null}

              {!isMobile && (
                <Title
                  style={{
                    color: 'white',
                    fontSize: isMobile ? '30px' : '40px',
                    textAlign: 'center',
                  }}
                >
                  Primeiros Passos
                </Title>
              )}
              <Text size="md" color="white" align="justify">
                Procurando por um artigo específico? Utilize nossa barra de
                pesquisa abaixo para encontrar rapidamente o que você precisa.
              </Text>
            </Flex>
            {!isMobile && (
              <img
                src={user?.whiteLabel ? firtSteps : cosmo}
                style={{
                  position: 'absolute',
                  bottom: user?.whiteLabel ? 0 : -16,
                  right: 16,
                  overflow: 'visible',
                }}
                width={user?.whiteLabel ? '268px' : '392px'}
              />
            )}
          </Flex>

          <Flex justify="center">
            <div style={{ position: 'absolute', bottom: -20 }}>
              <SearchBar value={search} onSearch={setSearch} />
            </div>
          </Flex>
        </div>

        {isLoading ? (
          <Spin size="large" />
        ) : (
          <LinkBox
            title={data?.data?.[0]?.subClassification ?? ''}
            items={
              data?.data.map((item) => ({
                id: item.id,
                title: item.title,
                link: `/helpers/${item.id}`,
              })) ?? []
            }
          />
        )}
      </Flex>
    </Layout.Container>
  );
}

export default FirstSteps;
