import lumi from 'assets/images/lumi-profile-pic.png';
import star from 'assets/images/white-label/smiling-star.png';
import heart from 'assets/images/heart.png';
import lumiWaving from 'assets/images/lumi-waving.png';
import feedbackThankYou from 'assets/images/white-label/feedback-thank-you.png';
import { Flex, Form, Input, Slider, SliderSingleProps } from 'antd';
import { useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import Button from 'components/buttons/button';
import { MdExpandLess } from 'react-icons/md';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import styles from './styles.module.css';
import SelectRating from './SelectRating';
import Text from 'components/typography/text';
import { useCurrentUser } from 'core/features/user/store';

function Feedback({
  onSubmit,
  askMessage,
  submited,
  isLoadingSubmit,
  requireText = false,
  askForTextMessage = 'Deseja comentar sobre sua avaliação ou deixar uma sugestão?',
  placeholderTextArea = 'Escreva aqui...',
}: {
  onSubmit: (ev: any) => void;
  askMessage: string;
  submited: boolean;
  isLoadingSubmit: boolean;
  requireText?: boolean;
  askForTextMessage?: string;
  placeholderTextArea?: string;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const feedback = Form.useWatch('feedback', form);
  const rating = Form.useWatch('rating', form);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);
  const { colorPrimaryMedium } = useU4heroColors();
  const [step, setStep] = useState(1);
  const { user } = useCurrentUser();

  useEffect(() => {
    if (submited) {
      setStep(3);
    }
  }, [submited]);

  return (
    <div
      style={{
        position: 'fixed',
        insetInlineEnd: '0px',
        insetBlockEnd: 'calc(50% - 20px)',
        zIndex: 200,
      }}
    >
      <Flex
        justify="space-around"
        align="center"
        style={{ width: '100%', height: '100%' }}
      >
        <Flex
          style={{
            right: isMobile ? '-88vw' : -394,
            transition: '1s',
            position: 'fixed',
          }}
          align="center"
          className={
            isOpenFeedback
              ? `${isMobile ? `${styles.leftMobile}` : `${styles.left}`}`
              : `${styles.right}`
          }
        >
          <Button
            size="sm"
            style={{
              transform: 'rotate(-90deg)',
              height: 'fit-content',
              marginRight: isMobile ? -56 : -76,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              padding: isMobile ? '3px 9px' : '7px 25px',
              borderRadius: '5px 5px 0px 0px',
              background: colorPrimaryMedium,
              letterSpacing: '3px',
              gap: '16px',
            }}
            onClick={() => setIsOpenFeedback(!isOpenFeedback)}
          >
            {isOpenFeedback ? (
              <MdExpandLess
                size={22}
                color="white"
                style={{ transform: 'rotate(180deg)' }}
              />
            ) : (
              <img
                alt="lumi-perfil"
                src={user?.whiteLabel ? star : lumi}
                style={{ transform: 'rotate(90deg)' }}
                width={user?.whiteLabel ? '28px' : '22px'}
                height={user?.whiteLabel ? '28px' : '22px'}
              />
            )}
            <Text size="sm" color="white" bold>
              {' '}
              Feedback
            </Text>
          </Button>
          <div
            style={{
              minHeight: isMobile ? '222px' : '234px',
              width: isMobile ? '88vw' : '394px',
              borderRadius: '10px 0px 0px 10px',
              padding: '16px',
              background: 'white',
              boxShadow:
                '0px -0.5px 4px 0px rgba(0, 0, 0, 0.25), 0px 3px 4px 0px rgba(0, 0, 0, 0.25)',
              overflow: 'hidden',
              position: 'relative',
            }}
          >
            <Form
              id="id-tour-helper-feedback-form"
              onFinish={(ev) => onSubmit(ev)}
              validateTrigger="onSubmit"
              layout="vertical"
              requiredMark={'optional'}
              form={form}
              style={{ height: '100%' }}
            >
              <Flex
                vertical
                // justify="space-between"
                style={{
                  width: '100%',
                  height: '100%',
                  display: step === 1 ? 'flex' : 'none',
                }}
                gap={isMobile ? '8px' : '16px'}
              >
                <Text size="lg" align="justify">
                  {askMessage}
                </Text>

                <Form.Item name="rating" initialValue={undefined} noStyle>
                  {/* @ts-ignore */}
                  <SelectRating />
                </Form.Item>
                {!requireText && (
                  <Button
                    size="sm"
                    style={{
                      justifySelf: 'center',
                      alignSelf: 'center',
                    }}
                    onClick={() => setStep(2)}
                    disabled={rating === undefined}
                  >
                    ENVIAR
                  </Button>
                )}
              </Flex>
              <Flex
                vertical
                justify="space-between"
                style={{
                  width: '100%',
                  height: '100%',
                  display:
                    (step === 2 || requireText) && step !== 3 ? 'flex' : 'none',
                }}
                gap="8px"
              >
                <Text size="lg" align="justify">
                  {askForTextMessage}
                </Text>

                <Form.Item name="feedback" initialValue={''} noStyle>
                  <Input.TextArea
                    id="id-tour-create-practice-input-resources"
                    placeholder={placeholderTextArea}
                    rows={3}
                  />
                </Form.Item>
                <Flex
                  style={{
                    justifySelf: 'center',
                    alignSelf: 'center',
                  }}
                  gap="16px"
                >
                  {!requireText && (
                    <Button
                      size="sm"
                      htmlType="submit"
                      id="id-tour-helpers-create-button-preview"
                      variant="outline"
                      loading={isLoadingSubmit}
                    >
                      PULAR
                    </Button>
                  )}

                  <Button
                    htmlType="submit"
                    size="sm"
                    loading={isLoadingSubmit}
                    // onClick={() => setStep(3)}
                    disabled={feedback === undefined || feedback === ''}
                  >
                    ENVIAR
                  </Button>
                </Flex>
              </Flex>
              <Flex
                vertical
                justify="space-between"
                style={{
                  width: '100%',
                  height: isMobile ? '164px' : '234px',
                  display: step === 3 ? 'flex' : 'none',
                }}
                gap={isMobile ? '8px' : '16px'}
              >
                <Text size="lg" align="justify">
                  Muito obrigado pelo feedback! Estamos sempre trabalhando para
                  atender melhor às suas necessidades.
                </Text>

                {user?.whiteLabel ? (
                  <>
                    <Button
                      style={{
                        justifySelf: 'center',
                        alignSelf: 'center',
                        position: 'relative',
                        top: isMobile ? -20 : -73,
                        zIndex: 100,
                      }}
                      type="primary"
                      size="sm"
                      onClick={() => setIsOpenFeedback(false)}
                    >
                      FECHAR
                    </Button>
                    <img
                      alt="Obrigado pelo feedback"
                      src={feedbackThankYou}
                      style={{
                        position: 'absolute',
                        right: 0,
                        bottom: 0,
                        maxHeight: '100%',
                        maxWidth: '100%',
                        objectFit: 'contain',
                        overflow: 'hidden',
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Flex gap="16px" justify="center">
                      <img alt="coração" src={heart} />
                      <img alt="coração" src={heart} />
                      <img alt="coração" src={heart} />
                    </Flex>
                    <img
                      alt="lumi-acenando"
                      src={lumiWaving}
                      width={isMobile ? 84 : 115}
                      style={{ position: 'absolute', right: 0, bottom: -20 }}
                    />
                    <Button
                      style={{
                        justifySelf: 'center',
                        alignSelf: 'center',
                      }}
                      type="primary"
                      size="sm"
                      onClick={() => setIsOpenFeedback(false)}
                    >
                      FECHAR
                    </Button>
                  </>
                )}
              </Flex>
            </Form>
          </div>
        </Flex>
      </Flex>
    </div>
  );
}

export default Feedback;
