import { useMediaQuery } from '@chakra-ui/react';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import SearchBar from 'components/V2/SearchBar';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Flex, Spin } from 'antd';
import cosmo from 'assets/images/cosmo-with-bullseye.png';
import funcionality from 'assets/images/white-label/funcionalities.png';
import { useHistory, useLocation } from 'react-router-dom';
import BackButton from 'components/buttons/back-button';
import useHelpers from 'core/features/helpers/hooks/useHelpers';
import { HelperDetails } from 'core/features/helpers/typings';
import React from 'react';
import LinkBox from 'components/link-box';
import { useCurrentUser } from 'core/features/user/store';

function Funcionalities() {
  const { colorPrimary, colorPrimaryLight } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState<string>();
  const history = useHistory();
  const { user } = useCurrentUser();

  let location = useLocation();
  useEffect(() => {
    if (location.search.includes('search')) {
      const queryParams = new URLSearchParams(location.search);
      setSearch(queryParams.get('search') as string);
    }
  }, []);

  const { data, isLoading } = useHelpers({
    page: 1,
    limit: 100,
    classifications: ['Funcionalidades'],
    search,
    active: true,
  });

  const groupedData = (data?.data || []).reduce(
    (acc: { [key: string]: HelperDetails[] }, obj: HelperDetails) => {
      const subClass = obj.subClassification;
      if (!acc[subClass]) {
        acc[subClass] = [];
      }
      acc[subClass].push(obj);
      return acc;
    },
    {}
  );
  return (
    <Layout.Container>
      <Flex vertical gap={isMobile ? '24px' : '32px'}>
        <BackButton onClick={() => history.goBack()} />
        <div
          style={{
            backgroundImage: "url('/estrelas_1.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: colorPrimary,
            borderRadius: '20px',
            position: 'relative',
            height: '204px',
            padding: '16px',
            marginBottom: '24px',
          }}
        >
          <Flex style={{ width: '100%' }} justify="space-between">
            <Flex
              vertical
              gap={isMobile ? '16px' : '8px'}
              style={{ maxWidth: 413 }}
            >
              {isMobile ? (
                <Flex justify="space-between">
                  <Title
                    style={{
                      color: 'white',
                      fontSize: isMobile ? '25px' : '40px',
                      marginBottom: '20px',
                    }}
                  >
                    Funcionalidades
                  </Title>
                  <img
                    src={user?.whiteLabel ? funcionality : cosmo}
                    style={{
                      position: 'absolute',
                      top: user?.whiteLabel ? 0 : -20,
                      right: 0,
                      overflow: 'visible',
                    }}
                    width={user?.whiteLabel ? '98px' : '127px'}
                  />
                </Flex>
              ) : null}
              {!isMobile && (
                <Title
                  style={{
                    color: 'white',
                    fontSize: isMobile ? '30px' : '40px',
                    textAlign: 'center',
                  }}
                >
                  Funcionalidades
                </Title>
              )}

              <Text size="md" color="white" align="justify">
                Procurando por um artigo específico? Utilize nossa barra de
                pesquisa abaixo para encontrar rapidamente o que você precisa.
              </Text>
            </Flex>
            {!isMobile && (
              <img
                src={user?.whiteLabel ? funcionality : cosmo}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  right: 16,
                  overflow: 'visible',
                }}
                width={user?.whiteLabel ? '331px' : '365px'}
              />
            )}
          </Flex>

          <Flex justify="center">
            <div style={{ position: 'absolute', bottom: -20 }}>
              <SearchBar value={search} onSearch={setSearch} />
            </div>
          </Flex>
        </div>

        {isLoading ? (
          <Spin size="large" />
        ) : (
          <React.Fragment>
            {Object.entries(groupedData).map(([subClass, items]) => (
              <LinkBox
                title={subClass}
                key={subClass}
                items={items.map((item) => ({
                  id: item.id,
                  title: item.title,
                  link: `/helpers/${item.id}`,
                }))}
              />
            ))}
          </React.Fragment>
        )}
      </Flex>
    </Layout.Container>
  );
}

export default Funcionalities;
