import { Col, Flex, Row } from 'antd';
import Text from 'components/typography/text';
import { PiPencilThin } from 'react-icons/pi';
import { FiTrash2 } from 'react-icons/fi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import narratorIcon from 'assets/images/narrator-icon.png';
import endIcon from 'assets/images/interaction-end.png';
import decisionIcon from 'assets/images/interaction-decision.png';
import reinforcementIcon from 'assets/images/interaction-reinforcement.png';

import {
  ContentType,
  Interaction,
  StoryRoute,
} from 'core/features/stories/typings';
import { StoreItemDetails } from 'core/features/store-items/typings';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import SubTitle from 'components/typography/subTitle';

interface InteractionProps {
  interaction: Interaction;
  routes: StoryRoute[];
  character?: StoreItemDetails;
  onRemove: () => void;
  onEdit: () => void;
  onChangeRoute: (routeIndex: number) => void;
}

function InteractionBox({
  interaction,
  character,
  onRemove,
  onEdit,
  routes,
  onChangeRoute,
}: InteractionProps) {
  const { colorPrimary } = useU4heroColors();

  const colors = [
    'rgba(173, 115, 235, 0.30)',
    'rgba(21, 101, 192, 0.30)',
    'rgba(185, 65, 127, 0.30)',
  ];

  const letters = ['A', 'B', 'C', 'D', 'E'];

  const decision = {
    icon: decisionIcon,
    color: '#C6691C',
    routeColor: '#AD73EB',
  };
  const reinforcement = {
    icon: reinforcementIcon,
    color: '#607B56',
    routeColor: '',
  };

  if (!interaction) {
    return null;
  }

  const actionButtons = (
    <Flex vertical justify="center" align="center" gap="16px">
      <PiPencilThin
        size={30}
        color={'white'}
        style={{
          backgroundColor: colorPrimary,
          borderRadius: '50%',
          padding: '4px',
          cursor: 'pointer',
        }}
        onClick={onEdit}
      />
      <FiTrash2
        onClick={onRemove}
        size={30}
        color="white"
        style={{
          backgroundColor: colorPrimary,
          borderRadius: '50%',
          cursor: 'pointer',
          padding: '4px',
        }}
      />
    </Flex>
  );

  if (interaction.contentType === ContentType.DIALOGUE) {
    return (
      <Flex
        gap="12px"
        align="center"
        style={{ minHeight: '100px', width: '100%' }}
      >
        <img
          src={character?.imageUrl || narratorIcon}
          alt={character?.name || 'narrator'}
          style={{
            width: '60px',
            borderRadius: '50%',
            background: colors[interaction.index % colors.length],
            padding: '8px',
          }}
        />
        <Flex
          style={{
            borderRadius: '10px',
            background: colors[interaction.index % colors.length],
            padding: '8px',
            height: '100%',
            flex: 1,
          }}
          vertical
          align="center"
          justify="center"
          gap="8px"
        >
          <Text size="sm" bold>
            {interaction?.textContent}
          </Text>
        </Flex>
        {actionButtons}
      </Flex>
    );
  }

  if (interaction.contentType === ContentType.END) {
    return (
      <Flex
        gap="12px"
        align="center"
        style={{
          minHeight: '100px',
          width: '100%',
          position: 'relative',
          marginTop: interaction.index === 0 ? '20px' : undefined,
        }}
      >
        <img
          src={endIcon}
          style={{
            width: '60px',
            height: '60px',
            backgroundColor: colors[interaction.index % colors.length],
            borderRadius: '50%',
            padding: '8px',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
            top: '-30px',
          }}
        />
        <Flex
          style={{
            borderRadius: '10px',
            background: colors[interaction.index % colors.length],
            padding: '8px',
            height: '100%',
            flex: 1,
          }}
          vertical
          align="center"
          justify="center"
          gap="8px"
        >
          <Text size="sm" bold align="center">
            FIM!
          </Text>
        </Flex>
        {actionButtons}
      </Flex>
    );
  }
  const options =
    interaction.contentType === ContentType.DECISION ? decision : reinforcement;

  return (
    <>
      <Flex
        gap="12px"
        align="center"
        style={{ minHeight: '100px', width: '100%' }}
      >
        <Flex
          gap="12px"
          style={{
            width: '100%',
            padding: '20px 8px 8px 8px',
            position: 'relative',
            background: hex2rgba(options.color, 0.3),
            borderRadius: '10px',
            marginTop: '40px',
          }}
          vertical
        >
          <img
            src={options.icon}
            style={{
              width: '60px',
              height: '60px',
              backgroundColor: hex2rgba(options.color, 0.4),
              borderRadius: '50%',
              padding: '8px',
              position: 'absolute',
              left: '50%',
              transform: 'translateX(-50%)',
              top: '-40px',
            }}
          />
          <Text size="md" align="justify">
            {interaction.textContent}
          </Text>

          <Row gutter={[16, 16]} align="stretch">
            {interaction?.options?.map((option) => (
              <Col
                key={option.index}
                xs={24}
                md={option.imageUrl ? 12 : 24}
                xxl={option.imageUrl ? 6 : 24}
              >
                <Flex align="center" gap="8px" style={{ height: '100%' }}>
                  <Text
                    size="md"
                    bold
                    style={{
                      borderRadius: '5px',
                      borderRight: `2px solid ${hex2rgba(options.color, 0.4)}`,
                      borderBottom: `2px solid ${hex2rgba(options.color, 0.4)}`,
                      background: hex2rgba(options.color, 0.3),
                      padding: '4px 8px',
                    }}
                  >
                    {letters[option.index]}
                  </Text>
                  <Flex
                    style={{
                      padding: '8px',
                      borderRadius: '5px',
                      borderRight: `2px solid ${hex2rgba(options.color, 0.4)}`,
                      borderBottom: `2px solid ${hex2rgba(options.color, 0.4)}`,
                      background: hex2rgba(options.color, 0.3),
                      width: '100%',
                      flex: 1,
                    }}
                  >
                    {option.imageUrl ? (
                      <img
                        src={option.imageUrl}
                        alt={option.text}
                        style={{
                          width: '100%',
                          height: '200px',
                          objectFit: 'cover',
                          borderRadius: '10px',
                        }}
                      />
                    ) : (
                      <Text size="sm" align="justify">
                        {option.text}
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Col>
            ))}
          </Row>
        </Flex>
        {actionButtons}
      </Flex>

      {interaction.contentType === ContentType.DECISION && (
        <Row gutter={[16, 16]} align="stretch" style={{ marginTop: '16px' }}>
          {interaction?.options?.map((option) => (
            <Col span={12}>
              <Flex
                style={{
                  padding: '16px 8px 8px 8px',
                  borderRadius: '10px',
                  position: 'relative',
                  background: hex2rgba(options.routeColor, 0.3),
                  height: '100%',
                  cursor: 'pointer',
                }}
                onClick={() => onChangeRoute(option.nextRouteIndex ?? 0)}
              >
                <SubTitle
                  style={{
                    width: '30px',
                    height: '30px',
                    backgroundColor: hex2rgba(options.routeColor, 0.4),
                    borderRadius: '50%',
                    padding: '4px',
                    position: 'absolute',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    top: '-20px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {letters[option.index]}
                </SubTitle>
                <Text size="md" bold>
                  <strong>Destino: </strong>{' '}
                  {`"Rota ${(option.nextRouteIndex ?? 0) + 1} - ${
                    routes?.[option.nextRouteIndex as number]?.name
                  }"`}
                </Text>
              </Flex>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
}

export default InteractionBox;
