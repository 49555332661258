import http from 'infra/http';
import { useMutation } from 'react-query';

export function useChangeAdaptativeOrder() {
  return useMutation(
    (data: { id: string }[]) => {
      return http.post(`/v2/tags/adaptative-order`, data);
    },
    { retry: false }
  );
}
