import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import Button from 'components/buttons/button';
import PracticesTable from '../../components/PracticesTable';
import { useState } from 'react';
import PracticeFeedbackModal from '../../components/practiceFeedbackModal';
import { t } from 'core/resources/strings';

function PracticesTab() {
  const history = useHistory();
  const [check] = useCheckPermissions();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isOpenModalFeedback, setIsOpenModalFeedback] = useState(false);
  const [practiceId, setPracticeId] = useState<string>();

  return (
    <>
      {isOpenModalFeedback ? (
        <PracticeFeedbackModal
          id={practiceId}
          onClose={() => {
            setIsOpenModalFeedback(false);
            setPracticeId(undefined);
          }}
        />
      ) : null}
      <Flex
        gap="24px"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '16px' }}
      >
        {check([Permissions.U4hero.Practices.Create]) ? (
          <Button
            id="id-tour-create-practice-button-generate-with-ai"
            size={'md'}
            onClick={() => history.push('/practices/create-with-ai')}
            style={{
              alignSelf: 'end',
            }}
            variant={'outline'}
          >
            {t('app.gerarInspiracaoComIA')}
          </Button>
        ) : null}
        {check([Permissions.U4hero.Practices.Create]) ? (
          <Button
            size="md"
            onClick={() => history.push('/practices/create')}
            id="id-tour-practices-button-add"
          >
            {t('app.criar')} {t('app.inspiracao')}
          </Button>
        ) : null}
      </Flex>
      <PracticesTable
        onViewFeedback={(id) => {
          setPracticeId(id);
          setIsOpenModalFeedback(true);
        }}
      />
    </>
  );
}

export default PracticesTab;
