import { StoreItemDetails } from 'core/features/store-items/typings';
import { SoundEffect } from 'infra/helpers/types';

export interface StoryForm {
  id?: string;
  tagId: string;
  name: string;
  description: string;
  routes: StoryRoute[];
  scenario: string;
  published: boolean;
}

export enum ContentType {
  DIALOGUE = 0,
  DECISION = 1,
  REINFORCEMENT = 2,
  END = 3,
}

export enum InteractionRole {
  NARRATOR = 0,
  CHARACTER = 1,
}

export interface Interaction {
  index: number;
  contentType: ContentType;
  role?: InteractionRole;
  characterId?: string;
  textContent?: string;
  imageUrl?: string;
  voiceId?: string;
  soundEffect?: SoundEffect;
  optionsWithImage?: boolean;
  nextRouteIndex?: number;
  options?: {
    text: string;
    imageUrl?: string;
    index: number;
    nextRouteIndex?: number;
    feedback?: string;
    isCorrect?: boolean;
  }[];
}

export interface StoryRoute {
  index: number;
  name: string;
  characters: StoreItemDetails[];
  interactions: Interaction[];
}

export interface StoryDetails extends StoryForm {
  id: string;
  userId: string;
  author: string;
  createdAt: string;
  updatedAt: string;
  locale: string;
}
