import { useMediaQuery } from '@chakra-ui/react';
import {
  Flex,
  Modal,
  message,
  Input,
  Row,
  Col,
  Checkbox,
  Form,
  Spin,
} from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Button from 'components/buttons/button';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';
import CustomizedRequiredMark from 'components/required-mark';
import { useEffect } from 'react';
import { StoryRoute } from 'core/features/stories/typings';

interface ModalRouteProps {
  onClose: () => void;
  index: number;
  route?: StoryRoute;
  onSave: (route: StoryRoute) => void;
}

function ModalRoute({ route, onClose, index, onSave }: ModalRouteProps) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data, isLoading } = useStoreItems({
    page: 1,
    limit: 1000,
    types: ['character'],
  });

  const [routeForm] = Form.useForm();

  useEffect(() => {
    routeForm.resetFields();
  }, [route, routeForm]);

  const handleSave = (createAnother?: boolean) => {
    routeForm
      .validateFields()
      .then((values) => {
        const newRoute: StoryRoute = {
          ...values.route,
          interactions: route?.interactions ?? [],
          index,
          characters: data?.data
            .filter((item) => values.route.characters.includes(item.id))
            .map((item) => ({
              id: item.id,
              name: item.name,
              imageUrl: item.imageUrl,
            })),
        };
        onSave(newRoute);
        if (createAnother) {
          routeForm.resetFields();
        } else {
          onClose();
        }
      })
      .catch((error) => {
        console.error('Validation failed:', error);
      });
  };

  return (
    <Modal
      title={<SubTitle primary>{route ? 'Editar Rota' : 'Nova Rota'}</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={
        <Flex
          vertical
          justify="center"
          align="center"
          gap={isMobile ? '12px' : '24px'}
        >
          <Button
            htmlType="button"
            size="md"
            onClick={() => handleSave()}
            id="id-tour-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
          <Button
            variant="outline"
            size="md"
            onClick={() => handleSave(true)}
            id="id-tour-modal-button-save-and-create-new-route"
          >
            SALVAR E CRIAR NOVA ROTA
          </Button>
        </Flex>
      }
      width={isMobile ? '90%' : 640}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Form
        form={routeForm}
        layout="vertical"
        requiredMark={CustomizedRequiredMark}
        initialValues={{
          route: {
            name: route?.name ?? '',
            characters: route?.characters.map((char) => char.id) ?? [],
            interactions: route?.interactions ?? [],
          },
        }}
      >
        <Form.Item
          name={['route', 'name']}
          label="Nome da Rota"
          rules={[
            { required: true, message: 'Por favor, insira o nome da rota' },
          ]}
        >
          <Input
            id={`id-tour-create-story-input-route-name`}
            placeholder="Insira o nome da rota"
            size={isMobile ? 'middle' : 'large'}
          />
        </Form.Item>

        {isLoading && <Spin size="large" />}
        <Form.Item
          name={['route', 'characters']}
          label="Personagens:"
          rules={[
            {
              required: true,
              message: 'Por favor, selecione pelo menos um personagem',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row
              gutter={[16, 16]}
              style={{ maxHeight: '300px', overflowY: 'auto' }}
            >
              {data?.data?.map((item) => (
                <Col xs={12} md={8} key={item.id}>
                  <Checkbox value={item.id}>
                    <img
                      src={item.imageUrl}
                      alt={item.name}
                      style={{
                        width: '100%',
                        height: '150px',
                        objectFit: 'cover',
                        borderRadius: '10px',
                        backgroundColor: '#4891e0',
                      }}
                    />
                    <div>{item.name}</div>
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ModalRoute;
