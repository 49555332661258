import Polyglot from "node-polyglot";

export const getLanguagePreference = () => {
  return localStorage.getItem('language') || 'pt-br'; 
};

export const setLanguagePreference = (language:string) => {
  localStorage.setItem('language', language);
};

export const currentLocale = getLanguagePreference() as keyof {
  en: string;
  es: string;
  'pt-br': string;
};


const polyglot = new Polyglot({
  locale: currentLocale,
});

let { t, extend } = polyglot;

t = t.bind(polyglot);
extend = extend.bind(polyglot);

export const translate = (locale: string, strings: any) => {
  if (locale === currentLocale) {
    extend(strings);
  }
};

export { t };
