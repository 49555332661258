import { Button, Flex, notification } from 'antd';
import { FaCircleCheck } from 'react-icons/fa6';
import { IoClose } from 'react-icons/io5';
import { MdCancel } from 'react-icons/md';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { hex2rgba } from './hex2Rgba';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';
import warningWhiteLabel from 'assets/images/white-label/hand-with-question.png';

const MySwal = withReactContent(Swal);
export const notificateError = (
  message = t('app.problemaAoRealizarOperacao')
) => {
  notification.config({ maxCount: 1 });
  notification.error({
    message: message,
    icon: <MdCancel color="red" size={30} />,
    placement: 'top',
    duration: 6,
    closeIcon: null,
  });
};

export const notificateSucess = (
  message = t('app.operacaoRealizadaComSucesso')
) => {
  notification.config({ maxCount: 1 });
  notification.success({
    message: message,
    icon: <FaCircleCheck size={30} color="green" />,
    placement: 'top',
    duration: 6,
    closeIcon: null,
  });
};

export const openNotification = ({
  message = t('app.mensagemSairSemSalvar'),
  onClose,
  colorPrimary,
  isMobile,
}: {
  message?: string;
  onClose: () => void;
  colorPrimary: string;
  isMobile: boolean;
}) => {
  const btn = (
    <Flex gap={isMobile ? '28px' : '12px'}>
      <Button
        key="back"
        style={{ border: `3px solid ${colorPrimary}`, color: colorPrimary }}
        onClick={() => notification.destroy()}
      >
        NÃO
      </Button>
      <Button
        key="submit"
        type="primary"
        style={{ backgroundColor: colorPrimary }}
        onClick={() => {
          notification.destroy();
          onClose();
        }}
      >
        SIM
      </Button>
    </Flex>
  );

  notification.config({ maxCount: 1 });
  notification.open({
    message: (
      <p
        style={{
          fontWeight: 600,
          fontSize: '18px',
          color: colorPrimary,
          fontFamily: 'Poppins',
        }}
      >
        {t('app.informacoesNaoSalvas')}
      </p>
    ),
    duration: 0,
    description: (
      <p
        style={{
          fontWeight: 400,
          fontSize: '14px',
          fontFamily: 'Poppins',
        }}
      >
        {message}
      </p>
    ),
    btn,
  });
};

export function showConfirm({
  message = t('app.desejaRealmenteRealizarEstaOperacao'),
  colorPrimary,
  isMobile,
}: {
  message?: string;
  colorPrimary: string;
  isMobile: boolean;
}) {
  const { user } = useCurrentUser.getState();

  return MySwal.fire({
    imageUrl: user?.whiteLabel ? warningWhiteLabel : '/warning.png',
    cancelButtonText: 'NÃO',
    cancelButtonColor: hex2rgba(colorPrimary, 0.3),
    title: (
      <p
        style={{
          color: colorPrimary,
          fontSize: isMobile ? '18px' : '24px',
          fontWeight: 700,
          textAlign: 'left',
        }}
      >
        {t('app.temCerteza')}
      </p>
    ),
    showCloseButton: true,
    closeButtonHtml: (
      <div
        style={{
          background: colorPrimary,
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <IoClose size={16} color="white" />
      </div>
    ),
    html: <Text>{message}</Text>,
    confirmButtonColor: colorPrimary,
    confirmButtonText: 'SIM',
    showCancelButton: true,
    reverseButtons: true,
    imageWidth: 150,
    imageHeight: 'auto',
    customClass: {
      title: 'my-title-class',
      image: 'my-image-class',
      actions: 'my-actions-class',
      htmlContainer: 'my-container-class',
      cancelButton: 'my-btn-class',
    },
  });
}
