import { InboxOutlined } from '@ant-design/icons';
import { Editor } from '@tinymce/tinymce-react';
import { Upload } from 'antd';
import { t } from 'core/resources/strings';
import axios from 'infra/http';
import { useRef } from 'react';
import { FaYelp } from 'react-icons/fa6';
import Swal from 'sweetalert2';

const { Dragger } = Upload;
function CustomEditor({
  value,
  onChange,
  folders,
  acceptFiles = false,
  hideUpload = false,
  editorId,
}: {
  value: string;
  onChange: (v: string) => void;
  folders: {
    images: string;
    files: string;
  };
  acceptFiles?: boolean;
  hideUpload?: boolean;
  editorId?: string;
}) {
  const editorRef = useRef<any>(null);
  const imageTypes = ['image/png', 'image/jpeg'];
  const fileTypes = [
    'application/pdf',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
  ];
  const uploadAndAddImage = async (file: any) => {
    const acceptedTypes = acceptFiles
      ? [...fileTypes, ...imageTypes]
      : imageTypes;

    if (!acceptedTypes.includes(file.type)) {
      Swal.fire({
        title: t('app.aviso'),
        text: t('app.formatoArquivoInvalido'),
        icon: 'info',
      });
      return null;
    }

    if (
      (imageTypes.includes(file.type) && file.size > 3e6) ||
      (fileTypes.includes(file.type) && file.size > 30e6)
    ) {
      Swal.fire({
        title: t('app.aviso'),
        text: t('app.tamanhoArquivoInvalido'),
        icon: 'info',
      });
      return null;
    }
    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data }: { data: { result: string } } = await axios.post(
        `/v1/file/generic-upload?folderKey=${
          acceptFiles ? folders.files : folders.images
        }&bucketName=u4hero-files&keepOriginalName=true`,
        formData
      );

      if (imageTypes.includes(file.type)) {
        editorRef.current.setContent(
          editorRef.current.getContent() +
            `<img src="${data.result}" alt="" width="100" height="100">`
        );
      } else {
        editorRef.current.setContent(
          editorRef.current.getContent() + `${data.result}`
        );
      }
    } finally {
    }
  };

  const generateHeadingId = () => {
    return `content_${Math.random().toString(36).substring(7)}`;
  };

  return (
    <>
      {!hideUpload && (
        <Dragger
          multiple={true}
          customRequest={(action: any) => {
            uploadAndAddImage(action.file);
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            {t('app.cliqueOuArrasteUmaImagem')}{' '}
            {acceptFiles ? t('app.ouArquivo') : ''}{' '}
            {t('app.paraAdicionarAoFinalDoDocumento')}
          </p>
        </Dragger>
      )}

      <Editor
        id={editorId}
        onInit={(evt, editor) => (editorRef.current = editor)}
        apiKey={process.env.REACT_APP_TINY_API_KEY}
        value={value}
        onEditorChange={(v, editor) => onChange(v)}
        init={{
          min_height: 500,
          menubar: true,
          plugins: [
            'advlist',
            'autolink',
            'lists',
            'media',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'code',
            'help',
            'wordcount',
            'autoresize',
          ],
          toolbar:
            'undo redo | blocks | ' +
            '|  forecolor backcolor blockquote |' +
            '| link image media |' +
            'bold italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat | help',
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          setup: function (editor) {
            editor.on('NodeChange', function (e) {
              if (e.element.nodeName === 'H1' || e.element.nodeName === 'H2') {
                const headingId = generateHeadingId();
                e.element.id = headingId;
              }
            });
          },
        }}
      />
    </>
  );
}

export default CustomEditor;
