import { ColorPicker, Flex, Form, Input, Switch } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import cosmoWithColors from 'assets/images/cosmo-with-colors.png';
import paintingLady from 'assets/images/white-label/painting-lady.png';
import './style.css';
import { useMediaQuery } from '@chakra-ui/media-query';
import { useCurrentUser } from 'core/features/user/store';

function ColorsSection() {
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const whiteLabel = Form.useWatch('whiteLabel', form);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  return (
    <GenericCard style={{ position: 'relative' }}>
      <Flex vertical style={{ width: '100%' }}>
        <Flex justify="space-between">
          <Text
            size="lg"
            color={colorPrimary}
            style={{ marginBottom: '16px', fontWeight: 700 }}
          >
            Personalização do Site
          </Text>
          <Form.Item name="whiteLabel" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Flex>
        {whiteLabel ? (
          <Text size="lg" align="justify" style={{ marginBottom: '8px' }}>
            Configure a paleta de cores da sua organização para uma identidade
            visual exclusiva no site!
          </Text>
        ) : (
          <Text size="lg" align="justify">
            Ao ativar a Personalização do Site, o logo da sua organização será
            integrado à nossa plataforma e você poderá definir a paleta de cores
            conforme a identidade visual da sua marca.
          </Text>
        )}
        {whiteLabel ? (
          <Flex vertical={isMobile} justify="space-between">
            <Flex vertical>
              <Form.Item
                name="colorPrimary"
                label="Cor Principal:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
              <Form.Item
                name="colorPrimaryMedium"
                label="Tom Escuro da Cor Principal:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
              <Form.Item
                name="colorPrimaryLight"
                label="Tom Claro da Cor Principal:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
            </Flex>
            {!isMobile ? (
              <img
                src={user?.whiteLabel ? paintingLady : cosmoWithColors}
                width="140px"
              />
            ) : null}
            <Flex vertical>
              <Form.Item
                name="colorSecondary"
                label="Cor Segundária:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
              <Form.Item
                name="colorSecondaryMedium"
                label="Tom Escuro da Cor Segundária:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
              <Form.Item
                name="colorSecondaryLight"
                label="Tom Claro da Cor Segundária:"
                getValueFromEvent={(color, hex) => {
                  return '#' + color.toHex();
                }}
                style={isMobile ? { display: 'flex', height: '34px' } : {}}
              >
                <ColorPicker
                  className={isMobile ? undefined : 'custom-color-picker'}
                />
              </Form.Item>
            </Flex>
          </Flex>
        ) : null}
      </Flex>
    </GenericCard>
  );
}

export default ColorsSection;
