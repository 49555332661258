import { useMediaQuery } from '@chakra-ui/react';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import SearchBar from 'components/V2/SearchBar';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { Col, Flex, Row } from 'antd';
import cosmo from 'assets/images/cosmo-with-floater.png';
import floater from 'assets/images/white-label/floater.png';
import firstSteps from 'assets/images/first-steps.png';
import funcionalities from 'assets/images/funcionalities.png';
import generalConfig from 'assets/images/general-config.png';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import SubTitle from 'components/typography/subTitle';
import { Link, useHistory } from 'react-router-dom';
import useCountByClassification from 'core/features/helpers/hooks/useCountByClassification';
import Dot from 'components/dot';
import { Pluralizar } from 'infra/helpers/plural';
import useHelpers from 'core/features/helpers/hooks/useHelpers';
import { HelperDetails } from 'core/features/helpers/typings';
import Button from 'components/buttons/button';
import { PiBinoculars } from 'react-icons/pi';
import TourMap from '../helpers-u4hero/components/TourMap';
import { useCurrentUser } from 'core/features/user/store';

function HelpersCenter() {
  const { colorPrimary, colorPrimaryLight, colorGreyBorderAndFont } =
    useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState<string>();
  const { data: countFirstSteps } =
    useCountByClassification('Primeiros Passos');
  const { data: countConfig } = useCountByClassification(
    'Configurações Gerais'
  );
  const { data: countFuncionalities } =
    useCountByClassification('Funcionalidades');
  const { user } = useCurrentUser();
  const { data: helpers, isLoading } = useHelpers({
    page: 1,
    limit: 100,
    search,
    disabled: search === '' || search === undefined,
    active: true,
  });

  const groupedData = (helpers?.data || []).reduce(
    (acc: { [key: string]: HelperDetails[] }, obj: HelperDetails) => {
      const classification = obj.classification;
      if (!acc[classification]) {
        acc[classification] = [];
      }
      acc[classification].push(obj);
      return acc;
    },
    {}
  );

  const [isMapOpen, setIsMapOpen] = useState(false);

  const items = [
    {
      label: 'Primeiros Passos',
      description:
        'Se você está dando os primeiros passos com a U4Hero, clique aqui! Você vai entender definições básicas.',
      image: firstSteps,
      link: '/helpers/first-steps',
      count: search
        ? groupedData?.['Primeiros Passos']?.length ?? 0
        : countFirstSteps?.total,
    },
    {
      label: 'Configurações Gerais',
      description:
        'Explore como gerenciar suas configurações de perfil, criar novos perfis e atribuir permissões de forma eficaz dentro da plataforma.',
      image: generalConfig,
      link: '/helpers/general-config',
      count: search
        ? groupedData?.['Configurações Gerais']?.length ?? 0
        : countConfig?.total,
    },
    {
      label: 'Funcionalidades',
      description:
        'Descubra em detalhes todas as funcionalidades disponíveis, explorando como cada uma pode ser aproveitada ao máximo.',
      image: funcionalities,
      link: '/helpers/funcionalities',
      count: search
        ? groupedData?.['Funcionalidades']?.length ?? 0
        : countFuncionalities?.total,
    },
  ];
  return (
    <Layout.Container>
      <Flex vertical gap="16px">
        <Title primary>Central de Ajuda</Title>
        <div
          style={{
            backgroundImage: "url('/estrelas_1.png')",
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundColor: colorPrimary,
            borderRadius: '20px',
            position: 'relative',
            height: '204px',
            padding: '16px',
            marginBottom: '24px',
          }}
        >
          <Flex
            vertical
            gap={isMobile ? '16px' : '8px'}
            style={{ maxWidth: 413 }}
          >
            {isMobile ? (
              <Flex justify="space-between">
                <Title
                  style={{
                    color: 'white',
                    fontSize: isMobile ? '30px' : '40px',
                    maxWidth: '170px',
                  }}
                >
                  Central de ajuda
                </Title>
                <img
                  src={user?.whiteLabel ? floater : cosmo}
                  style={{
                    position: 'absolute',
                    top: -20,
                    right: 0,
                    overflow: 'visible',
                  }}
                  width={'106px'}
                />
              </Flex>
            ) : null}

            {!isMobile && (
              <Title
                style={{
                  color: 'white',
                  fontSize: isMobile ? '20px' : '40px',
                  textAlign: 'center',
                }}
              >
                Central de ajuda
              </Title>
            )}
            <Text size="md" color="white">
              Seja você um administrador, coordenador ou educador, aqui está o
              seu guia para dominar A U4Hero e se tornar o protagonista do seu
              trabalho.
            </Text>
          </Flex>
          {!isMobile && (
            <img
              src={user?.whiteLabel ? floater : cosmo}
              style={{
                position: 'absolute',
                bottom: isMobile ? undefined : 0,
                top: isMobile ? 0 : undefined,
                right: 54,
                overflow: 'visible',
              }}
              width={isMobile ? '106px' : '216px'}
            />
          )}

          <Flex justify="center">
            <div style={{ position: 'absolute', bottom: -20 }}>
              <SearchBar value={search} onSearch={setSearch} />
            </div>
          </Flex>
        </div>
        <Flex justify="end">
          <Button
            onClick={() => setIsMapOpen(true)}
            size="md"
            id="id-tour-help-center-explore-tours"
            style={{
              display: 'flex',
              gap: isMobile ? '4px' : '16px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <PiBinoculars size={isMobile ? 22 : 28} color={'white'} /> EXPLORAR
            TOURS
          </Button>
        </Flex>
        <Row gutter={isMobile ? [0, 16] : [24, 0]} align="stretch">
          {items.map((item) => (
            <Col key={item.label} span={isMobile ? 24 : 8}>
              <Link
                to={
                  search && groupedData && groupedData[item.label]?.length
                    ? `${item.link}?search=${search}`
                    : item.link
                }
              >
                <GenericCard
                  height="100%"
                  background={
                    groupedData && groupedData[item.label]?.length
                      ? colorPrimaryLight
                      : '#fff'
                  }
                  hover={colorPrimaryLight}
                >
                  <Flex vertical align="center" gap="10px">
                    <img width="164px" src={item.image} />
                    <SubTitle>{item.label}</SubTitle>
                    <Text size="lg">{item.description}</Text>
                    <Flex
                      gap="8px"
                      align="center"
                      justify="flex-end"
                      style={{ width: '100%' }}
                    >
                      <Dot size={8} color={colorGreyBorderAndFont} />
                      <Text size="lg" color={colorGreyBorderAndFont}>
                        {item.count}{' '}
                        {Pluralizar(
                          'Ajuda',
                          item.count !== undefined && item.count > 1
                        )}
                      </Text>{' '}
                    </Flex>
                  </Flex>
                </GenericCard>
              </Link>
            </Col>
          ))}
        </Row>
      </Flex>
      {isMapOpen ? <TourMap onClose={() => setIsMapOpen(false)} /> : null}
    </Layout.Container>
  );
}

export default HelpersCenter;
