import { useMediaQuery } from '@chakra-ui/react';
import { Form, Radio, Space } from 'antd';
import SelectSoundEffect from 'components/modals/modal-interaction/components/selectSoundEffect';
import { ContentType } from 'infra/helpers/types';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { AiFillSound } from 'react-icons/ai';

const voicesPtBr = [
  {
    id: 'Vitoria',
    name: 'Vitória',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Vitoria.mp3',
  },
  {
    id: 'Thiago',
    name: 'Thiago',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Thiago.mp3',
  },
  {
    id: 'Camila',
    name: 'Camila',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Camila.mp3',
  },
];

const voicesEn = [
  {
    id: 'Danielle',
    name: 'Danielle',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/danielle.mp3',
  },
  {
    id: 'Joanna',
    name: 'Joanna',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/Joanna.mp3',
  },
  {
    id: 'Matthew',
    name: 'Matthew',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/matthew.mp3',
  },
  {
    id: 'Ruth',
    name: 'Ruth',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/ruth.mp3',
  },
  {
    id: 'Stephen',
    name: 'Stephen',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/stephen.mp3',
  },
];

const voicesEs = [
  {
    id: 'Mia',
    name: 'Mia',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/mia.mp3',
  },
  {
    id: 'Andres',
    name: 'Andrés',
    sample:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/audios-exemplos/andres.mp3',
  },
];

function SoundsSection({ locale }: { locale: string }) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const contentType = Form.useWatch(['interaction', 'contentType'], form);

  const { colorGreyBorderAndFont } = useU4heroColors();

  const playAudioSample = (sampleUrl: string) => {
    const audio = new Audio(sampleUrl);
    audio.play();
  };
  if (contentType === ContentType.END) {
    return null;
  }
  const voices =
    locale === 'pt-br' ? voicesPtBr : locale === 'en' ? voicesEn : voicesEs;

  const validateSoundEffect = (_: any, value: any) => {
    if (!value) return Promise.resolve();

    const hasAudioUrl = value.audioUrl && value.audioUrl.trim() !== '';
    const hasTriggerTime =
      value.triggerTime !== undefined && value.triggerTime !== null;

    if ((hasAudioUrl || hasTriggerTime) && !(hasAudioUrl && hasTriggerTime)) {
      return Promise.reject(
        'Se você selecionar um efeito sonoro, é necessário definir também o tempo de disparo, e vice-versa'
      );
    }

    return Promise.resolve();
  };

  return (
    <>
      <Form.Item
        label="Selecione a voz da narração"
        name={['interaction', 'voiceId']}
      >
        <Radio.Group id="id-tour-modal-story-interaction-voiceId">
          <Space
            direction={isMobile ? 'vertical' : 'horizontal'}
            wrap
            style={{ width: '100%' }}
          >
            {voices.map((item) => (
              <Radio key={item.id} value={item.id}>
                <Space>
                  {item.name}
                  <AiFillSound
                    color={colorGreyBorderAndFont}
                    onClick={(e) => {
                      e.preventDefault();
                      playAudioSample(item.sample);
                    }}
                    style={{ cursor: 'pointer', fontSize: '16px' }}
                  />
                </Space>
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        label=""
        name={['interaction', 'soundEffect']}
        rules={[{ validator: validateSoundEffect }]}
      >
        {/* @ts-ignore */}
        <SelectSoundEffect />
      </Form.Item>
    </>
  );
}

export default SoundsSection;
