import React, { useEffect } from 'react';
import { Form, Input, Select, Row, Col, Flex, Switch, Radio } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Text from 'components/typography/text';
import Button from 'components/buttons/button';
import { useMediaQuery } from '@chakra-ui/react';
import { ContentType, StoryRoute } from 'core/features/stories/typings';
import UploadWithPreview from 'components/modals/modal-interaction/components/uploadImageWithPreview';
import SubTitle from 'components/typography/subTitle';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

interface InteractionOptionsProps {
  routes?: StoryRoute[];
}

const InteractionOptions: React.FC<InteractionOptionsProps> = ({ routes }) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const optionsWithImage = Form.useWatch(
    ['interaction', 'optionsWithImage'],
    form
  );
  const contentType = Form.useWatch(['interaction', 'contentType'], form);

  if (
    contentType !== ContentType.DECISION &&
    contentType !== ContentType.REINFORCEMENT
  ) {
    return null;
  }

  return (
    <Form.List name={['interaction', 'options']}>
      {(fields, { add, remove }) => (
        <Flex vertical>
          <Text size="md">Alternativas: *</Text>
          <Flex
            gap="16px"
            align="center"
            justify="flex-end"
            style={{ marginBottom: '16px' }}
          >
            <Text size="sm">Alternativas com Imagem:</Text>
            <Switch
              onChange={(checked) => {
                form.setFieldsValue({
                  interaction: { optionsWithImage: checked },
                });
              }}
            />
          </Flex>

          {optionsWithImage && contentType === ContentType.DECISION && (
            <Row
              gutter={[
                { xs: 16, md: 68 },
                { xs: 0, md: 0 },
              ]}
              align="stretch"
            >
              {fields.map((field, index) => (
                <Col xs={12} md={8} style={{ height: '100%' }}>
                  <Form.Item name={[field.name, 'index']} hidden />
                  <SubTitle
                    align="center"
                    style={{
                      background: hex2rgba(colorPrimary, 0.6),
                      borderRadius: '8px 8px 0px 0px',
                      color: 'white',
                    }}
                  >
                    {['A', 'B', 'C', 'D', 'E'][index]}
                  </SubTitle>
                  <Form.Item
                    name={[field.name, 'imageUrl']}
                    rules={[
                      {
                        required: optionsWithImage,
                        message: 'Por favor, insira a imagem!',
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <UploadWithPreview />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'nextRouteIndex']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira o destino da alternativa!',
                      },
                    ]}
                  >
                    <Select
                      size={isMobile ? 'middle' : 'large'}
                      placeholder="Destino"
                      options={routes?.map((route) => ({
                        label: route.name,
                        value: route.index,
                      }))}
                    />
                  </Form.Item>
                </Col>
              ))}
            </Row>
          )}

          {optionsWithImage && contentType === ContentType.REINFORCEMENT && (
            <Row
              gutter={[
                { xs: 16, md: 68 },
                { xs: 0, md: 0 },
              ]}
              align="stretch"
            >
              {fields.map((field, index) => (
                <Col xs={24} md={12} style={{ height: '100%' }}>
                  <Row
                    gutter={[
                      { xs: 8, md: 12 },
                      { xs: 0, md: 0 },
                    ]}
                  >
                    <Col xs={12} md={12} style={{ width: '100%' }}>
                      <Form.Item name={[field.name, 'index']} hidden />
                      <SubTitle
                        align="center"
                        style={{
                          background: hex2rgba(colorPrimary, 0.6),
                          borderRadius: '8px 8px 0px 0px',
                          color: 'white',
                        }}
                      >
                        {['A', 'B', 'C', 'D', 'E'][index]}
                      </SubTitle>
                      <Form.Item
                        name={[field.name, 'imageUrl']}
                        rules={[
                          {
                            required: optionsWithImage,
                            message: 'Por favor, insira a imagem!',
                          },
                        ]}
                      >
                        {/* @ts-ignore */}
                        <UploadWithPreview />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={12} style={{ width: '100%' }}>
                      <Flex vertical>
                        <Form.Item
                          name={[field.name, 'feedback']}
                          rules={[
                            {
                              required: true,
                              message: 'Por favor, insira o feedback!',
                            },
                          ]}
                          style={{ flex: 1 }}
                        >
                          <Input.TextArea
                            maxLength={150}
                            placeholder="Feedback"
                            size={isMobile ? 'middle' : 'large'}
                            style={{ width: '100%' }}
                            rows={7}
                          />
                        </Form.Item>

                        <Form.Item
                          name={[field.name, 'isCorrect']}
                          valuePropName="checked"
                        >
                          <Radio
                            onChange={(e) => {
                              fields.forEach((f, i) => {
                                if (i !== index) {
                                  form.setFieldValue(
                                    ['interaction', 'options', i, 'isCorrect'],
                                    false
                                  );
                                }
                              });
                            }}
                          >
                            Opção Correta
                          </Radio>
                        </Form.Item>
                      </Flex>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          )}

          {!optionsWithImage &&
            contentType === ContentType.DECISION &&
            fields.map((field, index) => (
              <Row
                gutter={[
                  { xs: 0, md: 16 },
                  { xs: 0, md: 0 },
                ]}
                align="middle"
                key={field.key}
              >
                <Form.Item name={[field.name, 'index']} hidden />

                <Col xs={24} md={16}>
                  <Form.Item
                    name={[field.name, 'text']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira a alternativa!',
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder={`0${index + 1} - Alternativa`}
                      maxLength={150}
                      rows={2}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} md={8}>
                  <Form.Item
                    name={[field.name, 'nextRouteIndex']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira o destino da alternativa!',
                      },
                    ]}
                  >
                    <Select
                      size={isMobile ? 'middle' : 'large'}
                      placeholder="Destino"
                      options={routes?.map((route) => ({
                        label: route.name,
                        value: route.index,
                      }))}
                    />
                  </Form.Item>
                </Col>
              </Row>
            ))}

          {!optionsWithImage &&
            contentType === ContentType.REINFORCEMENT &&
            fields.map((field, index) => (
              <Row
                gutter={[
                  { xs: 0, md: 16 },
                  { xs: 0, md: 0 },
                ]}
                align="middle"
                key={field.key}
              >
                <Form.Item name={[field.name, 'index']} hidden />

                <Col xs={24} md={20} style={{ width: '100%' }}>
                  <Flex align="baseline" gap="10px" style={{ width: '100%' }}>
                    <Text
                      size="md"
                      align="center"
                      style={{
                        background: hex2rgba(colorPrimary, 0.6),
                        borderRadius: '5px',
                        color: 'white',
                        padding: '7px 13px',
                      }}
                    >
                      {['A', 'B', 'C', 'D', 'E'][index]}
                    </Text>
                    <Form.Item name={[field.name, 'text']} style={{ flex: 1 }}>
                      <Input
                        maxLength={150}
                        size={isMobile ? 'middle' : 'large'}
                      />
                    </Form.Item>
                  </Flex>
                  <Flex align="baseline" gap="10px" style={{ width: '100%' }}>
                    <Text size="md" align="center">
                      Feedback *
                    </Text>
                    <Form.Item
                      name={[field.name, 'feedback']}
                      rules={[
                        {
                          required: true,
                          message: 'Por favor, insira o feedback!',
                        },
                      ]}
                      style={{ flex: 1 }}
                    >
                      <Input
                        maxLength={150}
                        size={isMobile ? 'middle' : 'large'}
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Flex>
                </Col>
                <Col xs={24} md={4}>
                  <Form.Item
                    name={[field.name, 'isCorrect']}
                    valuePropName="checked"
                  >
                    <Radio
                      onChange={(e) => {
                        fields.forEach((f, i) => {
                          if (i !== index) {
                            form.setFieldValue(
                              ['interaction', 'options', i, 'isCorrect'],
                              false
                            );
                          }
                        });
                      }}
                    >
                      Opção Correta
                    </Radio>
                  </Form.Item>
                </Col>
              </Row>
            ))}

          {fields.length < 5 && (
            <Flex justify="center">
              <Button
                size="md"
                variant="outline"
                onClick={() =>
                  add({
                    index: fields.length,
                    text: '',
                    nextRouteIndex: undefined,
                    imageUrl: undefined,
                  })
                }
                icon={<PlusOutlined />}
              />
            </Flex>
          )}
        </Flex>
      )}
    </Form.List>
  );
};

export default InteractionOptions;
