import { Flex } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import ReportPage from '../page';
import styled from 'styled-components';
import trophy from 'assets/images/report/trophy.png';
import goldMedal from 'assets/images/report/gold-medal.png';
import silverMedal from 'assets/images/report/silver-medal.png';
import warning from 'assets/images/report/red-warning.png';
import target from 'assets/images/report/target.png';
import star from 'assets/images/report/yellow-star.png';
import fire from 'assets/images/report/fire.png';
import goldBell from 'assets/images/report/gold-bell.png';

import { t } from 'core/resources/strings';

export const avaliationIcons = [
  {
    image: trophy,
    title: t('app.maiorPercentualEntreOsTiposDeAnalise'),
    description: t('app.indicacaoDoMelhorResultadoGeral'),
    validation: (
      current: {
        value: number;
        analysisTypeId: number;
      },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value === Math.max(...values.map((item) => item.value));
    },
  },
  {
    image: goldMedal,
    title: t('app.excelenteResultado') + '(>89,9):',
    description: t('app.descricaoExcelenteResultado'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value > 89.9;
    },
  },
  {
    image: warning,
    title: t('app.menorPercentualEntreOsTiposDeAnalise') + ':',
    description: t('app.descricaoMenorPercentualEntreOsTiposDeAnalise'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value === Math.min(...values.map((item) => item.value));
    },
  },
  {
    image: silverMedal,
    title: t('app.bomResultado') + ' (>79,9 | <89,9):',
    description: t('app.descricaoBomResultado'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value > 79.9 && current.value < 89.9;
    },
  },
  {
    image: goldBell,
    title: t('app.espacoParaMelhoria') + ' (>59,9 | <79,9):',
    description: t('app.descricaoEspacoParaMelhoria'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value > 59.9 && current.value < 79.9;
    },
  },
  {
    image: fire,
    title: t('app.precisaDeAtencao') + ' (<59,9):',
    description: t('app.descricaoPrecisaDeAtencao'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value < 59.9;
    },
  },
  {
    image: star,
    title: t('app.maiorResultadoNesteTipoDeAnalise') + ':',
    description: t('app.descricaoMaiorResultadoNesteTipoDeAnalise'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      const filteredValues = values.filter(
        (item) => item.analysisTypeId === current.analysisTypeId
      );
      return (
        current.value === Math.max(...filteredValues.map((item) => item.value))
      );
    },
  },
  {
    image: target,
    title: t('app.precisaSerTrabalhadaNaProximaTrilha') + ':',
    description: t('app.descricaoPrecisaSerTrabalhadaNaProximaTrilha'),
    validation: (
      current: { value: number; analysisTypeId: number },
      values: { value: number; analysisTypeId: number }[]
    ) => {
      return current.value < 59.9;
    },
  },
];

export default function ReportDataInfo() {
  const { colorPrimary, colorSecondary, colorGreyBorderAndFont } =
    useU4heroColors();

  return (
    <>
      <ReportPage pageNumber={13}>
        <Title primary style={{ textTransform: 'uppercase' }}>
          {t('app.dados')}
        </Title>
        <Text size="md" style={{ margin: '0 auto', lineHeight: '40px' }}>
          {t('app.dadosDescricao')}
        </Text>
        <Title primary style={{ textTransform: 'uppercase' }}>
          {t('app.iconesDeAvaliacao')}
        </Title>
        <Text size="md" style={{ margin: '0 auto', lineHeight: '40px' }}>
          {t('app.iconesDeAvaliacaoDescricao')}
        </Text>
        {avaliationIcons.slice(0, 4).map((item, index) => (
          <Flex
            key={index}
            style={{
              borderRadius: '20px',
              border: `2px solid ${colorGreyBorderAndFont}`,
              padding: '8px',
              width: '100%',
              gap: '16px',
            }}
          >
            <Flex
              align="center"
              justify="center"
              style={{
                borderRight: `2px solid ${colorGreyBorderAndFont}`,
                padding: '0 16px',
              }}
            >
              <img src={item.image} alt={item.title} width={80} height={80} />
            </Flex>
            <Flex
              vertical
              justify="center"
              align="center"
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <Text
                size="md"
                color={colorSecondary}
                style={{ margin: '0 auto', lineHeight: '40px' }}
                bold
              >
                {item.title}
              </Text>
              <Text size="md" style={{ margin: '0 auto', lineHeight: '40px' }}>
                {item.description}
              </Text>
            </Flex>
          </Flex>
        ))}
      </ReportPage>

      <ReportPage pageNumber={14}>
        {avaliationIcons.slice(4).map((item, index) => (
          <Flex
            key={index}
            style={{
              borderRadius: '20px',
              border: `2px solid ${colorGreyBorderAndFont}`,
              padding: '8px',
              width: '100%',
              gap: '16px',
            }}
          >
            <Flex
              align="center"
              justify="center"
              style={{
                borderRight: `2px solid ${colorGreyBorderAndFont}`,
                padding: '0 16px',
              }}
            >
              <img src={item.image} alt={item.title} width={80} height={80} />
            </Flex>
            <Flex
              vertical
              justify="center"
              align="center"
              style={{
                flex: 1,
                textAlign: 'center',
              }}
            >
              <Text
                size="md"
                color={colorSecondary}
                style={{ margin: '0 auto', lineHeight: '40px' }}
                bold
              >
                {item.title}
              </Text>
              <Text size="md" style={{ margin: '0 auto', lineHeight: '40px' }}>
                {item.description}
              </Text>
            </Flex>
          </Flex>
        ))}

        <Text size="md" style={{ margin: '0 auto', lineHeight: '40px' }}>
          <span style={{ fontWeight: 'bold', color: colorPrimary }}>
            {t('app.apresentacaoDosResultados')}:
          </span>{' '}
          {t('app.apresentacaoDosResultadosDescricao')}
        </Text>
      </ReportPage>
    </>
  );
}
