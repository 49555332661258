import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Row, Select } from 'antd';
import Text from 'components/typography/text';
import { SoundEffect } from 'infra/helpers/types';
const soundEffects = [
  {
    id: 1,
    label: 'Batida',
    audioUrl:
      'https://u4hero-files.s3.amazonaws.com/u4heroStories/surpresa.mp3',
  },
  {
    id: 2,
    label: 'Surpresa',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/batida.mp3',
  },
  {
    id: 3,
    label: 'Magia',
    audioUrl: 'https://u4hero-files.s3.amazonaws.com/u4heroStories/magia.mp3',
  },
  {
    id: 4,
    label: 'Tempo',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/tempo.mp3',
  },
  {
    id: 5,
    label: 'Passos',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/passos.mp3',
  },
  {
    id: 6,
    label: 'Porta Abrindo/Fechando',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/porta.mp3',
  },
  {
    id: 7,
    label: 'Vento e Folhas',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/folhas.mp3',
  },
  {
    id: 8,
    label: 'Objeto Quebrando',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/vidro.mp3',
  },
  {
    id: 9,
    label: 'Copo Enchendo',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/copo-enchendo.mp3',
  },
  {
    id: 10,
    label: 'Relâmpago',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/relampago.mp3',
  },
  {
    id: 11,
    label: 'Fogo',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/fogo.mp3',
  },
  {
    id: 12,
    label: 'Movimento',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/movimento.mp3',
  },
  {
    id: 13,
    label: 'Moedas',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/moedas.mp3',
  },
  {
    id: 14,
    label: 'Telefone Tocando',
    audioUrl:
      'https://u4hero-files.s3.sa-east-1.amazonaws.com/u4heroStories/telefone-tocando.mp3',
  },
];

export default function SelectSoundEffect({
  value,
  onChange,
}: {
  value: SoundEffect;

  onChange: (value: SoundEffect) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Row
      gutter={[
        { xs: 0, md: 48 },
        { xs: 16, md: 0 },
      ]}
    >
      <Col xs={24} md={12}>
        <Flex gap={8} align="center">
          <Text size="md" style={{ width: '100%' }}>
            Efeito Sonoro:
          </Text>
          <Select
            options={soundEffects.map((soundEffect) => ({
              label: soundEffect.label,
              value: soundEffect.id,
            }))}
            onChange={(id) => {
              const soundEffect = soundEffects.find((item) => item.id === id);
              if (soundEffect) {
                onChange({
                  id: soundEffect.id,
                  audioUrl: soundEffect.audioUrl,
                  triggerTime: value?.triggerTime,
                });
              }
            }}
            value={value?.id}
            placeholder="Selecione"
            allowClear
            size={isMobile ? 'middle' : 'large'}
          />
        </Flex>
      </Col>
      <Col xs={24} md={12}>
        <Flex gap={8} align="center">
          <Text size="md" style={{ width: '100%' }}>
            Reproduzir no:
          </Text>
          <Select
            size={isMobile ? 'middle' : 'large'}
            allowClear
            placeholder="Selecione"
            value={value?.triggerTime}
            onChange={(triggerTime) => {
              onChange({
                id: value?.id,
                audioUrl: value?.audioUrl,
                triggerTime: triggerTime,
              });
            }}
            options={[
              {
                label: 'Início',
                value: 'start',
              },
              {
                label: 'Fim',
                value: 'end',
              },
            ]}
          />
        </Flex>
      </Col>
    </Row>
  );
}
