import GenericFilterComponent from 'components/generic-filter';
import { t } from 'core/resources/strings';
import { FilterProps } from 'infra/helpers/types';
import moment from 'moment';

function ReportFilter({ filters, onChangeFilters }: FilterProps) {
  let uniqueYears: any = [];
  const currentYear = moment().year();
  for (let i = 0; i < 3; i++) {
    uniqueYears.push({
      label: String(currentYear - i),
      value: String(currentYear - i),
    });
  }

  return (
    <GenericFilterComponent
      onChangeFilters={onChangeFilters}
      filters={filters}
      filterGroups={[
        {
          title: t('app.ano'),
          options:
            uniqueYears.map((item: any) => {
              return { label: item.label, value: item.value };
            }) ?? [],
          filterName: 'years',
          type: 'radio',
        },
        {
          title: t('app.periodo'),
          options: [
            {
              label: '1° ' + t('app.bimestre'),
              value: 1,
            },
            {
              label: '2° ' + t('app.bimestre'),
              value: 2,
            },

            {
              label: '3° ' + t('app.bimestre'),
              value: 3,
            },
            {
              label: '4° ' + t('app.bimestre'),
              value: 4,
            },
            {
              label: '5° ' + t('app.bimestre'),
              value: 5,
            },
            {
              label: '6° ' + t('app.bimestre'),
              value: 6,
            },
            {
              label: '1° ' + t('app.semestre'),
              value: 7,
            },
            {
              label: '2° ' + t('app.semestre'),
              value: 8,
            },
          ],
          filterName: 'period',
          type: 'radio',
        },
      ]}
    />
  );
}

export default ReportFilter;
