import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Row, Tabs, Collapse, Switch } from 'antd';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import Title from 'components/typography/title';
import { useProfile } from 'core/features/profile/hooks/useProfile';
import { useSaveProfile } from 'core/features/profile/hooks/useSaveProfile';
import useResources from 'core/features/resources/hooks/useResources';
import useTenantResources from 'core/features/resources/hooks/useTenantResources';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { NumberParam, useQueryParam } from 'use-query-params';
import ResourcesTab from '../../components/ResourcesTab';
import { Permissions } from 'core/resources/enums/permissions';

interface Resource {
  id: string;
  name: {
    'pt-br': string;
    en: string;
    es: string;
  };
  description: {
    'pt-br': string;
    en: string;
    es: string;
  };
}
interface Group {
  name: string;
  children: Resource[];
}
interface Category {
  name: string;
  groups: Group[];
}

function CreateProfile() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [step, setStep] = useQueryParam('tab', NumberParam);
  const { id, tenantId } = useParams<{
    id: string;
    tenantId: string;
  }>();
  let location = useLocation();
  const { user } = useCurrentUser();
  const [check] = useCheckPermissions();
  const { data, isLoading } = useProfile(id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveProfile();
  const { data: tenantResources, isLoading: isLoadingResources } =
    useTenantResources({
      tenantId: tenantId ?? user?.tenantId,
      disabled: !tenantId && check([Permissions.U4hero.Profile.View]),
    });

  const { data: allResources } = useResources({
    page: 1,
    limit: 1000,
    disabled: !check([Permissions.U4hero.Profile.View]),
  });

  const resourcesData = tenantResources ?? allResources?.data;

  const [items, setItems] = useState<any>();

  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const [form] = Form.useForm();
  const [resources, setResources] = useState<string[]>([]);

  const onClose = () => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/profiles`);
    } else {
      if (location.pathname.includes('settings-u4hero')) {
        history.push(`/settings-u4hero?tab=1`);
      } else {
        history.push(`/my-school/profiles`);
      }
    }
  };

  const onSubmit = (values: any) => {
    handleSave(
      {
        ...values,
        id: id ?? undefined,
        resources,
        tenantId,
      },
      {
        onError: () => {
          notificateError();
        },
        onSuccess: () => {
          notificateSucess(
            `${
              id
                ? t('app.perfil') + ' ' + t('app.editadoComSucesso')
                : t('app.perfil') + ' ' + t('app.cadastradoComSucesso')
            }`
          );
          onClose();
        },
      }
    );
  };

  const categories: Category[] = [
    {
      name: 'U4Hero',
      groups: [],
    },
    {
      name: t('app.escola'),
      groups: [],
    },
    {
      name: t('app.organizacao'),
      groups: [],
    },
    {
      name: 'Inkluzen',
      groups: [],
    },
    {
      name: 'B2C',
      groups: [],
    },
  ];

  useEffect(() => {
    if (resourcesData) {
      for (const resource of resourcesData) {
        const names = resource.tag.split('.');
        if (names.length === 3) {
          names.shift();
        }

        const group = categories[resource.type].groups.find(
          (item) => item.name === names[0]
        );
        if (!group) {
          categories[resource.type].groups.push({
            name: names[0],
            children: [
              {
                name: resource.name,
                id: resource.id,
                description: resource.description,
              },
            ],
          });
        } else {
          group.children.push({
            name: resource.name,
            id: resource.id,
            description: resource.description,
          });
        }
      }
      setItems(
        categories
          .filter((item) => item.groups.length > 0)
          .map((category, index) => ({
            key: index + 1,
            label: (
              <p
                style={{
                  fontSize: isMobile ? '14px' : '24px',
                  fontWeight: 700,
                  color:
                    index + 1 !== step ? colorGreyBorderAndFont : colorPrimary,
                  textTransform: 'uppercase',
                }}
              >
                {category.name}
              </p>
            ),
            children: (
              <ResourcesTab
                setResources={setResources}
                resources={resources}
                groups={category.groups}
              />
            ),
          }))
      );
    }
  }, [resourcesData, resources, step]);

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  useEffect(() => {
    if (data) {
      setResources(data.resources.map((item) => item.id));
    }
  }, [data]);

  return (
    <Layout.Container>
      {' '}
      <Flex gap="23px" align="center" style={{ marginBottom: '16px' }}>
        <BackButton
          onClick={() => {
            if (form.isFieldsTouched() || resources.length !== 0) {
              openNotification({
                colorPrimary,
                isMobile,
                onClose: onClose,
              });
            } else {
              onClose();
            }
          }}
        />

        <Title primary>
          {id ? t('app.editar') : t('app.criar')} {t('app.perfil')}
        </Title>
      </Flex>
      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="submit"
        initialValues={
          data
            ? {
                name: data.name,
                resources: data.resources.map((item) => item.id),
              }
            : {
                name: undefined,
                resources: [],
              }
        }
      >
        <Flex
          vertical={isMobile}
          align="center"
          style={{ width: '100%' }}
          gap={isMobile ? '0px' : '24px'}
        >
          <Form.Item
            name="name"
            label={t('app.nomeDoPerfil')}
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: t('app.mensagemErroNomePerfil'),
              },
            ]}
          >
            <Input
              id="id-tour-my-school-profiles-create-profile-input-name"
              placeholder={t('app.atribuaUmNomeParaEssePerfil')}
              size={isMobile ? 'middle' : 'large'}
            />
          </Form.Item>

          <Button size="md" htmlType="submit" loading={isLoadingSave}>
            {t('app.salvar')}
          </Button>
        </Flex>
        <SubTitle primary>{t('app.gerenciamentoDePermissoes')}</SubTitle>
        <Tabs
          centered
          onTabClick={(event) => setStep(Number(event))}
          size="large"
          defaultActiveKey={step?.toString()}
          items={items}
        />
      </Form>
    </Layout.Container>
  );
}

export default CreateProfile;
