import { Flex, Form, Radio, Collapse } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import '../style.css';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import SelectTagType from 'components/select-tag-type';
import { TagType } from 'core/features/competence/typings';
import LoadingDots from 'components/V2/loading-dots';
import PickCompetence from '../PickCompetence';
import PickSkill from '../PickSkill';
import PickTag from '../PickTag';
import { t } from 'core/resources/strings';

function AnalysisArea({
  hasTwoCompetences,
  setHasTwoCompetences,
  isEditing,
}: {
  hasTwoCompetences: boolean;
  setHasTwoCompetences: (v: boolean) => void;
  isEditing: boolean;
}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const tagType = Form.useWatch('tagType', form);

  return (
    <GenericCard>
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              {t('app.areaDeAnalise')}
            </Text>
          }
        >
          <SelectTagType
            id="id-tour-create-question-input-tag-type"
            onChange={() => {
              form.setFieldValue('skill', undefined);
              form.setFieldValue('competence', undefined);
            }}
            removeLabel
          />
          {tagType === undefined ? (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ height: '100%' }}
              gap={'8px'}
            >
              <LoadingDots />
              <Text size="sm" color={colorPrimary}>
                {t('app.definaAreaDeAnalise')}
              </Text>
            </Flex>
          ) : (
            <Flex gap={8} vertical style={{ marginTop: '8px' }}>
              {!isMobile ? (
                <>
                  <Form.Item
                    name="competence"
                    label={
                      tagType === TagType.Competências
                        ? t('app.competencia')
                        : tagType === TagType['Clima']
                        ? t('app.focoDeAnaliseDoClimaEscolar')
                        : tagType === TagType.Treinamento
                        ? t('app.escolhaDoTipoDeTreinamento')
                        : t('app.areaDeFoco')
                    }
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroCompetencia'),
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <PickCompetence
                      tagType={tagType}
                      setHasTwoCompetences={setHasTwoCompetences}
                    />
                  </Form.Item>

                  <Form.Item
                    name="skill"
                    label={t('app.habilidade')}
                    rules={[
                      {
                        required: !hasTwoCompetences,
                        message: t('app.mensagemErroHabilidade'),
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <PickSkill hasTwoCompetences={hasTwoCompetences} />
                  </Form.Item>
                </>
              ) : (
                <Flex>
                  <Form.Item hidden name="competence" />
                  <Form.Item
                    name="skill"
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroHabilidade'),
                      },
                    ]}
                    style={{ minWidth: '100%' }}
                  >
                    {/* @ts-ignore */}
                    <PickTag
                      setHasTwoCompetences={setHasTwoCompetences}
                      isEditing={isEditing}
                    />
                  </Form.Item>
                </Flex>
              )}
            </Flex>
          )}
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
}

export default AnalysisArea;
