import { useMediaQuery } from '@chakra-ui/media-query';
import {
  Button,
  Divider,
  Flex,
  DatePicker as DatePickerAntd,
  Spin,
} from 'antd';
import SubTitle from 'components/typography/subTitle';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import background from 'assets/images/background-safe-space.png';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useHistory, useParams } from 'react-router-dom';
import Text from 'components/typography/text';
import { PiEquals } from 'react-icons/pi';
import React, { useEffect, useState } from 'react';
import { MdExpandMore } from 'react-icons/md';
import dayjs from 'dayjs';
import { GoUnverified } from 'react-icons/go';
import CustomCarousel from 'components/custom-carousel';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { AiOutlineCalendar } from 'react-icons/ai';
import lumi from 'assets/images/lumi-with-heart.png';
import hugging from 'assets/images/white-label/people-hugging.png';
import { safeSpaceFlags } from 'infra/helpers/types';
import useSafeSpaceStats from 'core/features/safeSpace/hooks/useSafeSpaceStats';
import useInfiniteSafespaces from 'core/features/safeSpace/hooks/useInfiniteSafeSpaces';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';
const { RangePicker } = DatePickerAntd;

function SafeSpaceRecords() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { id, tenantId, classId } = useParams<{
    id: string;
    tenantId: string;
    classId: string;
  }>();
  const { user } = useCurrentUser();

  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const [date, setDate] = useState<any>([
    dayjs().startOf('year'),
    dayjs().endOf('year'),
  ]);
  const [flags, setFlags] = useState<number[]>([]);

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteSafespaces(
    {
      limit: 5,
      userId: id,
      flags,
      classId,
      initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
      endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
      tenantId,
    }
  );

  const redirectToSafeSpace = () => {
    if (classId) {
      history.push(`/safe-space/?classId=${classId}`);
    } else {
      history.push(
        `/safe-space/?search=${messages.length ? messages[0].name : ''}`
      );
    }
  };
  const { data: stats } = useSafeSpaceStats({
    userId: id,
    classId,
    initialDate: date ? dayjs(date[0]).format('YYYY-MM-DD') : undefined,
    endDate: date ? dayjs(date[1]).format('YYYY-MM-DD') : undefined,
    tenantId,
  });
  const items = [
    {
      label: t('app.totalDeRegistros'),
      icon: PiEquals,
      value: stats?.total,
    },
    {
      ...safeSpaceFlags.find((item) => item.value === 1),
      value: stats?.flag1,
    },
    {
      ...safeSpaceFlags.find((item) => item.value === 2),
      value: stats?.flag2,
    },
    {
      ...safeSpaceFlags.find((item) => item.value === 3),
      value: stats?.flag3,
    },
    {
      ...safeSpaceFlags.find((item) => item.value === 4),
      value: stats?.flag4,
    },
    {
      ...safeSpaceFlags.find((item) => item.value === 5),
      value: stats?.flag5,
    },
  ];

  const messages = data?.pages.flatMap((page) => page.data) || [];

  return (
    <Flex vertical>
      <Flex
        align="center"
        justify="end"
        style={{ marginBottom: isMobile ? '8px' : '18px' }}
      >
        <RangePicker
          style={{ height: '34px' }}
          format="DD/MM/YYYY"
          separator={<HiOutlineArrowNarrowRight />}
          suffixIcon={<AiOutlineCalendar color="black" />}
          value={date}
          onChange={(v) => setDate(v)}
        />
      </Flex>
      {!isLoading ? (
        <GenericCard>
          <Flex vertical gap="16px" style={{ width: '100%' }}>
            <Flex justify="space-between" align="center">
              <SubTitle primary>{t('app.registrosEmMeuCantinho')}</SubTitle>
              {!isMobile && (
                <Button
                  type="default"
                  size="large"
                  style={{
                    color: colorPrimary,
                    fontWeight: 600,
                    border: `3px solid ${colorPrimary}`,
                    fontSize: isMobile ? '12px' : '16px',
                  }}
                  onClick={redirectToSafeSpace}
                >
                  {t('app.meuCantinho')}
                </Button>
              )}
            </Flex>
            {isMobile ? (
              <CustomCarousel slidesToScroll={1} slidesToShow={1}>
                <>
                  <Flex
                    style={{
                      background: 'rgba(244, 244, 244, 0.80)',
                      borderRadius: '20px',
                    }}
                    justify="space-evenly"
                  >
                    <img
                      alt={user?.whiteLabel ? 'Pessoas abraçando' : 'lumi'}
                      src={user?.whiteLabel ? hugging : lumi}
                      width="97px"
                    />
                    <Flex vertical>
                      <Flex align="center" gap="8px">
                        <p
                          style={{
                            fontSize: isMobile ? '24px' : '30px',
                            color: colorPrimary,
                            fontWeight: 700,
                          }}
                        >
                          {items[0].value}
                        </p>
                        <Text align="center">{items[0].label}</Text>
                      </Flex>
                      <Button
                        type="default"
                        size="large"
                        style={{
                          color: colorPrimary,
                          fontWeight: 600,
                          border: `3px solid ${colorPrimary}`,
                          fontSize: isMobile ? '12px' : '16px',
                        }}
                        onClick={redirectToSafeSpace}
                      >
                        {t('app.meuCantinho')}
                      </Button>
                    </Flex>
                  </Flex>
                </>
                <>
                  <Flex
                    style={{
                      background: 'rgba(244, 244, 244, 0.80)',
                      borderRadius: '20px',
                      height: '100%',
                    }}
                    justify="space-evenly"
                    align="center"
                  >
                    {items.slice(1, 3).map((item, index) => (
                      <React.Fragment key={item.label}>
                        <Flex
                          align="center"
                          gap="4px"
                          onClick={() => setFlags([index + 1])}
                        >
                          <p
                            style={{
                              fontSize: isMobile ? '24px' : '30px',
                              color: colorPrimary,
                              fontWeight: 700,
                            }}
                          >
                            {item.value}
                          </p>
                          <Flex
                            vertical
                            align="center"
                            style={{ maxWidth: '81px' }}
                          >
                            {React.createElement(item?.icon || GoUnverified, {
                              size: isMobile ? '20px' : '24px',
                              color: 'black',
                            })}
                            <Text align="center">{item.label}</Text>
                          </Flex>
                        </Flex>
                        {index !== 1 && (
                          <Divider
                            type="vertical"
                            style={{ color: 'black', height: '60px' }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                </>
                <>
                  <Flex
                    style={{
                      background: 'rgba(244, 244, 244, 0.80)',
                      borderRadius: '20px',
                      height: '100%',
                    }}
                    justify="space-evenly"
                    align="center"
                  >
                    {items.slice(3, 5).map((item, index) => (
                      <React.Fragment key={item.label}>
                        <Flex
                          align="center"
                          gap="4px"
                          onClick={() => setFlags([index + 3])}
                        >
                          <p
                            style={{
                              fontSize: isMobile ? '24px' : '30px',
                              color: colorPrimary,
                              fontWeight: 700,
                            }}
                          >
                            {item.value}
                          </p>
                          <Flex
                            vertical
                            align="center"
                            style={{ maxWidth: '81px' }}
                          >
                            {React.createElement(item.icon || GoUnverified, {
                              size: isMobile ? '20px' : '24px',
                              color: 'black',
                            })}
                            <Text align="center">{item.label}</Text>
                          </Flex>
                        </Flex>
                        {index !== 1 && (
                          <Divider
                            type="vertical"
                            style={{ color: 'black', height: '60px' }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                </>
                <>
                  <Flex
                    style={{
                      background: 'rgba(244, 244, 244, 0.80)',
                      borderRadius: '20px',
                      height: '100%',
                    }}
                    justify="space-evenly"
                    align="center"
                  >
                    {items.slice(5).map((item, index) => (
                      <React.Fragment key={item.label}>
                        <Flex
                          align="center"
                          gap="4px"
                          onClick={() => setFlags([index + 3])}
                        >
                          <p
                            style={{
                              fontSize: isMobile ? '24px' : '30px',
                              color: colorPrimary,
                              fontWeight: 700,
                            }}
                          >
                            {item.value}
                          </p>
                          <Flex
                            vertical
                            align="center"
                            style={{ maxWidth: '81px' }}
                          >
                            {React.createElement(item.icon || GoUnverified, {
                              size: isMobile ? '20px' : '24px',
                              color: 'black',
                            })}
                            <Text align="center">{item.label}</Text>
                          </Flex>
                        </Flex>
                        {index !== 1 && (
                          <Divider
                            type="vertical"
                            style={{ color: 'black', height: '60px' }}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Flex>
                </>
              </CustomCarousel>
            ) : (
              <Flex
                gap="16px"
                align="center"
                style={{
                  width: '100%',
                  background: 'rgba(244, 244, 244, 0.80)',
                  backgroundImage: `url(${background})`,
                  backgroundSize: 'contain',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  padding: '8px 16px',
                  borderRadius: isMobile ? '20px' : '10px',
                }}
              >
                <Flex
                  justify="space-around"
                  align="center"
                  style={{ width: '100%' }}
                >
                  {items.map((item, index) => (
                    <React.Fragment key={item.label}>
                      <Flex
                        align="center"
                        gap="4px"
                        style={{ cursor: index !== 0 ? 'pointer' : undefined }}
                        onClick={
                          index !== 0 ? () => setFlags([index]) : undefined
                        }
                      >
                        <p
                          style={{
                            fontSize: isMobile ? '24px' : '30px',
                            color: colorPrimary,
                            fontWeight: 700,
                          }}
                        >
                          {item.value}
                        </p>
                        <Flex
                          vertical
                          align="center"
                          style={{ maxWidth: '111px' }}
                        >
                          {React.createElement(item?.icon || GoUnverified, {
                            size: isMobile ? '20px' : '24px',
                            color: 'black',
                          })}
                          <Text align="center">{item.label}</Text>
                        </Flex>
                      </Flex>
                      {index !== items.length - 1 && (
                        <Divider
                          type="vertical"
                          style={{ color: 'black', height: '60px' }}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </Flex>
              </Flex>
            )}

            <Flex vertical gap="24px">
              {messages?.map((item) => (
                <Flex
                  key={item.id}
                  vertical
                  style={{
                    borderRadius: '10px',
                    boxShadow:
                      '0px 2px 4px 0px rgba(0, 0, 0, 0.10), 0px -0.5px 4px 0px rgba(0, 0, 0, 0.10)',
                    background: '#F5F5F5',
                    padding: '16px',
                  }}
                  gap={isMobile ? '4px' : '16px'}
                >
                  <Text>{item.message}</Text>
                  <Flex justify="flex-end" gap="8px" align="center">
                    {React.createElement(
                      safeSpaceFlags[item.systemClassification].icon,
                      {
                        size: isMobile ? '20px' : '24px',
                        color: colorGreyBorderAndFont,
                      }
                    )}
                    <Text
                      style={{
                        color: colorGreyBorderAndFont,
                        fontSize: isMobile ? '12px' : '14px',
                      }}
                    >
                      {safeSpaceFlags[item.systemClassification].label}
                    </Text>
                    -
                    <Text
                      style={{
                        color: colorGreyBorderAndFont,
                        fontSize: isMobile ? '12px' : '14px',
                      }}
                    >
                      {dayjs(item.date).format('DD/MM/YYYY')}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
            {hasNextPage && (
              <Flex
                justify="center"
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={() => fetchNextPage()}
              >
                <Text style={{ color: colorGreyBorderAndFont }}>
                  {t('app.mostrarMais')}
                </Text>
                <MdExpandMore color={colorGreyBorderAndFont} size={22} />
              </Flex>
            )}
          </Flex>
        </GenericCard>
      ) : (
        <GenericCard width="80vw" height="30vh">
          <Flex justify="center" align="center" style={{ width: '100%' }}>
            <Spin size={'large'} />
          </Flex>
        </GenericCard>
      )}
    </Flex>
  );
}

export default SafeSpaceRecords;
