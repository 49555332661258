import { useQuery } from 'react-query';
import axios from 'infra/http';
import { TagDetails } from '../types';

interface TagsParams {
  tagId?: string;
  type?: number;
  disabled?: boolean;
  active?: boolean;
}

function useTagsWithoutParent(params?: TagsParams) {
  const fetchTags = async (params?: TagsParams): Promise<TagDetails[]> => {
    const { disabled, ...rest } = params || {};
    const { data } = await axios.get<TagDetails[]>(`/v2/tags/without-parent`, {
      params: rest,
    });

    return data;
  };

  return useQuery(
    [`/v2/tags/without-parent`,params],
    ()=> fetchTags(params),
    {
      enabled: !params?.disabled,
    }
  );
}

export default useTagsWithoutParent;
