import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Input, Select, Form, Radio, Popover } from 'antd';
import useSeries from 'core/features/series/hooks/useSeries';
import { TfiInfo } from 'react-icons/tfi';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { white } from 'core/resources/theme/colors';
import { useParams } from 'react-router-dom';
import { Pluralizar } from 'infra/helpers/plural';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import useQuestions from 'core/features/questions/hooks/useQuestions';
import useSerie from 'core/features/series/hooks/useSerie';
import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';

function GeneralDataTab() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data: allSeries } = useSeries();
  const form = useFormInstance();
  const { id } = useParams<{ id: string }>();
  const { data: serie } = useSerie(Form.useWatch('serieId', form));
  const { colorPrimary } = useU4heroColors();
  const { data } = useQuestions({
    page: 1,
    types: ['1'],
    active: true,
    tenantId: id,
    minAge: serie ? serie.minAge : undefined,
    maxAge: serie ? serie.maxAge : undefined,
    locale: currentLocale,
  });
  const groupByCompetence = (arr: any[] | undefined) => {
    if (!arr) {
      return;
    }
    const result: any = [];
    for (const item of arr) {
      const index = result.findIndex(
        (i: any) => i.competence === item.competence.name['pt-br']
      );
      if (index !== -1) {
        result[index].quantity += 1;
      } else {
        result.push({
          competence: item?.competence?.name['pt-br'],
          quantity: 1,
        });
      }
    }
    return result;
  };

  const content = (
    <div style={{ color: 'white', marginTop: '8px' }}>
      {t('app.aAvaliacaoInicialÉCompostaPor')} {data?.data?.length}{' '}
      {Pluralizar(
        t('app.pergunta').toLowerCase(),
        (data?.data?.length || 0) > 1
      )}{' '}
      {t('app.sendoElas')}:
      <div style={{ marginTop: '8px' }}>
        {groupByCompetence(data?.data)?.map((item: any) => (
          <p>
            {`${item.quantity} ${Pluralizar(
              t('app.pergunta').toLowerCase(),
              item.quantity > 1
            )} ${t('app.daCompetencia')} ${item?.competence} `}
          </p>
        ))}
      </div>
    </div>
  );

  return (
    <Flex style={{ width: '100%', height: '100%' }} vertical={isMobile && true}>
      <Flex
        style={{ width: '100%', height: '100%' }}
        vertical
        justify="space-between"
        align="space-between"
      >
        <Flex
          style={{ height: '100%' }}
          vertical={isMobile}
          gap={isMobile ? '2px' : '90px'}
        >
          <Form.Item
            label={t('app.anoLetivo')}
            name="year"
            rules={[
              {
                required: true,
                message: t('app.mensagemDeErroAnoLetivo'),
              },
            ]}
          >
            <Select
              id="id-tour-reenroll-class-input-year"
              style={
                isMobile
                  ? { width: '130px', height: '30px' }
                  : { width: '160px', height: '40px' }
              }
              options={[
                { value: '2024', label: '2024' },
                { value: '2023', label: '2023' },
                { value: '2022', label: '2022' },
                { value: '2021', label: '2021' },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="serieId"
            label={t('app.serie')}
            rules={[
              {
                required: true,
                message: t('app.mensagemDeErroSerie'),
              },
            ]}
          >
            <Select
              id="id-tour-reenroll-class-input-serie"
              style={
                isMobile
                  ? { width: '130px', height: '30px' }
                  : { width: '160px', height: '40px' }
              }
              options={allSeries?.map((serie) => ({
                value: serie.id,
                label: serie.name,
              }))}
            />
          </Form.Item>
          <Form.Item
            name="period"
            label={t('app.turno')}
            rules={[
              {
                required: true,
                message: t('app.mensagemDeErroTurno'),
              },
            ]}
          >
            <Select
              id="id-tour-reenroll-class-input-period"
              style={
                isMobile
                  ? { width: '130px', height: '30px' }
                  : { width: '160px', height: '40px' }
              }
              options={[
                { value: 'morning', label: t('app.matutino') },
                { value: 'afternoon', label: t('app.vespertino') },
                { value: 'fulltime', label: t('app.integral') },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="name"
            label={t('app.nome')}
            rules={[
              {
                required: true,
                message: t('app.mensagemDeErroNomeDaTurma'),
              },
            ]}
          >
            <Input
              id="id-tour-reenroll-class-input-name"
              style={
                isMobile
                  ? { width: '284px', height: '30px' }
                  : { width: '390px', height: '40px' }
              }
            />
          </Form.Item>
        </Flex>
        <Form.Item
          name="initialAvaliation"
          label={
            <Flex gap={17} align="center">
              <p
                style={{
                  fontSize: isMobile ? '14px' : '18px',
                  color: 'black',
                }}
              >
                {t('app.desejaCriarUmaAvaliacaoInicial')}
              </p>
              <Popover content={content}>
                <Flex
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: colorPrimary,
                    borderRadius: '50px',
                    width: '18px',
                    height: '18px',
                    padding: '2px',
                  }}
                >
                  <TfiInfo style={{ color: white, fontWeight: 900 }} />
                </Flex>
              </Popover>
            </Flex>
          }
          rules={[
            {
              required: true,
              message: t('app.mensagemDeErroAvaliacaoInicial'),
            },
          ]}
        >
          <Flex align="center">
            <Radio.Group id="id-tour-reenroll-class-input-initialAvaliation">
              <Radio value={true}>{t('app.sim')}</Radio>
              <Radio value={false}>{t('app.nao')}</Radio>
            </Radio.Group>
          </Flex>
        </Form.Item>
      </Flex>
    </Flex>
  );
}

export default GeneralDataTab;
