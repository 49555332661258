import { FilterProps } from 'infra/helpers/types';
import GenericFilterComponent from 'components/generic-filter';
import useStoriesAuthors from 'core/features/stories/hooks/useStoriesAuthors';

function StoriesFilter({ filters, onChangeFilters }: FilterProps) {
  const { data: authors } = useStoriesAuthors();
  return (
    <GenericFilterComponent
      filters={filters}
      onChangeFilters={onChangeFilters}
      filterGroups={[
        {
          title: 'Autor',
          filterName: 'authors',
          options:
            authors?.map((item) => {
              return { label: item.name, value: item.id };
            }) ?? [],
        },
        {
          title: 'Tipo',
          filterName: 'published',
          options: [
            {
              label: 'Publicado',
              value: 1,
            },
            {
              label: 'Rascunho',
              value: 2,
            },
          ],
          type: 'radio',
        },
      ]}
    />
  );
}

export default StoriesFilter;
