import { Flex } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import ReportPage from '../page';
import folder from 'assets/images/report/folder.png';

import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';

export default function ReportReferences({ startPage=9 }: { startPage?: number }) {
  const items = [
    {
      author: 'De Fruyt, F., Wille, B., & John, O. P. (2015)',
      article: {
        'pt-br':
          'Empregabilidade no Século 21: Resolução de Problemas Complexos (Interativos) e Outras Habilidades Essenciais. Psicologia Industrial e Organizacional, 8(2), 276-281.',
        en: 'Employability in the 21st Century: Complex (Interactive) Problem Solving and Other Essential Skills. Industrial and Organizational Psychology, 8(2), 276-281.',
        es: 'Empleabilidad en el Siglo XXI: Resolución de Problemas Complejos (Interactivos) y Otras Habilidades Esenciales. Psicología Industrial y Organizacional, 8(2), 276-281.',
      },
    },
    {
      author: 'Primi, R., Santos, D., John, O. P., & Fruyt, F. D. (2016)',
      article: {
        'pt-br':
          'Desenvolvimento de um Inventário para Avaliação de Habilidades Sociais e Emocionais na Juventude Brasileira. European Journal of Psychological Assessment, 32(1), 5–16.',
        en: 'Development of an Inventory Assessing Social and Emotional Skills in Brazilian Youth. European Journal of Psychological Assessment, 32(1), 5–16.',
        es: 'Desarrollo de un Inventario para Evaluar Habilidades Sociales y Emocionales en Jóvenes Brasileños. European Journal of Psychological Assessment, 32(1), 5–16.',
      },
    },
    {
      author: 'Primi, R., Santos, D., John, O. P., & Fruyt, F. D.',
      article: {
        'pt-br':
          'Manual técnico do instrumento Senna de avaliação socioemocional. Manual não publicado. São Paulo: Instituto Ayrton Senna.',
        en: 'Technical manual of the Senna socioemotional assessment instrument. Unpublished manual. São Paulo: Instituto Ayrton Senna.',
        es: 'Manual técnico del instrumento Senna de evaluación socioemocional. Manual no publicado. São Paulo: Instituto Ayrton Senna.',
      },
    },
    {
      author: 'ABED, Anita. (2014)',
      article: {
        'pt-br':
          'O desenvolvimento das habilidades socioemocionais como caminho para a aprendizagem e o sucesso escolar de alunos da educação básica. São Paulo: UNESCO/MEC.',
        en: 'The development of socioemotional skills as a path to learning and school success for basic education students. São Paulo: UNESCO/MEC.',
        es: 'El desarrollo de habilidades socioemocionales como camino para el aprendizaje y el éxito escolar de alumnos de educación básica. São Paulo: UNESCO/MEC.',
      },
    },
    {
      author: 'Barros, P. B., Coutinho, D., Garcia, B., & Muller, L. (2016)',
      article: {
        'pt-br':
          'O desenvolvimento socioemocional como antídoto para a desigualdade de oportunidades. Relatório técnico INAF 2016. São Paulo: Instituto Ayrton Senna e Instituto Paulo Montenegro.',
        en: 'Socioemotional development as an antidote to inequality of opportunities. INAF 2016 Technical Report. São Paulo: Instituto Ayrton Senna and Instituto Paulo Montenegro.',
        es: 'El desarrollo socioemocional como antídoto para la desigualdad de oportunidades. Informe técnico INAF 2016. São Paulo: Instituto Ayrton Senna e Instituto Paulo Montenegro.',
      },
    },
    {
      author:
        'Sklad, M., Diekstra, R., DeRitter, M., Ben, J., & Gravesteijn, C. (2012)',
      article: {
        'pt-br':
          'Eficácia de programas universais escolares sociais, emocionais e comportamentais: Eles melhoram o desenvolvimento dos alunos nas áreas de habilidade, comportamento e ajuste? Psychology in the Schools, 49, 892-909.',
        en: "Effectiveness of school-based universal social, emotional, and behavioral programs: Do they enhance students' development in the area of skill, behavior and adjustment? Psychology in the Schools, 49, 892-909.",
        es: '¿Eficacia de programas universales escolares sociales, emocionales y conductuales: Mejoran el desarrollo de los estudiantes en el área de habilidades, comportamiento y ajuste? Psychology in the Schools, 49, 892-909.',
      },
    },
    {
      author:
        'Taylor, R. D., Oberle, E., Durlak, J. A., & Weissberg, R. P. (2017)',
      article: {
        'pt-br':
          'Promovendo o Desenvolvimento Positivo da Juventude através de intervenções escolares de aprendizagem social e emocional: uma meta-análise dos efeitos de acompanhamento.',
        en: 'Promoting Positive Youth Development through school-based social and emotional learning interventions: a meta-analysis of follow up effects.',
        es: 'Promoviendo el Desarrollo Positivo de la Juventud a través de intervenciones escolares de aprendizaje social y emocional: un meta-análisis de los efectos de seguimiento.',
      },
    },
  ];

  return (
    <>
      <ReportPage pageNumber={startPage}>
        <Flex vertical align="center" justify="center">
          <Title primary style={{ textTransform: 'uppercase' }}>
            {t('app.referenciasBibliograficas')}
          </Title>
          <img src={folder} alt="Pasta" width={68} />
        </Flex>

        {items.slice(0, 6).map((item, index) => (
          <Text
            key={index}
            size="md"
            style={{
              lineHeight: '40px',
            }}
          >
            <strong>{item.author}</strong>. {item.article[currentLocale]}
          </Text>
        ))}
      </ReportPage>
      <ReportPage
        pageNumber={startPage + 1}
        style={{ alignItems: 'start', paddingTop: '90px' }}
      >
        {items.slice(6).map((item, index) => (
          <Text
            key={index}
            size="md"
            style={{
              lineHeight: '40px',
            }}
          >
            <strong>{item.author}</strong>. {item.article[currentLocale]}
          </Text>
        ))}
      </ReportPage>
    </>
  );
}
