import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Radio, Row } from 'antd';
import Layout from 'components/layout';
import CustomizedRequiredMark from 'components/required-mark';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  openNotification,
  showConfirm,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { IoSaveOutline } from 'react-icons/io5';
import { useHistory, useParams } from 'react-router';
import Button from 'components/buttons/button';
import BackButton from 'components/buttons/back-button';
import Title from 'components/typography/title';
import ModalWelcomeCreateStory from 'components/modals/modal-welcome-create-story';
import Text from 'components/typography/text';
import { PiImage } from 'react-icons/pi';
import ModalScenario from 'components/modals/modal-scenario';
import ModalStoryAi from 'components/modals/modal-story-ai';
import ModalRoute from 'components/modals/modal-route';
import RouteBox from 'ui/pages/app/posts-hub/stories/pages/CreateStory/components/routeBox';
import ModalInteraction from 'components/modals/modal-interaction';
import InteractionBox from 'ui/pages/app/posts-hub/stories/pages/CreateStory/components/interactionBox';
import {
  Interaction,
  StoryForm,
  StoryRoute,
} from 'core/features/stories/typings';
import useStory from 'core/features/stories/hooks/useStory';
import { useSaveStory } from 'core/features/stories/hooks/useSaveStory';
import { t } from 'core/resources/strings';
import { ContentType, languages } from 'infra/helpers/types';
import { currentLocale } from 'core/resources/strings/polyglot';
import SelectCompetence from 'components/modals/modal-tag/select-competence';
import { TagType } from 'core/features/competence/typings';

function CreateStory() {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { user } = useCurrentUser();
  const params = useParams<{
    id: string;
  }>();
  const { data } = useStory(params.id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveStory();
  const [form] = Form.useForm();
  const routes = Form.useWatch<StoryRoute[] | undefined>('routes', form);
  const scenario = Form.useWatch<string | undefined>('scenario', form);
  const locale = Form.useWatch<string>('locale', form);

  const [published, setPublished] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalScenarioOpen, setIsModalScenarioOpen] = useState(false);
  const [isModalStoryAiOpen, setIsModalStoryAiOpen] = useState(false);
  const [isModalRouteOpen, setIsModalRouteOpen] = useState(false);
  const [isModalInteractionOpen, setIsModalInteractionOpen] = useState(false);
  const [selectedRouteIndex, setSelectedRouteIndex] = useState<number>(0);

  const [routeToEdit, setRouteToEdit] = useState<StoryRoute | undefined>(
    undefined
  );
  const [interactionToEdit, setInteractionToEdit] = useState<
    Interaction | undefined
  >(undefined);

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const onSubmit = (values: StoryForm) => {
    handleSave(
      {
        ...values,
        published: published,
        id: params.id,
      },
      {
        onError: () => {
          notificateError(
            `Houve um problema ao ${
              params.id ? 'editar' : 'publicar'
            } a história. Verifique sua conexão e tente novamente.`
          );
        },
        onSuccess: () => {
          notificateSucess(
            `${
              !published
                ? 'História salva como rascunho!'
                : `História ${params.id ? 'editada' : 'publicada'} com sucesso!`
            }`
          );
          setTimeout(() => {
            history.push('/stories/manager');
          }, 200);
        },
      }
    );
  };

  useEffect(() => {
    if (!user?.hideWelcomeCreateStory) {
      setIsModalOpen(true);
    }
  }, []);

  const onAddInteraction = (routeIndex: number, interaction: Interaction) => {
    if (routes?.length) {
      const newRoute = routes[routeIndex];
      newRoute.interactions = [...newRoute.interactions, interaction];
      const updatedRoutes = routes.map((r) =>
        r.index === routeIndex ? newRoute : r
      );
      form.setFieldValue('routes', updatedRoutes);
      notificateSucess('Interação adicionada com sucesso!');
    }
  };

  const onEditInteraction = (routeIndex: number, interaction: Interaction) => {
    if (routes?.length) {
      const newRoute = routes[routeIndex];
      newRoute.interactions[interaction.index] = interaction;
      const updatedRoutes = routes.map((r) =>
        r.index === routeIndex ? newRoute : r
      );
      form.setFieldValue('routes', updatedRoutes);
      notificateSucess('Interação editada com sucesso!');
      setInteractionToEdit(undefined);
    }
  };
  const onRemoveInteraction = (
    routeIndex: number,
    interactionIndex: number
  ) => {
    if (routes?.length) {
      const newRoute = routes[routeIndex];
      newRoute.interactions = newRoute.interactions.filter(
        (i) => i.index !== interactionIndex
      );
      const updatedRoutes = routes.map((r) =>
        r.index === routeIndex ? newRoute : r
      );
      form.setFieldValue('routes', updatedRoutes);
      notificateSucess('Interação removida com sucesso!');
    }
  };

  const addRoute = (route: StoryRoute) => {
    if (routes) {
      form.setFieldValue('routes', [...routes, route]);
      notificateSucess('Rota criada com sucesso!');
      return;
    }
    form.setFieldValue('routes', [route]);
    notificateSucess('Rota criada com sucesso!');
  };

  const editRoute = (route: StoryRoute) => {
    const newRoutes = routes?.map((r) => (r.index === route.index ? route : r));
    form.setFieldValue('routes', newRoutes);
    notificateSucess('Rota editada com sucesso!');
    setRouteToEdit(undefined);
  };

  const onRemoveRoute = (routeIndex: number) => {
    if (routes?.length) {
      const newRoutes = routes.filter((r) => r.index !== routeIndex);
      form.setFieldValue('routes', newRoutes);
      notificateSucess('Rota removida com sucesso!');
    }
  };

  const latestInteraction =
    routes?.[selectedRouteIndex]?.interactions?.[
      routes?.[selectedRouteIndex]?.interactions?.length - 1
    ];

  return (
    <Layout.Container>
      {isModalOpen && (
        <ModalWelcomeCreateStory onClose={() => setIsModalOpen(false)} />
      )}
      {isModalScenarioOpen && (
        <ModalScenario
          onClose={() => setIsModalScenarioOpen(false)}
          onSave={(scenario) => {
            form.setFieldValue('scenario', scenario);
          }}
          value={scenario}
        />
      )}
      {isModalStoryAiOpen && (
        <ModalStoryAi
          onClose={() => setIsModalStoryAiOpen(false)}
          onSave={() => console.log('salvei')}
        />
      )}
      {isModalRouteOpen && (
        <ModalRoute
          onClose={() => {
            setIsModalRouteOpen(false);
            setRouteToEdit(undefined);
          }}
          index={routeToEdit?.index ?? routes?.length ?? 0}
          route={routeToEdit}
          onSave={(route) => {
            if (routeToEdit) {
              editRoute(route);
            } else {
              addRoute(route);
            }
            setRouteToEdit(undefined);
          }}
        />
      )}

      {isModalInteractionOpen && (
        <ModalInteraction
          locale={locale}
          onClose={() => {
            setInteractionToEdit(undefined);
            setIsModalInteractionOpen(false);
          }}
          onSave={(interaction) => {
            if (interactionToEdit) {
              onEditInteraction(selectedRouteIndex, interaction);
            } else {
              onAddInteraction(selectedRouteIndex, interaction);
            }
          }}
          interaction={interactionToEdit}
          characters={routes?.[selectedRouteIndex].characters}
          routes={routes ?? []}
          index={
            interactionToEdit?.index ??
            routes?.[selectedRouteIndex].interactions.length ??
            0
          }
        />
      )}

      <Form
        layout="vertical"
        form={form}
        onFinish={onSubmit}
        requiredMark={CustomizedRequiredMark}
        validateTrigger="onSubmit"
        initialValues={{
          id: data?.id ?? undefined,
          routes: data?.routes ?? [],
          scenario: data?.scenario ?? undefined,
          name: data?.name ?? undefined,
          description: data?.description ?? undefined,
          locale: data?.locale ?? currentLocale,
          tagId: data?.tagId ?? undefined,
        }}
      >
        <Flex vertical gap="16px">
          <Flex gap="23px" align="center">
            <BackButton
              onClick={() => {
                if (form.isFieldsTouched()) {
                  openNotification({
                    colorPrimary,
                    isMobile,
                    onClose: () => history.push('/stories/manager'),
                  });
                } else {
                  history.push('/stories/manager');
                }
              }}
            />

            <Title primary>{params.id ? 'Editar' : 'Criar'} História</Title>
          </Flex>

          <Flex gap="16px" justify="end" style={{ marginBottom: '16px' }}>
            <Button
              size="md"
              id="id-tour-stories-create-button-generate-story-with-ai"
              htmlType="button"
              loading={isLoadingSave}
              variant="outline"
              onClick={() => setIsModalStoryAiOpen(true)}
              disabled
            >
              GERAR HISTÓRIA COM IA
            </Button>
            <Button
              variant="outline"
              size="md"
              id="id-tour-stories-create-button-save"
              htmlType="submit"
              onClick={() => setPublished(false)}
              loading={isLoadingSave}
            >
              <IoSaveOutline color={colorPrimary} size={24} />
            </Button>

            <Button
              size="md"
              id="id-tour-stories-create-button-publish"
              htmlType="submit"
              loading={isLoadingSave}
            >
              {params.id ? 'SALVAR' : 'PUBLICAR'}
            </Button>
          </Flex>
        </Flex>

        <Row
          gutter={[
            { xs: 0, md: 24 },
            { xs: 24, md: 24 },
          ]}
          align="stretch"
        >
          <Col xs={24}>
            <Flex
              vertical
              style={{
                padding: '16px',
                backgroundColor: '#F1F1F1CC',
                borderRadius: '20px',
                height: '100%',
              }}
            >
              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Informações Gerais
              </Text>
              <Row
                gutter={[
                  { xs: 0, md: 32 },
                  { xs: 0, md: 0 },
                ]}
              >
                <Col xs={24} md={18}>
                  <Form.Item
                    label="Nome da História:"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, de um nome para a história!',
                      },
                    ]}
                    name="name"
                    trigger="onBlur"
                    valuePropName="defaultValue"
                  >
                    <Input
                      id="id-tour-create-story-input-name"
                      placeholder="Insira o nome da história"
                      size={isMobile ? 'middle' : 'large'}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item
                    name="tagId"
                    label="Competência"
                    rules={[
                      {
                        required: true,
                        message:
                          'Por favor, selecione um campo de conhecimento!',
                      },
                    ]}
                  >
                    {/* @ts-ignore */}
                    <SelectCompetence
                      type={TagType.Competências}
                      id="id-tour-competences-create-story-input-tag-id"
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={18}>
                  <Form.Item
                    label="Descrição da História:"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, descreva a história!',
                      },
                    ]}
                    name="description"
                    trigger="onBlur"
                    valuePropName="defaultValue"
                  >
                    <Input.TextArea
                      id="id-tour-create-story-input-description"
                      placeholder="Descreva brevemente a história"
                      size={isMobile ? 'middle' : 'large'}
                      rows={4}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: t('app.mensagemErroIdiomaHistoria'),
                      },
                    ]}
                    label={t('app.idiomaDaHistoria')}
                    name="locale"
                  >
                    <Radio.Group>
                      <Flex
                        wrap={!isMobile ? 'wrap' : 'nowrap'}
                        vertical={isMobile}
                      >
                        {languages.map((language) => (
                          <Radio value={language.value} key={language.value}>
                            {language.name}
                          </Radio>
                        ))}
                      </Flex>
                    </Radio.Group>
                  </Form.Item>
                </Col>

                <Flex justify="center" style={{ width: '100%' }}>
                  <Form.Item
                    name="scenario"
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, selecione o cenário!',
                      },
                    ]}
                  >
                    <Flex
                      gap="8px"
                      align="center"
                      justify="center"
                      onClick={() => setIsModalScenarioOpen(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <PiImage size={28} color={colorGreyBorderAndFont} />
                      <Text
                        size="md"
                        style={{ textDecoration: 'underline' }}
                        color={colorGreyBorderAndFont}
                      >
                        Definir Cenário
                      </Text>
                    </Flex>
                  </Form.Item>
                </Flex>
              </Row>
            </Flex>
          </Col>

          <Col xs={24} md={6}>
            <Flex
              vertical
              style={{
                padding: '16px',
                backgroundColor: '#F1F1F1CC',
                borderRadius: '20px',
                height: '100%',
              }}
            >
              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Rotas
              </Text>

              <Form.Item name="routes" hidden />

              <Flex
                vertical
                gap="8px"
                justify="center"
                align="center"
                style={{ width: '100%' }}
              >
                <Button
                  size="md"
                  style={{ alignSelf: 'center' }}
                  onClick={() => {
                    setIsModalRouteOpen(true);
                  }}
                >
                  + Rotas
                </Button>

                <Flex vertical style={{ width: '100%' }} gap="16px">
                  {routes?.map((route, index) => (
                    <RouteBox
                      key={index}
                      route={route}
                      selected={selectedRouteIndex === index}
                      onSelect={() => setSelectedRouteIndex(index)}
                      onRemove={() => {
                        showConfirm({
                          message: t('Tem certeza que deseja excluir a rota?'),
                          colorPrimary,
                          isMobile,
                        }).then(({ isConfirmed }) => {
                          if (isConfirmed) {
                            onRemoveRoute(index);
                          }
                        });
                      }}
                      onEdit={() => {
                        setRouteToEdit(route);
                        setIsModalRouteOpen(true);
                      }}
                    />
                  ))}
                </Flex>
              </Flex>
            </Flex>
          </Col>

          <Col xs={24} md={18}>
            <Flex
              vertical
              style={{
                padding: '16px',
                backgroundColor: '#F1F1F1CC',
                borderRadius: '20px',
                height: '100%',
              }}
            >
              <Text
                size="lg"
                bold
                color={colorPrimary}
                style={{ marginBottom: '8px' }}
              >
                Área de Criação
              </Text>

              <Flex vertical gap="16px">
                <Button
                  size="md"
                  style={{ alignSelf: 'center' }}
                  disabled={
                    !routes?.length ||
                    latestInteraction?.contentType === ContentType.END ||
                    latestInteraction?.contentType === ContentType.DECISION ||
                    latestInteraction?.nextRouteIndex !== undefined
                  }
                  onClick={() => {
                    setIsModalInteractionOpen(true);
                  }}
                >
                  + Interações
                </Button>
                <Flex
                  vertical
                  justify="center"
                  align="center"
                  gap="8px"
                  style={{ width: '100%' }}
                >
                  {routes?.[selectedRouteIndex]?.interactions?.map(
                    (interaction, index) => (
                      <InteractionBox
                        key={index}
                        interaction={interaction}
                        routes={routes}
                        character={routes?.[
                          selectedRouteIndex
                        ]?.characters?.find(
                          (c) => c.id === interaction.characterId
                        )}
                        onRemove={() => {
                          showConfirm({
                            message: t(
                              'Tem certeza que deseja excluir a interação?'
                            ),
                            colorPrimary,
                            isMobile,
                          }).then(({ isConfirmed }) => {
                            if (isConfirmed) {
                              onRemoveInteraction(
                                selectedRouteIndex,
                                interaction.index
                              );
                            }
                          });
                        }}
                        onEdit={() => {
                          setInteractionToEdit(interaction);
                          setIsModalInteractionOpen(true);
                        }}
                        onChangeRoute={(routeIndex) => {
                          setSelectedRouteIndex(routeIndex);
                        }}
                      />
                    )
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Col>
        </Row>
      </Form>
    </Layout.Container>
  );
}

export default CreateStory;
