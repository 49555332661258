import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useCurrentUser } from 'core/features/user/store';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import ActivityFilter from '../ActivityFilter';
import useActivities from 'core/features/activity/hooks/useActivities';
import { ActivityDetails } from 'core/features/activity/types';
import { useDeleteActivity } from 'core/features/activity/hooks/useDeleteActivity';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { FilterType } from 'infra/helpers/types';
import { calcDiffDays } from 'infra/helpers/calcDiffDays';
import { t } from 'core/resources/strings';

function ActivitiesTable({
  onEdit,
  onProgress,
}: {
  onEdit: (item: ActivityDetails) => void;
  onProgress: (item: ActivityDetails) => void;
}) {
  const [check] = useCheckPermissions();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  let location = useLocation();
  const { colorPrimary } = useU4heroColors();

  const types: {
    [key: string]: {
      label: string;
      permissions: {
        edit: string;
        delete: string;
      };
      link: string;
      type: number;
    };
  } = {
    '/activities/individual': {
      label: t('app.atividadeIndividual'),
      permissions: {
        edit: Permissions.Activity.Edit,
        delete: Permissions.Activity.Delete,
      },
      link: '/activities/create/individual',
      type: 3,
    },
    '/activities/class': {
      label: t('app.atividadeDaTurma'),
      permissions: {
        edit: Permissions.Activity.Edit,
        delete: Permissions.Activity.Delete,
      },
      link: '/activities/create/class',
      type: 0,
    },
    '/activities/self-avaliation': {
      label: t('app.avaliacaoInicial'),
      permissions: {
        edit: Permissions.SelfAvaliation.Edit,
        delete: Permissions.SelfAvaliation.Delete,
      },
      link: '/activities/create/self-avaliation',
      type: 1,
    },
    '/activities/avaliation': {
      label: t('app.avaliacaoDaTurma'),
      permissions: {
        edit: Permissions.Avaliation.Edit,
        delete: Permissions.Avaliation.Delete,
      },
      link: '/activities/create/avaliation',
      type: 2,
    },
    '/activities/individual-avaliation': {
      label: t('app.avaliacaoIndividual'),
      permissions: {
        edit: Permissions.Avaliation.Edit,
        delete: Permissions.Avaliation.Delete,
      },
      link: '/activities/create/individual-avaliation',
      type: 4,
    },
  };

  let pathname = location.pathname.endsWith('/')
    ? location.pathname.slice(0, -1)
    : location.pathname;

  const type = types[pathname].type;

  const [filters, setFilters] = useState<FilterType>(
    type === 0 || type === 3
      ? {
          tags: [],
          initialDate: [],
          endDate: [],
        }
      : {
          initialDate: [],
          endDate: [],
        }
  );
  const { data, isLoading, refetch, isFetching } = useActivities({
    page,
    typeId: type,
    disableConcat: true,
    publishDate: filters?.initialDate.map((item) => item.value)[0] ?? undefined,
    endDate: filters?.endDate.map((item) => item.value)[0] ?? undefined,
    tags: filters?.tags?.map((item) => item.value) ?? undefined,
    limit: pageSize,
    responsible: search,
  });
  const { mutate: handleDelete } = useDeleteActivity();
  const isEachLoading = isLoading || isFetching;
  const { user } = useCurrentUser();
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}></p>,
      dataIndex: 'game.midia',
      key: 'game.midia',
      render: (game: string, record: ActivityDetails) => (
        <img
          alt={record.game?.name['pt-br']}
          width="100px"
          style={{ borderRadius: '15px', aspectRatio: '16/9' }}
          src={record.game?.coverImageUrl ?? ''}
        />
      ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.jogo')}</p>,
      name: t('app.jogo'),
      dataIndex: 'game.name',
      key: 'game.name',
      mobile: true,
      render: (game: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>{record?.game?.name['pt-br']}</p>
      ),
      sorter: (a: ActivityDetails, b: ActivityDetails) =>
        a.game.name['pt-br'].localeCompare(b.game.name['pt-br']),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.trilha')}</p>,
      name: t('app.trilha'),
      dataIndex: 'tag.name',
      key: 'tag.name',
      mobile: true,
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>{record.tag.name['pt-br']}</p>
      ),
      sorter: (a: ActivityDetails, b: ActivityDetails) =>
        a.tag.name['pt-br'].localeCompare(b.tag.name['pt-br']),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.responsavel')}</p>,
      name: t('app.responsavel'),
      dataIndex: 'responsible.name',
      key: 'responsible.name',
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>{record?.responsible?.name}</p>
      ),
      sorter: (a: any, b: any) =>
        a?.responsible?.name?.localeCompare(b?.responsible?.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: (
        <p style={tableHeaderStyle}>
          {type === 1 ? '' : t('app.qtd')}
          <br />
          {type === 1
            ? t('app.turma')
            : type === 3 || type === 4
            ? t('app.Estudantes')
            : t('app.turmas')}
        </p>
      ),
      dataIndex: 'quantity',
      key: 'quantity',
      name: `${t('app.qtd')} ${
        type === 3 || type === 4 ? t('app.Estudantes') : t('app.turmas')
      }`,
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>
          {type === 1
            ? record.classes[0]?.name
            : type === 3 || type === 4
            ? record.countStudents
            : record.countClasses}
        </p>
      ),
      sorter: (a: any, b: any) =>
        type === 1
          ? a?.countClasses
          : type === 3
          ? b.students.length - a.students.length
          : b.classes.length - a.classes.length,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.inicio')}</p>,
      key: 'publishDate',
      dataIndex: 'publishDate',
      name: t('app.inicio'),
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.publishDate).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.publishDate.localeCompare(b.publishDate),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.termino')}</p>,
      key: 'endDate',
      dataIndex: 'endDate',
      name: t('app.termino'),
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.endDate).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.endDate.localeCompare(b.endDate),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.tempoRestante')}</p>,
      dataIndex: 'disponible',
      key: 'disponible',
      name: t('app.tempoRestante'),
      render: (_: string, record: ActivityDetails) => (
        <p style={tableRowStyle}>
          {calcDiffDays(record.endDate) > 0 ? calcDiffDays(record.endDate) : 0}{' '}
          dia(s)
        </p>
      ),
      sorter: (a: any, b: any) => b.endDate - a.endDate,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.acoes')}</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: ActivityDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'edit') {
                  onEdit(record);
                }
                if (e.key === 'delete') {
                  if (user?.id !== record.responsible.id) {
                    notificateError(t('app.voceNaoEoResponsavelPelaAtividade'));
                  } else {
                    showConfirm({
                      message: t('app.excluirAtividadeConfirmacao'),
                      colorPrimary,
                      isMobile,
                    }).then(({ isConfirmed }) => {
                      if (isConfirmed) {
                        handleDelete([record.id], {
                          onSuccess: () => {
                            notificateSucess(
                              t('app.atividadeExcluidaComSucesso')
                            );
                            refetch();
                          },
                          onError: () => {
                            notificateError(t('app.erroAoExcluirAtividade'));
                          },
                        });
                      }
                    });
                  }
                }
                if (e.key === 'progress') {
                  onProgress(record);
                }
              }}
            >
              {check([types[pathname].permissions.edit]) ? (
                <Menu.Item key={'edit'} id="id-tour-table-row-action-edit">
                  {t('app.editar')}
                </Menu.Item>
              ) : null}

              {check([types[pathname].permissions.delete]) ? (
                <Menu.Item key={'delete'} id="id-tour-table-row-action-delete">
                  {t('app.deletar')}
                </Menu.Item>
              ) : null}

              <Menu.Item
                key={'progress'}
                id="id-tour-table-row-action-progress"
              >
                {t('app.progresso')}
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            id="id-tour-activities-table-action-button"
            size={24}
            style={{ cursor: 'pointer' }}
          />
        </Dropdown>
      ),
    },
  ];

  if (type !== 0 && type !== 3) {
    columns.splice(0, 3);
    columns[0].mobile = true;
    columns[1].mobile = true;
  }

  useEffect(() => {
    setPage(1);
  }, [search, filters]);
  return (
    <DynamicTable
      id="id-tour-my-school-section-activities-table"
      itemName={
        type === 0 || type === 3 ? t('app.atividade') : t('app.avaliacao')
      }
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      searchBarPlaceholder={t('app.pesquisarPeloResponsavel')}
      filters={
        <ActivityFilter filters={filters} onChangeFilters={setFilters} />
      }
    ></DynamicTable>
  );
}

export default ActivitiesTable;
