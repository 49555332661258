import http from 'infra/http';
import { useMutation } from 'react-query';

export interface useChatGptParams {
  prompt:string,
  response_format?:any
}
function useChatGPT() {
  return useMutation(
    (params: useChatGptParams) => {
      return http.post('/v2/generate', { question: params.prompt, response_format: params.response_format });
    },
    { retry: false }
  );
}

export default useChatGPT;
