import { useMediaQuery } from '@chakra-ui/react';
import { Col, Divider, Flex, Row, Typography } from 'antd';
import SelectBase from 'components/inputs/select-base';
import { useClassification } from 'core/features/safeSpace/hooks/useClassification';
import useSafeSpace from 'core/features/safeSpace/hooks/useSafeSpace';
import { useStatus } from 'core/features/safeSpace/hooks/useStatus';
import { t } from 'core/resources/strings';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import background from 'assets/images/background-safe-space.png';
import Status from 'components/status';
import Classification from 'ui/pages/app/safeSpace/components/Classification';
import {
  notificateError,
  notificateSucess,
  openNotification,
} from 'infra/helpers/notifications';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import SafeSpaceChat from '../SafeSpaceChat';
import SafespaceComment from '../SafeSpaceComment';
import SafeSpaceAvaliation from '../SafeSpaceAvaliation';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import acessDenied from 'assets/images/lumi-acess-denied.png';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import U4Avatar from 'components/u4avatar';
import SafeSpaceRecord from 'ui/pages/app/safeSpace/components/SafeSpaceRecord';
import useSafeSpaceAvaliation from 'core/features/safeSpace/hooks/useSafeSpaceAvaliation';
import { Element } from 'react-scroll';
import { TfiInfo } from 'react-icons/tfi';
import ModalClassifications from 'components/modals/modal-classifications';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';

function SafeSpaceAnalysis() {
  const params = useParams<{ id: string }>();
  const { data, error, refetch } = useSafeSpace(params.id);
  const { data: avalation } = useSafeSpaceAvaliation(params.id);
  const history = useHistory();
  const { mutate: handleClassification } = useClassification();
  const { mutate: handleStatus } = useStatus();
  const [flag, setFlag] = useState<number>();
  const [status, setStatus] = useState<number>();
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [check] = useCheckPermission();
  const [isOpenModal, setIsOpenModal] = useState(false);

  const checkProgress = () => {
    if (
      (status !== data?.status && status !== undefined) ||
      (flag !== data?.teacherClassification &&
        flag !== undefined &&
        flag !== null)
    ) {
      return true;
    }
    return false;
  };

  const handleSaveInfo = () => {
    if (
      status === data?.status &&
      (flag === data?.teacherClassification ||
        flag === null ||
        flag === undefined)
    ) {
      notificateError(t('app.façaAlgumaAlteracaoAntesDeSalvar'));
    } else {
      if (status !== data?.status) {
        handleStatus(
          { safeSpaceId: data?.id, status: status },
          {
            onError: () => {
              notificateError(t('app.erroAoSalvarAlteracoes'));
              return;
            },
            onSuccess: () => {
              refetch();
            },
          }
        );
      }
      if (flag !== undefined && data?.teacherClassification !== flag) {
        handleClassification(
          { safeSpaceId: data?.id, flag: flag },
          {
            onError: () => {
              notificateError(t('app.erroAoSalvarAlteracoes'));
              return;
            },
            onSuccess: () => {
              refetch();
            },
          }
        );
      }
      notificateSucess(t('app.informacoesSalvasComSucesso'));
    }
  };
  useEffect(() => {
    if (data) {
      if (data?.teacherClassification !== 0) {
        setFlag(data?.teacherClassification);
      }
      if (data?.status !== null) {
        setStatus(data.status);
      }
    }
  }, [data]);

  if (error) {
    return (
      <Layout.Container>
        <Flex
          vertical
          align="center"
          justify="center"
          style={{ height: '100%' }}
          gap={16}
        >
          <img src={acessDenied} alt="acesso proibido" />
          <Title>{t('app.acessoNegado')}</Title>
          <Text style={{ maxWidth: 873 }} align="justify" size="lg">
            {t('app.mensagemAcessoNegadoMeuCantinho')}
          </Text>
          <Button size="md" onClick={() => history.push('/')}>
            {t('app.voltarParaPaginaInicial')}
          </Button>
        </Flex>
      </Layout.Container>
    );
  }
  return (
    <Layout.Container>
      {isOpenModal && (
        <ModalClassifications onClose={() => setIsOpenModal(false)} />
      )}
      <Flex
        gap={isMobile ? '12px' : '24px'}
        align="center"
        style={{ marginBottom: isMobile ? '14px' : '24px' }}
      >
        <BackButton
          onClick={() => {
            if (checkProgress()) {
              openNotification({
                onClose: () => history.goBack(),
                colorPrimary,
                isMobile,
              });
            } else {
              history.goBack();
            }
          }}
        />
        <Title primary>{t('app.cantinhoDoEstudante')}</Title>
      </Flex>

      <Flex vertical gap={'24px'} style={{ marginBottom: '16px' }}>
        <Flex
          gap="16px"
          align="center"
          style={{
            width: '100%',
            background: 'rgba(244, 244, 244, 0.80)',
            backgroundImage: `url(${background})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            padding: '8px 16px',
            borderRadius: isMobile ? '20px' : '10px',
          }}
        >
          <U4Avatar
            frame={data?.avatar ? data?.avatar?.frame?.imageUrl : defaultFrame}
            background={
              data?.avatar
                ? data?.avatar?.background?.imageUrl
                : defaultBackground
            }
            icon={data?.avatar ? data?.avatar?.icon?.imageUrl : defaultIcon}
            size="medium"
          />
          <Flex
            vertical={isMobile}
            justify="space-around"
            style={{
              width: '100%',
              fontSize: isMobile ? '14px' : '18px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            <p>
              <strong style={{ color: colorPrimary }}>{t('app.nome')}: </strong>
              {!isMobile ? <br /> : null}
              {data?.anonymous === false ? data?.name : t('app.anonimo')}
            </p>
            {!isMobile ? (
              <Divider
                type="vertical"
                style={{ color: 'black', height: '56px' }}
              />
            ) : null}
            <p>
              <strong style={{ color: colorPrimary }}>
                {t('app.turmaSérie')}:{' '}
              </strong>
              {!isMobile ? <br /> : null}
              {data?.class.name} - {data?.class.serie.name}
            </p>
            {!isMobile ? (
              <Divider
                type="vertical"
                style={{ color: 'black', height: '56px' }}
              />
            ) : null}
            <p>
              <strong style={{ color: colorPrimary }}>
                {t('app.turno')}:{' '}
              </strong>
              {!isMobile ? <br /> : null}
              {data ? t(`app.${data?.class?.period}`) : null}
            </p>
          </Flex>
        </Flex>

        <Row
          align="stretch"
          gutter={[
            { xs: 32, md: 45 },
            { xs: 32, md: 32 },
          ]}
        >
          <Col xs={24} md={16}>
            <SafeSpaceRecord
              message={data?.message}
              date={data?.date}
              id={data?.id}
              classification={data?.systemClassification}
              classificationJustification={
                avalation?.classificationJustification
              }
            />
          </Col>
          <Col xs={24} md={8}>
            <div
              className="box-shadow-generic-card"
              style={{ padding: '16px', height: '100%' }}
            >
              <p
                style={{
                  fontSize: isMobile ? '18px' : '24px',
                  color: colorPrimary,
                  fontWeight: 700,
                }}
              >
                {t('app.suaClassificacaoEImportante')}
              </p>

              <Flex vertical gap={16}>
                <div>
                  <Flex gap={8} align="center">
                    <Text size="md">{t('app.selecioneAClassificacao')}:</Text>
                    <TfiInfo
                      style={{
                        color: 'white',
                        background: colorPrimary,
                        fontSize: isMobile ? '14px' : '20px',
                        fontWeight: 900,
                        borderRadius: '50%',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        setIsOpenModal(true);
                      }}
                    />
                  </Flex>

                  <SelectBase
                    id="id-tour-safe-space-input-teacher-classification"
                    disabled={!check(Permissions.SafeSpace.Edit)}
                    tooltip={t(
                      'app.vocenãoTemPermissãoParaEditarEsteAcolhimento'
                    )}
                    options={[
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={1}
                          />
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={2}
                          />
                        ),
                        value: 2,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={3}
                          />
                        ),
                        value: 3,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={4}
                          />
                        ),
                        value: 4,
                      },
                      {
                        label: (
                          <Classification
                            size={isMobile ? 'sm' : 'md'}
                            type={5}
                          />
                        ),
                        value: 5,
                      },
                    ]}
                    value={flag}
                    onChange={(e) => setFlag(e)}
                  />
                </div>
                <div>
                  <Text size="md">{t('app.statusDoAcolhimento')}:</Text>
                  <SelectBase
                    id="id-tour-safe-space-input-status"
                    disabled={!check(Permissions.SafeSpace.Edit)}
                    tooltip={t(
                      'app.vocenãoTemPermissãoParaEditarEsteAcolhimento'
                    )}
                    options={[
                      {
                        label: (
                          <Status
                            size={isMobile ? 'sm' : 'md'}
                            type={'notStarted'}
                          />
                        ),
                        value: 0,
                      },
                      {
                        label: (
                          <Status
                            size={isMobile ? 'sm' : 'md'}
                            type={'progress'}
                          />
                        ),
                        value: 1,
                      },
                      {
                        label: (
                          <Status size={isMobile ? 'sm' : 'md'} type={'done'} />
                        ),
                        value: 2,
                      },
                    ]}
                    value={status}
                    onChange={(e) => setStatus(e)}
                  />
                </div>
              </Flex>
              <Flex
                justify="center"
                align="center"
                style={{ marginTop: '12px' }}
                vertical
                gap={8}
              >
                <Button
                  size="md"
                  id="id-tour-safe-space-button-save"
                  onClick={handleSaveInfo}
                  disabled={!checkProgress()}
                >
                  {t('app.salvar')}
                </Button>
              </Flex>
            </div>
          </Col>
          <Col span={24}>
            <SafeSpaceAvaliation avaliation={avalation!} />
          </Col>
        </Row>

        <Row align="stretch" gutter={isMobile ? [0, 24] : [24, 0]}>
          <Col span={isMobile ? 24 : 12}>
            <Element
              name="comment"
              className="element"
              style={{ height: '100%' }}
            >
              <SafespaceComment comments={data?.comments ?? []} />
            </Element>
          </Col>
          <Col span={isMobile ? 24 : 12}>
            <Element
              name="support"
              className="element"
              style={{ height: '100%' }}
            >
              <SafeSpaceChat />
            </Element>
          </Col>
        </Row>
      </Flex>
    </Layout.Container>
  );
}

export default SafeSpaceAnalysis;
