import React, { useState, useRef, useEffect } from 'react';
import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Modal, message, Row, Col } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import Button from 'components/buttons/button';
import CarouselV2 from 'components/V2/carousel';
import { Stage, Layer, Image, Transformer, Rect } from 'react-konva';
import useImage from 'use-image';
import Konva from 'konva';
import { t } from 'core/resources/strings';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import useStoreItems from 'core/features/store-items/hooks/useStoreItems';
import axios from 'axios';
import api from 'infra/http';

interface DraggableElementProps {
  src: string;
  onSelect: () => void;
  isSelected: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
}

const DraggableElement: React.FC<DraggableElementProps> = ({
  src,
  onSelect,
  isSelected,
  x,
  y,
  width,
  height,
}) => {
  const [image] = useImage(src);
  const shapeRef = useRef<Konva.Image>(null);
  const trRef = useRef<Konva.Transformer>(null);

  useEffect(() => {
    if (isSelected && shapeRef.current && trRef.current) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer()?.batchDraw();
    }
  }, [isSelected]);

  return (
    <>
      <Image
        image={image}
        onClick={onSelect}
        crossOrigin="anonymous"
        ref={shapeRef}
        draggable
        x={x}
        y={y}
        width={width}
        height={height}
        onDragEnd={(e: Konva.KonvaEventObject<DragEvent>) => {
          if (shapeRef.current) {
            shapeRef.current.position(e.target.position());
          }
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

interface ScenarioItem {
  id: string;
  image: string;
  x: number;
  y: number;
  width: number;
  height: number;
}

interface ModalScenarioProps {
  onClose: () => void;
  onSave: (scenario: string) => void;
  value?: string;
}

function ModalScenario({ onClose, onSave, value }: ModalScenarioProps) {
  const { colorPrimary, colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [backgroundImageObj] = useImage(backgroundImage || '');
  const [elements, setElements] = useState<ScenarioItem[]>([]);
  const [selectedId, selectShape] = useState<string | null>(null);
  const stageRef = useRef<Konva.Stage>(null);
  const [columnWidth, setColumnWidth] = useState(0);
  const columnRef = useRef<HTMLDivElement>(null);
  const [scenarioItems, setScenarioItems] = useState<any[] | undefined>([]);
  const [objectsItems, setObjectsItems] = useState<any[] | undefined>([]);

  const { data } = useStoreItems({
    page: 1,
    limit: 1000,
    types: ['scenario', 'object'],
  });

  const slidesToShow = 3;

  const addElement = (
    element: Omit<ScenarioItem, 'x' | 'y' | 'width' | 'height'>
  ) => {
    setElements([
      ...elements,
      { ...element, x: 50, y: 50, width: 100, height: 100 },
    ]);
  };

  const changeScenario = (scenario: string) => {
    setBackgroundImage(scenario);
  };

  const uploadAndAddImage = async (file: any) => {
    const imageTypes = ['image/png', 'image/jpeg'];
    if (!imageTypes.includes(file.type)) {
      notificateError('Formato de arquivo inválido');
    }

    if (imageTypes.includes(file.type) && file.size > 3e6) {
      notificateError('Tamanho de arquivo inválido');
      return null;
    }
    const formData = new FormData();

    formData.append('file', file);

    try {
      const { data }: { data: { result: string } } = await api.post(
        `/v1/file/generic-upload?folderKey=u4heroStories&bucketName=u4hero-files`,
        formData
      );
      onSave(data.result);
    } finally {
    }
  };

  const saveCanvasAsImage = async () => {
    if (stageRef.current) {
      const stageWidth = stageRef.current.width();
      const stageHeight = stageRef.current.height();

      const width = 2560;
      const height = 1440;
      const scaleX = width / stageWidth;
      const scaleY = height / stageHeight;
      const scale = Math.min(scaleX, scaleY);

      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');

      if (context) {
        const centerX = (width - stageWidth * scale) / 2;
        const centerY = (height - stageHeight * scale) / 2;

        context.drawImage(
          stageRef.current.toCanvas(),
          centerX,
          centerY,
          stageWidth * scale,
          stageHeight * scale
        );

        try {
          const blob = await new Promise<Blob>((resolve, reject) => {
            canvas.toBlob((blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error('Canvas to Blob conversion failed'));
              }
            }, 'image/png');
          });

          const file = new File([blob], 'scenario.png', { type: 'image/png' });

          await uploadAndAddImage(file);
          onClose();
        } catch (error) {
          console.error('Error uploading scenario:', error);
          notificateError(
            'Não foi possível salvar o cenário. Tente novamente.'
          );
        }
      } else {
        notificateError('Não foi possível criar o contexto do canvas.');
      }
    } else {
      notificateError('Não foi possível salvar o cenário. Tente novamente.');
    }
  };

  useEffect(() => {
    if (columnRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setColumnWidth(entry.contentRect.width);
        }
      });

      resizeObserver.observe(columnRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  const fetchImage = async (imageUrl: string) => {
    try {
      const response = await axios.get(imageUrl, { responseType: 'blob' });
      const imageBlob = response.data;

      const imageObjectUrl = URL.createObjectURL(imageBlob);
      return imageObjectUrl;
    } catch (error) {
      console.error('Erro ao carregar a imagem:', error);
    }
  };
  useEffect(() => {
    const populate = async () => {
      const scenarios = data?.data.filter((item) => item.type === 'scenario');
      const objects = data?.data.filter((item) => item.type === 'object');
      const scenariosWithImage = [];

      for (const scenario of scenarios ?? []) {
        scenariosWithImage.push({
          ...scenario,
          imageUrl: await fetchImage(scenario.imageUrl),
        });
      }

      setScenarioItems(scenariosWithImage);
      const objectsWithImage = [];
      for (const object of objects ?? []) {
        objectsWithImage.push({
          ...object,
          imageUrl: await fetchImage(object.imageUrl),
        });
      }
      setObjectsItems(objectsWithImage);
    };
    if (data) {
      populate();
    }
  }, [data]);

  useEffect(() => {
    if (value) {
      changeScenario(value);
    }
  }, [value]);

  return (
    <Modal
      title={<SubTitle primary>Definir Cenário</SubTitle>}
      open={true}
      onCancel={onClose}
      footer={
        <Flex justify="center" gap={isMobile ? '12px' : '24px'}>
          <Button
            variant="outline"
            size="md"
            onClick={onClose}
            id="id-tour-my-school-student-modal-button-cancel"
          >
            {t('app.cancelar')}
          </Button>

          <Button
            htmlType="submit"
            size="md"
            onClick={saveCanvasAsImage}
            id="id-tour-my-school-student-modal-button-save"
          >
            {t('app.salvar')}
          </Button>
        </Flex>
      }
      width={isMobile ? '95%' : 980}
      closeIcon={
        <div
          style={{
            background: colorPrimary,
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoClose size={16} color="white" />
        </div>
      }
    >
      <Flex vertical style={{ width: '100%', marginBottom: '16px' }}>
        <Text
          size="md"
          bold
          style={{
            backgroundColor: hex2rgba(colorPrimary, 0.7),
            borderRadius: '20px 20px 0px 0px',
            padding: '8px',
            width: '100%',
          }}
          color="white"
          align="center"
        >
          Cenário Base
        </Text>
        <Row
          style={{
            border: `2px solid ${colorGreyBorderAndFont}`,
            borderRadius: '0px 0px 10px 10px',
            padding: '18px 8px',
          }}
        >
          <Col xs={24} md={18} ref={columnRef}>
            <Stage width={columnWidth} height={400} ref={stageRef}>
              <Layer>
                {backgroundImage ? (
                  <Image
                    image={backgroundImageObj}
                    width={columnWidth}
                    height={400}
                    crossOrigin="anonymous"
                  />
                ) : (
                  <Rect width={columnWidth} height={400} fill="#E9E9E9" />
                )}
                {elements.map((elem, i) => (
                  <DraggableElement
                    key={i}
                    src={elem.image}
                    isSelected={elem.id === selectedId}
                    onSelect={() => selectShape(elem.id)}
                    x={elem.x}
                    y={elem.y}
                    width={elem.width}
                    height={elem.height}
                  />
                ))}
              </Layer>
            </Stage>
          </Col>
          <Col xs={24} md={6}>
            <CarouselV2
              totalSlides={scenarioItems?.length || 0}
              slidesToShow={1}
              rows={2}
            >
              {scenarioItems?.map((item) => (
                <div
                  key={item.id}
                  onClick={() => changeScenario(`${item.imageUrl}`)}
                >
                  <img
                    src={`${item.imageUrl}`}
                    style={{
                      borderRadius: '10px',
                      width: '100%',
                      height: '128px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </CarouselV2>
          </Col>
        </Row>
      </Flex>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={14}>
          <Flex vertical>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              Elementos Adicionais
            </Text>

            <CarouselV2
              totalSlides={objectsItems?.length || 0}
              rows={1}
              slidesToShow={slidesToShow}
              style={{
                border: `2px solid ${colorGreyBorderAndFont}`,
                borderRadius: '0px 0px 10px 10px',
                padding: '4px 8px 8px 8px',
              }}
            >
              {objectsItems?.map((item) => (
                <div
                  key={item.id}
                  onClick={() =>
                    addElement({
                      id: item.id,
                      image: `${item.imageUrl}`,
                    })
                  }
                >
                  <img
                    src={`${item.imageUrl}`}
                    style={{
                      borderRadius: '10px',
                      border: '4px solid #E9E9E9',
                      padding: '8px',
                      minWidth: '100px',
                      height: '100px',
                      marginBottom: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </div>
              ))}
            </CarouselV2>
          </Flex>
        </Col>

        <Col xs={24} md={10}>
          <Flex vertical>
            <Text
              size="md"
              bold
              style={{
                backgroundColor: hex2rgba(colorPrimary, 0.7),
                borderRadius: '20px 20px 0px 0px',
                padding: '8px',
                width: '100%',
              }}
              color="white"
              align="center"
            >
              Gerar Imagem com IA
            </Text>
            <Flex
              vertical
              style={{
                border: `2px solid ${colorGreyBorderAndFont}`,
                borderRadius: '0px 0px 10px 10px',
                padding: '4px 8px 8px 8px',
              }}
              gap={8}
            >
              <Text size="md" style={{ padding: '8px' }}>
                Para gerar uma imagem com IA, selecione um cenário base e
                adicione elementos complementares.
              </Text>
              <Button size="md" style={{ alignSelf: 'center' }} disabled>
                Gerar Imagem
              </Button>
            </Flex>
          </Flex>
        </Col>
      </Row>
    </Modal>
  );
}

export default ModalScenario;
