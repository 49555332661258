import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import CircularProgress from 'components/CircularProgress';
import { Flex } from 'antd';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { currentLocale } from 'core/resources/strings/polyglot';

const messages = {
  1: {
    'pt-br':
      'Ausência de reflexão ou autoconsciência: A mensagem não demonstra qualquer insight ou informação sobre o entendimento do autor em relação a si mesmo, indicando uma ausência completa de introspecção.',
    en: "Lack of reflection or self-awareness: The message shows no insight or information about the author's self-understanding, indicating a complete absence of introspection.",
    es: 'Ausencia de reflexión o autoconciencia: El mensaje no muestra ninguna comprensión o información sobre el entendimiento del autor sobre sí mismo, indicando una ausencia completa de introspección.',
  },
  2: {
    'pt-br':
      'Baixo nível de autoconhecimento: A mensagem demonstra algumas observações superficiais sobre o autor, mas carece de profundidade ou análise significativa, sugerindo uma percepção limitada de si mesmo.',
    en: 'Low level of self-knowledge: The message shows some superficial observations about the author, but lacks depth or meaningful analysis, suggesting a limited self-perception.',
    es: 'Bajo nivel de autoconocimiento: El mensaje muestra algunas observaciones superficiales sobre el autor, pero carece de profundidad o análisis significativo, sugiriendo una percepción limitada de sí mismo.',
  },
  3: {
    'pt-br':
      'Algum nível de autoconhecimento: A mensagem demonstra uma tentativa de reflexão pessoal, mas ainda falta uma compreensão completa ou consistente, indicando um autoconhecimento em desenvolvimento.',
    en: 'Some level of self-knowledge: The message shows an attempt at personal reflection, but still lacks complete or consistent understanding, indicating developing self-knowledge.',
    es: 'Algún nivel de autoconocimiento: El mensaje muestra un intento de reflexión personal, pero aún carece de una comprensión completa o consistente, indicando un autoconocimiento en desarrollo.',
  },
  4: {
    'pt-br':
      'Moderado nível de autoconhecimento: A mensagem demonstra uma compreensão sólida e consciente de certos aspectos da personalidade, pensamentos ou emoções do autor, mas pode haver áreas que ainda necessitam de maior exploração ou compreensão.',
    en: "Moderate level of self-knowledge: The message shows a solid and conscious understanding of certain aspects of the author's personality, thoughts, or emotions, but there may be areas that still need further exploration or understanding.",
    es: 'Nivel moderado de autoconocimiento: El mensaje muestra una comprensión sólida y consciente de ciertos aspectos de la personalidad, pensamientos o emociones del autor, pero puede haber áreas que aún necesiten mayor exploración o comprensión.',
  },
  5: {
    'pt-br':
      'Alto nível de autoconhecimento: A mensagem demonstra uma profunda introspecção e consciência sobre si mesmo, com insights significativos e uma compreensão abrangente das próprias motivações, valores e experiências.',
    en: "High level of self-knowledge: The message shows deep introspection and self-awareness, with significant insights and a comprehensive understanding of one's own motivations, values, and experiences.",
    es: 'Alto nivel de autoconocimiento: El mensaje muestra una profunda introspección y conciencia de sí mismo, con percepciones significativas y una comprensión integral de las propias motivaciones, valores y experiencias.',
  },
  6: {
    'pt-br':
      'Excepcional nível de autoconhecimento: A mensagem demonstra uma compreensão extraordinária e penetrante do autor sobre si mesmo, com insights profundos e uma capacidade notável de se relacionar consigo mesmo e com os outros de forma autêntica e significativa.',
    en: 'Exceptional level of self-knowledge: The message shows an extraordinary and penetrating understanding of the author about themselves, with deep insights and a remarkable ability to relate to themselves and others in an authentic and meaningful way.',
    es: 'Nivel excepcional de autoconocimiento: El mensaje muestra una comprensión extraordinaria y penetrante del autor sobre sí mismo, con percepciones profundas y una notable capacidad para relacionarse consigo mismo y con los demás de manera auténtica y significativa.',
  },
};

function SelfKnowledge({ level }: { level: number }) {
  const percentage = (level / 6) * 100;
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <div
      className="box-shadow-generic-card"
      style={{
        padding: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <SubTitle primary>{t('app.autoconhecimento')}</SubTitle>
      <Flex vertical={isMobile} gap={16} align="center">
        <CircularProgress
          percentage={percentage}
          label={`Nível ${level}`}
          primaryColor={colorPrimary}
        />
        <Text size="md">
          {messages[level as keyof typeof messages][currentLocale]}
        </Text>
      </Flex>
    </div>
  );
}

export default SelfKnowledge;
