import { useQuery } from 'react-query';
import axios from 'infra/http';

interface useActivitiesStatsParams {
  classId?: string;
  userId?: string;
  initialDate?: string;
  endDate?: string;
  tenantId?: string;
}

interface SafeSpaceStats {
  total: number;
  flag0: number;
  flag1: number;
  flag2: number;
  flag3: number;
  flag4: number;
  flag5: number;
}
function useSafeSpaceStats(params: useActivitiesStatsParams) {
  const fetchData = async (
    params: useActivitiesStatsParams
  ): Promise<SafeSpaceStats> => {
    const { data } = await axios.get<SafeSpaceStats>(`/v2/safeSpace/stats`, {
      params,
    });
    return data;
  };

  return useQuery([`/v2/safeSpace/stats`, params], () => fetchData(params), {
    enabled: !!params.classId || !!params.userId,
  });
}

export default useSafeSpaceStats;
