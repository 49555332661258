import { useMediaQuery } from '@chakra-ui/react';
import { Flex, Form, Radio, Collapse } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useQuestionTypes from 'core/features/questions/hooks/useQuestionTypes';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { Permissions } from 'core/resources/enums/permissions';
import '../style.css';
import { t } from 'core/resources/strings';
import { languages } from 'infra/helpers/types';

function AdditionalInformation({
  hasTwoCompetences,
}: {
  hasTwoCompetences: boolean;
}) {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [check] = useCheckPermissions();

  const isU4hero = check([Permissions.U4hero.Questions.Create]);

  const questionTypes = useQuestionTypes();

  let allScopes = [
    {
      id: 0,
      name: t('app.privada'),
    },
    {
      id: 1,
      name: t('app.publica'),
    },
    {
      id: 2,
      name: t('app.global'),
    },
  ];
  if (isU4hero) {
    allScopes.splice(0, 2);
  } else {
    allScopes.pop();
  }

  return (
    <GenericCard>
      <Collapse
        ghost
        expandIconPosition="end"
        style={{ width: '100%' }}
        defaultActiveKey={'1'}
      >
        <Collapse.Panel
          key={'1'}
          className="remove-padding"
          header={
            <Text size="md" color={colorPrimary} bold style={{ width: '100%' }}>
              {t('app.informacoesAdicionais')}
            </Text>
          }
        >
          <Flex gap={14} vertical style={{ width: '100%', marginTop: '8px' }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('app.mensagemErroTipoPergunta'),
                },
              ]}
              name="typeId"
              label={t('app.tipoPergunta')}
            >
              <Radio.Group>
                <Flex wrap={!isMobile ? 'wrap' : 'nowrap'} vertical={isMobile}>
                  {questionTypes?.data?.map((type) => (
                    <Radio value={type.id} disabled={hasTwoCompetences}>
                      {type.name}
                    </Radio>
                  ))}
                </Flex>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('app.mensagemErroEscopo'),
                },
              ]}
              label={t('app.escopo')}
              name="privacyId"
            >
              <Radio.Group>
                <Flex wrap={!isMobile ? 'wrap' : 'nowrap'} vertical={isMobile}>
                  {allScopes.map((scope) => (
                    <Radio value={scope.id} key={scope.id} disabled={isU4hero}>
                      {scope.name}
                    </Radio>
                  ))}
                </Flex>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              rules={[
                {
                  required: true,
                  message: t('app.mensagemErroIdiomaPergunta'),
                },
              ]}
              label={t('app.idiomaDaPergunta')}
              name="locale"
            >
              <Radio.Group>
                <Flex wrap={!isMobile ? 'wrap' : 'nowrap'} vertical={isMobile}>
                  {languages.map((language) => (
                    <Radio value={language.value} key={language.value}>
                      {language.name}
                    </Radio>
                  ))}
                </Flex>
              </Radio.Group>
            </Form.Item>
          </Flex>
        </Collapse.Panel>
      </Collapse>
    </GenericCard>
  );
}

export default AdditionalInformation;
