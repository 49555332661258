import { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'infra/http';
import { Flex } from 'antd';
import Text from 'components/typography/text';
import { FiTrash2 } from 'react-icons/fi';
import { useMediaQuery } from '@chakra-ui/react';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import defaultProfile from 'assets/images/default-profile.png';
import defaultLandscape from 'assets/images/default-landscape.png';
import defaultInstituition from 'assets/images/default-instituition.png';
import defaultIcon from 'assets/images/default-icon.png';
import defaultGameIcon from 'assets/images/default-game.png';
import storeIcon from 'assets/images/icons/treasure-chest.png';
import Button from 'components/buttons/button';
import SvgIcon from 'components/SvgIcon';
import { t } from 'core/resources/strings';

interface UploadImagemProps {
  value: any;
  onChange: (arg: string | null) => any;
  alt?: string;
  placeholderImg?:
    | 'landscape'
    | 'icon'
    | 'user'
    | 'instituition'
    | 'storeIcon'
    | 'gameIcon';
  buttonText?: string;
  apiPath?: string;
  sizeLimitMB?: number;
  formats?: string[];
}

function UploadImagem({
  value,
  onChange,
  alt,
  placeholderImg = 'user',
  buttonText = t('app.carregarFoto'),
  sizeLimitMB = 3,
  apiPath,
  formats = ['jpg', 'png', 'jpeg'],
}: UploadImagemProps) {
  const [uploading, setUploading] = useState(false);
  const [fileInfo, setFileInfo] = useState(null);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary, colorGreyCard } = useU4heroColors();
  const [error, setError] = useState<string>();
  const refUploadPerfil = useRef<any>();

  const isSvg = formats.includes('svg');

  const defaulImages = {
    user: defaultProfile,
    landscape: defaultLandscape,
    icon: defaultIcon,
    instituition: defaultInstituition,
    storeIcon: storeIcon,
    gameIcon: defaultGameIcon,
  };
  useEffect(() => {
    setFileInfo(value);
  }, [value]);

  const handleUpload = useCallback(
    async (file: File) => {
      const maxFileSize = sizeLimitMB * 1e6;

      let fileType = file.type.split('/')[1];
      if (fileType.includes('+')) {
        fileType = fileType.split('+')[0];
      }

      if (!formats.includes(fileType)) {
        setError(t('app.formatoDeImagemInvalido'));
        return null;
      }

      if (file.size > maxFileSize) {
        setError(t('app.tamanhoDaImagemInvalido'));
        return null;
      }
      const formData = new FormData();

      formData.append('file', file);
      try {
        setUploading(true);
        const { data }: { data: { result: string } } = await axios.post(
          apiPath ? apiPath : `/v1/file`,
          formData
        );
        onChange(data.result);
      } catch (e) {
        setError(t('app.erroAoFazerUpload'));
        onChange(null);
      } finally {
        setUploading(false);
        setError(undefined);
      }
    },
    [onChange]
  );

  const defaultImage = defaulImages[placeholderImg];
  return (
    <Flex gap={isMobile ? '18px' : '24px'} align="center">
      <input
        type="file"
        ref={refUploadPerfil}
        style={{ display: 'none' }}
        accept="image/*"
        onChange={async (ev) => {
          if (ev.target.files) {
            const file = ev.target.files[0];
            if (file) {
              handleUpload(file);
            }
          }
        }}
      />
      <div style={{ position: 'relative' }}>
        {isSvg && value ? (
          <SvgIcon url={value} color="black" size={100} />
        ) : (
          <img
            src={fileInfo || value || defaultImage}
            alt={alt}
            style={{
              objectFit: 'cover',
              width: isMobile ? '80px' : '100px',
              height: isMobile ? '80px' : '100px',
              borderRadius: '10px',
              background: !fileInfo && !value ? colorGreyCard : undefined,
            }}
          />
        )}

        {fileInfo || value ? (
          <Button
            type="default"
            size="sm"
            loading={uploading}
            onClick={() => {
              onChange(null);
              setFileInfo(null);
            }}
            style={{
              position: 'absolute',
              bottom: isMobile ? -4 : -8,
              right: isMobile ? -8 : -16,
              border: `1px solid #BB191E`,
              width: isMobile ? '24px' : undefined,
              height: isMobile ? '24px' : undefined,
            }}
          >
            <FiTrash2 size={isMobile ? 16 : 20} color="red" />
          </Button>
        ) : null}
      </div>
      <Flex vertical>
        <Button
          variant="outline"
          size={'sm'}
          onClick={() => refUploadPerfil.current.click()}
          loading={uploading}
        >
          {buttonText}
        </Button>
        <Text style={{ fontSize: '12px' }}>
          {t('app.formatosAceitos')}: {formats.join(', ').toUpperCase()}
          <br />
          {t('app.tamanhoMaximoDoArquivo')}: {sizeLimitMB} MB
        </Text>
        <Text style={{ fontSize: '12px' }} color="red">
          {error}
        </Text>
      </Flex>
    </Flex>
  );
}

export default UploadImagem;
