import { useMediaQuery } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface TextProps extends React.HTMLAttributes<HTMLElement> {
  children?: React.ReactNode;
  align?: 'left' | 'center' | 'right' | 'justify' | 'end';
  size?: 'lg' | 'md' | 'sm';
  grey?: boolean;
  bold?:boolean;
}

function Text({ children, align, size = 'md', bold, ...rest }: TextProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const sizes = {
    sm: isMobile ? 12 : 16,
    md: isMobile ? 14 : 18,
    lg: isMobile ? 18 : 20,
  };
  const textStyle = {
    ...rest.style,
    fontSize: rest.style?.fontSize ? rest.style.fontSize : sizes[size],
    color: rest.style?.color
      ? rest.style.color
      : rest.color
      ? rest.color
      : 'black',
    fontWeight: bold ? 700 : rest?.style?.fontWeight ? rest?.style?.fontWeight : 400,
    textAlign: align,
  };
  return (
    <p {...rest} style={textStyle}>
      {children}
    </p>
  );
}

export default Text;
