import { Divider, Flex } from 'antd';
import { StudentItem } from 'core/features/students/typings';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';
import U4Avatar from 'components/u4avatar';
import Text from 'components/typography/text';
import Tag from 'components/V2/tag';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Button from 'components/buttons/button';
import { useMediaQuery } from '@chakra-ui/media-query';
import dayjs from 'dayjs';
import { PiKey } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router';
import { t } from 'core/resources/strings';

function StudentCard(student: StudentItem) {
  const { colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const history = useHistory();

  const { tenantId } = useParams<{ tenantId: string }>();

  const redirectToDashboard = (userId: string) => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/student/${userId}`);
    } else {
      history.push(`/my-school/student/${userId}`);
    }
  };

  return (
    <Flex
      className="box-shadow-generic-card"
      justify="center"
      align="center"
      vertical
      style={{ padding: '16px', position: 'relative' }}
      gap="16px"
    >
      <Flex
        gap={4}
        align="center"
        style={{
          position: 'absolute',
          top: 8,
          right: 8,
        }}
      >
        <PiKey color={colorGreyBorderAndFont} size={16} />{' '}
        <Text size="sm" color={colorGreyBorderAndFont}>
          {student.license}
        </Text>
      </Flex>
      <U4Avatar
        frame={
          student?.avatar ? student?.avatar?.frame?.imageUrl : defaultFrame
        }
        background={
          student?.avatar
            ? student?.avatar?.background?.imageUrl
            : defaultBackground
        }
        icon={
          student?.avatar
            ? (student?.avatar?.icon?.imageUrl as string)
            : defaultIcon
        }
        size="large"
      />
      <div style={{ alignSelf: 'baseline' }}>
        <Text size="lg">{student?.name}</Text>
        <Flex gap="8px" align="center">
          <Tag text={student?.serie?.name ?? t('app.serieIndefinida')} />
          <Tag
            type="outline"
            text={student?.class?.name ?? t('app.semTurma')}
          />{' '}
        </Flex>
      </div>
      <Divider style={{ margin: '0px', background: 'black' }} />
      <Flex
        vertical={!isMobile}
        justify={isMobile ? 'space-between' : 'flex-start'}
        gap={isMobile ? undefined : '8px'}
        style={{ alignSelf: 'baseline', width: '100%' }}
      >
        <Text size="sm">
          <span style={{ color: colorGreyBorderAndFont }}>
            {t('app.ativacaoDeChave')}
          </span>
          <br />
          {student?.activationDate
            ? dayjs(student?.activationDate).format('DD/MM/YYYY')
            : '-'}
          :
        </Text>
        <Text size="sm">
          <span style={{ color: colorGreyBorderAndFont }}>
            {t('app.ultimoAcesso')}:
          </span>
          <br />
          {student.lastSync
            ? dayjs(student?.lastSync).format('DD/MM/YYYY')
            : '-'}
        </Text>
      </Flex>

      <Button size="md" onClick={() => redirectToDashboard(student.userId)}>
        {t('app.visualizarPainel')}
      </Button>
    </Flex>
  );
}

export default StudentCard;
