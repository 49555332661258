import { Flex } from 'antd';
import summaryImage from 'assets/images/report/report-summary-image.png';
import summaryImageWl from 'assets/images/white-label/report-summary-image.png';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React from 'react';
import SubTitle from 'components/typography/subTitle';
import styled from 'styled-components';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';

const SummaryContainer = styled(Flex)`
  @media print {
    width: 210mm;
    height: 297mm;
    margin: 0;
    page-break-after: always;
  }
`;

export default function ReportSummary() {
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();

  const items = [
    {
      label: t('app.metodologiaU4hero'),
      topics: [
        {
          topic: t('app.metodologiaU4hero'),
          page: 2,
        },
        {
          topic: t('app.oQueSaoCompetenciasSocioemocionais'),
          page: 3,
        },
        {
          topic: t(
            'app.quaisAreasSaoImpactadasPeloSubdesenvolvimentoDasCompetenciasEmocionais'
          ),
          page: 5,
        },
        {
          topic: t('app.indicadoresDeClimaEscolar'),
          page: 8,
        },
        {
          topic: t('app.tiposDeAnalise'),
          page: 9,
        },
      ],
    },
    {
      label: t('app.guiaParaInterpretarOsResultadosDesteRelatorio'),
      topics: [
        {
          topic: t('app.resumo'),
          page: 11,
        },
        {
          topic: t('app.dados'),
          page: 13,
        },
        {
          topic: t('app.iconesParaAvaliacao'),
          page: 13,
        },
      ],
    },
    {
      label: t('app.analiseDeDados'),
      topics: [
        {
          topic: t('app.amostra'),
          page: 15,
        },
        {
          topic: t('app.emocoesERegistros'),
          page: 16,
        },
        {
          topic: t('app.resumo'),
          page: 17,
        },
        {
          topic: t('app.dados'),
          page: 21,
        },
      ],
    },
    {
      label: t('app.referencias'),
      topics: [
        {
          topic: t('app.referenciasBibliograficas'),
          page: 23,
        },
      ],
    },
  ];
  return (
    <SummaryContainer gap="middle" align="flex-start">
      <img
        src={user?.whiteLabel ? summaryImageWl : summaryImage}
        alt={user?.whiteLabel ? 'Ilustração' : 'Lumi com livros'}
        style={{
          maxWidth: '393px',
          objectFit: 'contain',
          flexShrink: 0,
        }}
      />
      <Flex vertical flex={1}>
        <Title primary style={{ fontSize: '68px' }}>
          SUMÁRIO
        </Title>
        <Flex gap="16px" vertical>
          {items.map((item, index) => (
            <Flex
              gap="24px"
              align="flex-start"
              key={index}
              style={{ width: '100%' }}
            >
              <Flex
                align="center"
                justify="center"
                style={{
                  borderRadius: '50%',
                  backgroundColor: colorPrimary,
                  minWidth: '60px',
                  minHeight: '60px',
                }}
              >
                <Title color="white">{index + 1}</Title>
              </Flex>
              <Flex vertical style={{ width: '100%' }}>
                <SubTitle
                  primary
                  style={{
                    textTransform: 'uppercase',
                  }}
                >
                  {item.label}
                </SubTitle>
                {item.topics.map((topic, index) => (
                  <React.Fragment key={index}>
                    <Flex
                      style={{ width: '100%' }}
                      justify="space-between"
                      align="flex-end"
                    >
                      <Text
                        size="sm"
                        align="justify"
                        style={{ marginRight: 8, lineHeight: '40px' }}
                      >
                        {topic.topic}
                      </Text>
                      <div
                        style={{
                          flex: 1,
                          borderBottom: '2px dotted #00000040',
                          margin: '0 8px',
                          position: 'relative',
                          top: '-4px',
                        }}
                      />
                      <Text
                        size="sm"
                        style={{
                          lineHeight: '40px',
                        }}
                      >
                        {`${
                          topic.page < 10
                            ? '0' + topic.page.toString()
                            : topic.page.toString()
                        }`}
                      </Text>
                    </Flex>
                  </React.Fragment>
                ))}
              </Flex>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </SummaryContainer>
  );
}
