import { Divider, Flex } from 'antd';
import Text from 'components/typography/text';
import Tag from 'components/V2/tag';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import Button from 'components/buttons/button';
import { PiKey } from 'react-icons/pi';
import { useHistory, useParams } from 'react-router';
import { t } from 'core/resources/strings';
import { ClassDetails } from 'core/features/classes/types';

function ClassCard(classe: ClassDetails) {
  const { colorGreyBorderAndFont } = useU4heroColors();
  const history = useHistory();

  const { tenantId } = useParams<{ tenantId: string }>();

  const redirectToDashboard = (classId: string) => {
    if (tenantId) {
      history.push(`/schools/${tenantId}/class/dashboard/${classId}`);
    } else {
      history.push(`/my-school/class/dashboard/${classId}`);
    }
  };

  return (
    <Flex
      className="box-shadow-generic-card"
      justify="center"
      vertical
      style={{ padding: '16px', position: 'relative' }}
      gap="16px"
    >
      <Tag text={classe?.serie?.name ?? t('app.serieIndefinida')} />
      <Text size="md" bold>
        {classe.name}
      </Text>
      <Flex gap={4} align="center">
        <PiKey color={colorGreyBorderAndFont} size={20} />{' '}
        <Text size="sm" color={colorGreyBorderAndFont}>
          {classe.user.email}
        </Text>
      </Flex>

      <Divider style={{ margin: '0px', background: 'black' }} />
      <Flex
        justify={'space-between'}
        style={{ alignSelf: 'baseline', width: '100%' }}
      >
        <Text size="sm">
          <span style={{ color: colorGreyBorderAndFont }}>
            {t('app.estudantes').slice(0, 1).toUpperCase() +
              t('app.estudantes').slice(1)}
            :
          </span>
          <br />
          {classe?.countStudents}
        </Text>
        <Text size="sm">
          <span style={{ color: colorGreyBorderAndFont }}>
            {t('app.anoLetivo')}:
          </span>
          <br />
          {classe.year}
        </Text>
      </Flex>

      <Button
        size="md"
        style={{ alignSelf: 'center' }}
        onClick={() => redirectToDashboard(classe.id)}
      >
        {t('app.visualizarPainel')}
      </Button>
    </Flex>
  );
}

export default ClassCard;
