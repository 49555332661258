import { useMediaQuery } from '@chakra-ui/react';
import { Select } from 'antd';
import { CompetenceDetails } from 'core/features/questions/types/QuestionsDetails';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';

function SelectCompetence({
  id,
  value,
  onChange,
  disabled,
  type,
}: {
  id?: string;
  value: string;
  onChange: (v: string, competence?: CompetenceDetails) => void;
  disabled?: boolean;
  type?: number;
}) {
  const { data } = useTagsCompetence({
    competence: true,
    type,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Select
      id={id}
      options={data?.map((item) => ({
        label: item?.name['pt-br'],
        value: item.id,
      }))}
      value={value}
      onChange={(v) =>
        onChange(
          v,
          data?.find((item) => item.id === v)
        )
      }
      size={isMobile ? 'middle' : 'large'}
      disabled={disabled}
    />
  );
}

export default SelectCompetence;
