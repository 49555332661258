import SelfKnowledge from 'ui/pages/app/safeSpace/components/SelfKnowledge';
import SocialExpressions from 'ui/pages/app/safeSpace/components/SocialExpressions';
import { Flex } from 'antd';
import { SafeSpaceAvaliationItem } from 'core/features/safeSpace/typings';
import { Element } from 'react-scroll';

function SafeSpaceAvaliation({
  avaliation,
}: {
  avaliation: SafeSpaceAvaliationItem;
}) {
  return (
    <Flex gap={32} vertical>
      {avaliation?.selfKnowledgeLevel !== undefined && (
        <SelfKnowledge level={avaliation?.selfKnowledgeLevel ?? 1} />
      )}
      {avaliation?.quantityOfContextualizedTextualExpressions && (
        <Element name="expressions-details" className="element">
          <SocialExpressions
            {...avaliation.quantityOfContextualizedTextualExpressions}
          />
        </Element>
      )}
    </Flex>
  );
}

export default SafeSpaceAvaliation;
