import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Collapse, Divider, Flex, Popover, Row, Typography } from 'antd';
import Text from 'components/typography/text';
import Star from 'components/star';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { IoClose } from 'react-icons/io5';
import {
  PiClockAfternoonLight,
  PiDownloadSimple,
  PiFileDocLight,
  PiHashLight,
  PiUsersFourLight,
} from 'react-icons/pi';
import { t } from 'core/resources/strings';
import SubTitle from 'components/typography/subTitle';
import CustomCarousel from 'components/custom-carousel';
import { MdExpandMore } from 'react-icons/md';
import { useState } from 'react';
import { extractNonImageUrls } from 'infra/helpers/urlExtractor';
import PracticeDetail from 'ui/pages/app/practices/pages/Practice/components/practiceDetail';
import clock from 'assets/images/practice-details/clock.png';
import backpack from 'assets/images/practice-details/backpack.png';
import brain from 'assets/images/practice-details/brain.png';
import hashtag from 'assets/images/practice-details/hashtag.png';
import children from 'assets/images/practice-details/children.png';
import folder from 'assets/images/practice-details/folder.png';
import rocket from 'assets/images/practice-details/rocket.png';
import weights from 'assets/images/practice-details/weights.png';
import { TfiInfo } from 'react-icons/tfi';

const moment = require('moment');
require('moment/locale/pt-br');

const { Title } = Typography;

function PracticePreview({
  banner,
  duration,
  author,
  resources,
  bnccCode,
  bnccDefinition,
  title,
  maxAge,
  goal,
  content,
  skillName,
  competenceName,
  subject,
  preRequisites,
  previousContext,
  goalIcon,
  observationIcon,
  observationPoints,
  inclusionGuide,
  modality,
  environment,
  dificulty,
  suggestedTag,
  target,
  onClose,
}: {
  banner?: string;
  duration?: number;
  maxAge?: number;
  author?: string;
  resources?: string;
  bnccCode?: string;
  bnccDefinition?: string;
  title?: string;
  goal?: string;
  content?: string;
  skillName?: string;
  competenceName?: string;
  subject?: string;
  preRequisites?: string;
  previousContext?: string;
  goalIcon?: string;
  observationIcon?: string;
  observationPoints?: string;
  inclusionGuide?: string;
  modality?: string;
  environment?: string;
  target?: string;
  dificulty?: string;
  suggestedTag?: string;
  onClose: () => void;
}) {
  const { colorPrimary, colorGreyBorderAndFont, colorSecondary } =
    useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [attachmentsCount, setAttachmentsCount] = useState(3);

  const currentDate = moment();
  currentDate.locale('pt-br');

  const detailItems = [
    {
      label: t('app.duracao'),
      text: `${t('app.previstoParaDurar')} ${duration} ${t('app.minutos')}.`,
      icon: clock,
    },
    {
      label: t('app.faixaEtaria'),
      text: `${t('app.aPartirDe')} ${maxAge} ${t('app.anos').toLowerCase()}.`,
      icon: children,
    },
    {
      label: t('app.codigoBNCC'),
      text: (
        <Flex gap="8px">
          <Text size="md">{`(${bnccCode})`}</Text>
          <Popover
            content={
              <Text size="md" color="white">
                {bnccDefinition}
              </Text>
            }
          >
            <Flex
              align="center"
              justify="center"
              style={{
                backgroundColor: colorPrimary,
                borderRadius: '50px',
                width: '18px',
                height: '18px',
                padding: '2px',
              }}
            >
              <TfiInfo style={{ color: 'white', fontWeight: 900 }} />
            </Flex>
          </Popover>
        </Flex>
      ),
      icon: hashtag,
    },
    {
      label: t('app.materiais'),
      text: `${resources}`,
      icon: backpack,
    },
    {
      label: t('app.competencia'),
      text: competenceName,
      icon: brain,
    },
    {
      label: t('app.dificuldade'),
      text: `${dificulty}`,
      icon: weights,
    },
    {
      label: t('app.componenteCurricular'),
      text: `${subject}`,
      icon: rocket,
    },
    {
      label: t('app.trilhaSugerida'),
      text: `${suggestedTag}`,
      icon: folder,
    },
  ];

  const attachments = extractNonImageUrls(content ?? '');

  const cleanContent = (content: string) => {
    let cleanedContent = content;
    attachments.forEach((attachment) => {
      cleanedContent = cleanedContent.replace(attachment.url, '');
    });
    return cleanedContent;
  };

  return (
    <div style={{ padding: isMobile ? '0px' : '16px' }}>
      {!isMobile ? (
        <>
          <Flex justify="flex-end" style={{ cursor: 'pointer' }}>
            <IoClose
              size={24}
              color={colorGreyBorderAndFont}
              onClick={onClose}
            />
          </Flex>
          <Title
            level={1}
            style={{
              color: colorPrimary,
              fontSize: isMobile ? '24px' : '28px',
              fontWeight: 700,
              margin: '0px',
              marginBottom: '16px',
            }}
          >
            Prévia
          </Title>
        </>
      ) : null}

      <div style={{ position: 'relative' }}>
        <img
          alt={`banner ${title}`}
          src={banner}
          style={{
            borderRadius: '10px',
            width: '100%',
            height: isMobile ? '156px' : '275px',
            objectFit: 'cover',
          }}
        />
        <Flex
          style={{
            width: '100%',
            position: 'relative',
            bottom: isMobile ? 18 : 10,
          }}
          justify="center"
        >
          <div
            style={{
              borderRadius: '5px 5px 0px 0px',
              fontSize: isMobile ? '12px' : '16px',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorSecondary,
              fontWeight: 700,
              color: 'white',
              textTransform: 'uppercase',
            }}
          >
            {target} - {modality}
          </div>
        </Flex>
        {!isMobile ? (
          <div style={{ position: 'absolute', bottom: -10, left: -10 }}>
            <Star color={colorPrimary} size={'70px'} fontSize="16px">
              {environment}
            </Star>
          </div>
        ) : (
          <div
            style={{
              borderRadius: '10px 5px 5px 0px',
              fontSize: isMobile ? '12px' : '16px',
              position: 'absolute',
              paddingLeft: isMobile ? '14px' : '16px',
              paddingRight: isMobile ? '14px' : '16px',
              background: colorPrimary,
              color: 'white',
              top: 0,
            }}
          >
            {environment}
          </div>
        )}
        {!isMobile && (
          <Flex
            gap={8}
            style={{
              position: 'absolute',
              top: 8,
              right: 0,
            }}
          >
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiUsersFourLight size={24} color="white" />
              <Text size="md" color="white">{`${maxAge} ${t(
                'app.anosMais'
              )}`}</Text>
            </Flex>
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiHashLight size={24} color="white" />
              <Text size="md" color="white">
                {bnccCode}
              </Text>
            </Flex>
            <Flex
              style={{
                background: colorPrimary,
                borderRadius: '5px',
                padding: '8px',
              }}
              gap={8}
            >
              <PiClockAfternoonLight size={24} color="white" />
              <Text size="md" color="white">
                {duration} min
              </Text>
            </Flex>
          </Flex>
        )}
      </div>

      <p
        style={{
          fontSize: isMobile ? '14px' : '18px',
          color: colorGreyBorderAndFont,
          textAlign: 'end',
        }}
      >
        {currentDate.format('DD [de] MMMM [de] YYYY')}
        <br />
        Autor: {author}
      </p>
      {!isMobile ? (
        <Title
          level={2}
          style={{
            color: colorPrimary,
            fontSize: isMobile ? '18px' : '24px',
            fontWeight: 700,
            textAlign: 'center',
            margin: '0px',
            marginBottom: '8px',
          }}
        >
          {title}
        </Title>
      ) : null}

      <Row gutter={[24, 0]}>
        <Col span={24}>
          <Flex vertical gap={8}>
            <SubTitle primary align="center" id="pre-requisites">
              Pré-Requisitos
            </SubTitle>
            <Text size="md">{preRequisites}</Text>
            <SubTitle primary align="center" id="previous-context">
              Contexto Prévio
            </SubTitle>
            <Text size="md">{previousContext}</Text>
            <SubTitle primary align="center" id="title">
              {title}
            </SubTitle>
          </Flex>
          <div
            dangerouslySetInnerHTML={{
              __html: cleanContent(content ?? ''),
            }}
          />
          <Flex vertical gap={32} style={{ marginTop: '32px' }}>
            <Flex
              style={{
                background: '#F5F5F5',
                padding: '16px',
                borderRadius: '10px',
              }}
              vertical={isMobile}
            >
              <Flex vertical gap={24}>
                <Flex align="center" gap={12}>
                  <img src={goalIcon} />
                  <SubTitle primary id="goal">
                    Objetivo
                  </SubTitle>
                </Flex>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                  {goal
                    ?.split('.')
                    .filter((item) => item.trim())
                    .map((item, index) => (
                      <li key={index} style={{ marginBottom: '8px' }}>
                        <Text size="md" style={{ display: 'inline' }}>
                          {item}.
                        </Text>
                      </li>
                    ))}
                </ul>
              </Flex>

              <Divider
                type={isMobile ? 'horizontal' : 'vertical'}
                style={{ minHeight: isMobile ? '1px' : '200px' }}
              />
              <Flex vertical gap={24}>
                <Flex align="center" gap={12}>
                  <img src={observationIcon} />
                  <SubTitle primary id="observation-points">
                    {t('app.pontosDeObservacao')}
                  </SubTitle>
                </Flex>
                <ul style={{ paddingLeft: '20px', margin: 0 }}>
                  {observationPoints
                    ?.split('.')
                    .filter((item) => item.trim())
                    .map((item, index) => (
                      <li key={index} style={{ marginBottom: '8px' }}>
                        <Text size="md" style={{ display: 'inline' }}>
                          {item}.
                        </Text>
                      </li>
                    ))}
                </ul>
              </Flex>
            </Flex>

            <Collapse
              expandIconPosition="end"
              style={{ width: '100%' }}
              defaultActiveKey={'1'}
            >
              <Collapse.Panel
                key={'1'}
                header={
                  <Text size="md" style={{ width: '100%' }}>
                    {t('app.orientacoesParaInclusao')}
                  </Text>
                }
              >
                <div
                  id="inclusion-guide"
                  style={{ padding: '16px' }}
                  dangerouslySetInnerHTML={{
                    __html: inclusionGuide ?? '',
                  }}
                />
              </Collapse.Panel>
            </Collapse>
          </Flex>
          <Flex vertical gap={8} style={{ marginTop: '32px' }}>
            <SubTitle primary align="center" id="details">
              {t('app.detalhes')}
            </SubTitle>
            <CustomCarousel
              slidesToScroll={isMobile ? 2 : 3}
              slidesToShow={isMobile ? 2 : 3}
              className="special-carousel"
            >
              {detailItems.map((item) => (
                <PracticeDetail key={item.label} {...item} />
              ))}
            </CustomCarousel>
          </Flex>

          <Flex vertical gap={8} style={{ marginTop: '32px' }}>
            <SubTitle primary align="center" id="attachments">
              {t('app.anexos')}
            </SubTitle>
            <Row gutter={[16, 16]}>
              {attachments
                .slice(0, attachmentsCount)
                .map((attachment, index) => (
                  <Col key={index} xs={24} md={8}>
                    <Flex
                      gap="12px"
                      style={{
                        background: 'rgba(241, 241, 241, 0.50)',
                        borderRadius: '10px',
                        padding: '16px',
                      }}
                      align="center"
                      justify="space-around"
                    >
                      <PiFileDocLight size={40} />
                      <Text size="sm" color={colorGreyBorderAndFont}>
                        {attachment.fileName}
                      </Text>
                      <a
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        download
                      >
                        <PiDownloadSimple size={30} />
                      </a>
                    </Flex>
                  </Col>
                ))}
              {attachments.length > attachmentsCount && (
                <Flex
                  justify="center"
                  align="center"
                  style={{ width: '100%', cursor: 'pointer' }}
                  onClick={() => setAttachmentsCount(attachmentsCount + 3)}
                >
                  <Text style={{ color: colorGreyBorderAndFont }}>
                    {t('app.mostrarMais')}
                  </Text>
                  <MdExpandMore color={colorGreyBorderAndFont} size={22} />
                </Flex>
              )}
            </Row>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

export default PracticePreview;
