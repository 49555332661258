import { useMediaQuery } from '@chakra-ui/react';
import { Dropdown, Flex, Menu } from 'antd';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { Permissions } from 'core/resources/enums/permissions';
import moment from 'moment';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import DynamicTable from 'components/V2/dinamic-table';
import { FaEllipsisVertical } from 'react-icons/fa6';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import { useHistory } from 'react-router';
import { FilterType } from 'infra/helpers/types';
import StoriesFilter from '../StoriesFilter';
import useStories from 'core/features/stories/hooks/useStories';
import { StoryDetails } from 'core/features/stories/typings';
import { useDeleteStories } from 'core/features/stories/hooks/useDeleteStory';

function StoriesTable() {
  const [check] = useCheckPermissions();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    authors: [],
    published: [],
    publishDate: [],
    tags: [],
  });
  const { data, isLoading, refetch, isFetching } = useStories({
    page,
    limit: pageSize,
    search,
    authors: filters.authors.map((item) => item.value) ?? undefined,
    published: filters.published.length
      ? filters.published.map((item) => item.value)[0] === 1
      : undefined,
  });

  const { mutate: handleDelete } = useDeleteStories();

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
  const isEachLoading = isLoading || isFetching;
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };
  const columns = [
    {
      title: <p style={tableHeaderStyle}>Nome</p>,
      name: 'Nome',
      dataIndex: 'name',
      key: 'name',
      mobile: true,
      render: (game: string, record: StoryDetails) => (
        <div>
          <p style={tableRowStyle}>{record.name}</p>
        </div>
      ),
      sorter: (a: any, b: any) => a.name.localeCompare(b.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Autor</p>,
      name: 'Autor',
      dataIndex: 'author',
      key: 'author',
      render: (_: string, record: StoryDetails) => (
        <p style={tableRowStyle}>{record.author}</p>
      ),
      sorter: (a: any, b: any) => a.author.localeCompare(b.author),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Tipo</p>,
      name: 'Tipo',
      dataIndex: 'published',
      key: 'published',
      render: (_: string, record: StoryDetails) => (
        <p style={tableRowStyle}>
          {record.published === true ? 'Publicado' : 'Rascunho'}
        </p>
      ),
      sorter: (a: any, b: any) => {
        if (a.published === b.published) {
          return 0;
        }
        return a.published ? -1 : 1;
      },
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data da Publicação</p>,
      name: 'Data da Publicação',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_: string, record: StoryDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.createdAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>Data da última Atualização</p>,
      name: 'Data da última Atualização',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (_: string, record: StoryDetails) => (
        <p style={tableRowStyle}>
          {moment.utc(record.updatedAt).format('DD/MM/YYYY')}
        </p>
      ),
      sorter: (a: any, b: any) => a.updatedAt.localeCompare(b.updatedAt),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },

    {
      title: <p style={tableHeaderStyle}>Ações</p>,
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: 'Ações',
      render: (_: string, record: StoryDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(e) => {
                if (e.key === 'edit') {
                  history.push(`/stories/edit/${record.id}`);
                }
                if (e.key === 'delete') {
                  showConfirm({
                    message: 'Tem certeza que deseja excluir a história?',
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      handleDelete([record.id], {
                        onSuccess: () => {
                          notificateSucess(
                            `A história foi excluída com sucesso!`
                          );
                          refetch();
                        },
                        onError: () => {
                          notificateError(
                            `Houve um problema ao excluir a história. Verifique sua conexão e tente novamente.`
                          );
                        },
                      });
                    }
                  });
                }
              }}
            >
              {check([Permissions.U4hero.Posts.Edit]) ? (
                <Menu.Item key={'edit'}>Editar</Menu.Item>
              ) : null}

              {check([Permissions.U4hero.Posts.Delete]) ? (
                <Menu.Item key={'delete'}>Deletar</Menu.Item>
              ) : null}
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical size={24} style={{ cursor: 'pointer' }} />
        </Dropdown>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: string[], selectedRows: StoryDetails[]) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  const onDelete = () => {
    showConfirm({
      message: 'Tem certeza que deseja excluir as histórias?',
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleDelete(selectedRowKeys, {
          onSuccess: () => {
            notificateSucess('As histórias foram excluídas com sucesso!');
            refetch();
          },
          onError: () => {
            notificateError(
              'Houve um problema ao excluir as histórias. Verifique sua conexão e tente novamente.'
            );
          },
        });
      }
    });
  };
  useEffect(() => {
    setPage(1);
  }, [search, filters]);

  return (
    <DynamicTable
      id="id-tour-stories-manager-stories-table"
      itemName="História"
      data={data?.data ?? []}
      onChangePage={setPage}
      onChangePageSize={setPageSize}
      title=""
      total={data?.total ?? 0}
      page={page}
      pageSize={pageSize}
      isLoading={isEachLoading}
      defaultColumns={
        isMobile ? columns.filter((item) => item.mobile) : columns
      }
      allColumns={columns}
      onSearch={setSearch}
      selected={selectedRowKeys?.length > 0}
      onDelete={onDelete}
      rowSelection={{ preserveSelectedRowKeys: true, ...rowSelection }}
      appliedFilters={filters}
      onChangeFilters={setFilters}
      filters={<StoriesFilter filters={filters} onChangeFilters={setFilters} />}
    />
  );
}

export default StoriesTable;
