import { useMediaQuery } from '@chakra-ui/react';
import { Select } from 'antd';
import { CompetenceDetails } from 'core/features/questions/types/QuestionsDetails';
import useTagsCompetence from 'core/features/tags/hooks/useTagsCompetence';
import { useEffect } from 'react';

function SelectSkill({
  value,
  onChange,
  parentId,
  disabled,
  setSkillName,
}: {
  value: string;
  onChange: (v: string, skill?: CompetenceDetails) => void;
  parentId: string;
  disabled: boolean;
  setSkillName?: (v: string) => void;
}) {
  const { data: skills } = useTagsCompetence({
    tag: parentId,
    disableQuery: parentId === undefined,
  });
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const options = [
    ...(skills || []).map((item) => ({
      label: item?.name['pt-br'],
      value: item.id,
    })),
  ].sort((a, b) => (a.label > b.label ? 1 : -1));

  useEffect(() => {
    if (value && skills) {
      setSkillName &&
        setSkillName(
          options.find((item) => item.value === value)?.label as string
        );
    }
  }, [value, skills]);
  return (
    <Select
      id="id-tour-create-practice-input-skill"
      placeholder="Selecione a habilidade"
      disabled={disabled}
      options={options}
      onChange={(v) =>
        onChange(v, skills?.find((item) => item.id === v))
      }
      value={value}
      size={isMobile ? 'middle' : 'large'}
    />
  );
}

export default SelectSkill;
