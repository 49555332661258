import { Flex } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import React from 'react';
import SubTitle from 'components/typography/subTitle';
import styled from 'styled-components';
import bottomDecoration from 'assets/images/report/report-decoration-bottom.png';
import topDecoration from 'assets/images/report/report-decoration-top.png';
import logo from 'assets/images/logo.png';
import { useCurrentUser } from 'core/features/user/store';

const Container = styled(Flex)<{ isGrayscale: boolean }>`
  position: relative;
  width: 100%;
  height: 297mm;
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    width: 100%;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    filter: ${(props) => (props.isGrayscale ? 'grayscale(100%)' : 'none')};
  }

  &::before {
    top: 0;
    background-image: url(${topDecoration});
  }

  &::after {
    bottom: -67px;
    background-image: url(${bottomDecoration});
  }

  @media print {
    width: 210mm;
    height: 297mm;
    margin: 0;
    page-break-after: always;
  }
`;

const ContentWrapper = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding: 0 24px;
  gap: 16px;
  z-index: 1;
  margin: 0 auto;
`;

function ReportPage({
  pageNumber,
  children,
  style,
}: {
  pageNumber: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) {
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();

  return (
    <Container style={style} isGrayscale={!!user?.whiteLabel}>
      <ContentWrapper>{children}</ContentWrapper>
      <img
        src={logo}
        alt="logo"
        width={100}
        style={{ position: 'absolute', bottom: 0, left: 0, zIndex: 2 }}
      />
      <Flex
        align="center"
        justify="center"
        style={{
          position: 'absolute',
          bottom: 20,
          left: '50%',
          transform: 'translateX(-50%)',
          borderRadius: '50%',
          backgroundColor: colorPrimary,
          minWidth: '40px',
          minHeight: '40px',
          zIndex: 2,
        }}
      >
        <SubTitle color="white">
          {pageNumber < 10 ? `0${pageNumber}` : pageNumber}
        </SubTitle>
      </Flex>
    </Container>
  );
}

export default ReportPage;
