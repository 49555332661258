import { Col, Flex, Pagination, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import SearchBar from 'components/V2/SearchBar';
import useClasses from 'core/features/classes/hooks/useClasses';
import { FilterType } from 'infra/helpers/types';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ClassCard from 'ui/pages/app/indicators/tabs/components/classCard';
import StudentFilter from 'ui/pages/app/mySchool/pages/Students/components/StudentFilter';

function ClassView() {
  const { tenantId } = useParams<{ tenantId: string }>();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [filters, setFilters] = useState<FilterType>({
    years: [],
    series: [],
  });

  const { data, isLoading } = useClasses({
    page,
    years: filters?.years?.map((item: any) => item.value) ?? undefined,
    series: filters?.series?.map((item: any) => item.value) ?? undefined,
    search,
    limit: pageSize,
    tenantId,
  });

  return (
    <Flex vertical gap={16}>
      <div>
        <Flex
          align="center"
          gap="16px"
          style={{ marginBottom: '16px', width: '100%' }}
        >
          <SearchBar width="100%" value={search} onSearch={setSearch} />
          <StudentFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </div>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Row
        gutter={[
          { xs: 0, md: 20 },
          { xs: 24, md: 20 },
        ]}
      >
        {data?.data.map((classe) => (
          <Col xs={24} md={6}>
            <ClassCard {...classe} />
          </Col>
          ))}
        </Row>
      )}
      <Flex justify="center" style={{ marginBottom: '18px' }}>
        <Pagination
          defaultCurrent={1}
          showSizeChanger
          current={page}
          pageSize={pageSize}
          total={data?.total}
          onChange={(v, size) => {
            setPage(v);
            setPageSize(size);
          }}
        />
      </Flex>
    </Flex>
  );
}

export default ClassView;
