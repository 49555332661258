import { Checkbox, ColorPicker, Flex, Form, Input, Switch } from 'antd';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import cosmoWithColors from 'assets/images/cosmo-with-colors.png';
import cosmoWithColorsHorizontal from 'assets/images/cosmo-with-colors-horizontal.png';
import './style.css';
import { useMediaQuery } from '@chakra-ui/media-query';
import { useEffect, useState } from 'react';
import { useCurrentUser } from 'core/features/user/store';
import useCheckPermission from 'core/features/user/hooks/useCheckPermission';
import { Permissions } from 'core/resources/enums/permissions';
import paintingLady from 'assets/images/white-label/painting-lady.png';
import paintingLadyHorizontal from 'assets/images/white-label/painting-lady2.png';

function ColorsSection({ updateForm }: { updateForm: (v: any) => void }) {
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const whiteLabel = Form.useWatch('whiteLabel', form);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [inheritWhiteLabel, setInheritWhiteLabel] = useState(false);
  const { user } = useCurrentUser();
  const [check] = useCheckPermission();
  const isU4Hero = check(Permissions.U4hero.Tenant.Create);

  useEffect(() => {
    if (inheritWhiteLabel) {
      updateForm({
        colorPrimary: user?.colorPrimary,
        colorPrimaryMedium: user?.colorPrimaryMedium,
        colorPrimaryLight: user?.colorPrimaryLight,
        colorSecondary: user?.colorSecondary,
        colorSecondaryMedium: user?.colorSecondaryMedium,
        colorSecondaryLight: user?.colorSecondaryLight,
      });
    }
  }, [inheritWhiteLabel]);

  return (
    <GenericCard style={{ position: 'relative' }} height="100%">
      <Flex
        vertical
        style={{ width: '100%', height: '100%' }}
        // justify="space-between"
      >
        <Flex justify="space-between">
          <Text
            size="lg"
            color={colorPrimary}
            style={{ marginBottom: '16px', fontWeight: 700 }}
          >
            Personalização do Site
          </Text>
          <Form.Item name="whiteLabel" valuePropName="checked">
            <Switch />
          </Form.Item>
        </Flex>
        {whiteLabel ? (
          <Text size="lg" align="justify" style={{ marginBottom: '8px' }}>
            Configure a paleta de cores da sua organização para uma identidade
            visual exclusiva no site!
          </Text>
        ) : (
          <div>
            <Text size="lg" align="justify">
              Ao ativar a Personalização do Site, o logo da sua organização será
              integrado à nossa plataforma e você poderá definir a paleta de
              cores conforme a identidade visual da sua marca.
            </Text>
            {!isMobile ? (
              <img
                src={user?.whiteLabel ? paintingLady : cosmoWithColors}
                width={user?.whiteLabel ? '140px' : '258px'}
                style={{
                  position: 'absolute',
                  bottom: '0',
                  left: '50%',
                  transform: 'translateX(-50%)',
                }}
              />
            ) : null}
          </div>
        )}
        {whiteLabel ? (
          <Flex vertical justify="space-between">
            {!isU4Hero ? (
              <Checkbox
                checked={inheritWhiteLabel}
                onChange={(e) => setInheritWhiteLabel(e.target.checked)}
                style={{ marginBottom: '16px', marginTop: '8px' }}
              >
                <Text size="lg">Usar as cores da organização!</Text>
              </Checkbox>
            ) : null}

            <Form.Item
              name="colorPrimary"
              label="Cor Principal:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>
            <Form.Item
              name="colorPrimaryMedium"
              label="Tom Escuro da Cor Principal:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>
            <Form.Item
              name="colorPrimaryLight"
              label="Tom Claro da Cor Principal:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>

            <Form.Item
              name="colorSecondary"
              label="Cor Segundária:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>
            <Form.Item
              name="colorSecondaryMedium"
              label="Tom Escuro da Cor Segundária:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>
            <Form.Item
              name="colorSecondaryLight"
              label="Tom Claro da Cor Segundária:"
              getValueFromEvent={(color, hex) => {
                return '#' + color.toHex();
              }}
              style={
                isMobile
                  ? { display: 'flex', height: '34px' }
                  : { marginBottom: '0px' }
              }
            >
              <ColorPicker
                className={isMobile ? undefined : 'custom-color-picker'}
              />
            </Form.Item>

            {!isMobile ? (
              <img
                src={
                  user?.whiteLabel
                    ? paintingLadyHorizontal
                    : cosmoWithColorsHorizontal
                }
                width="100%"
              />
            ) : null}
          </Flex>
        ) : null}
      </Flex>
    </GenericCard>
  );
}

export default ColorsSection;
