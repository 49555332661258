import { Flex } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import rightDecoration from 'assets/images/report/report-decoration-top-right.png';
import leftDecoration from 'assets/images/report/report-decoration-top-left.png';
import spaceship from 'assets/images/report/spaceship.png';
import ReportPage from 'ui/pages/app/indicators/components/new-report/components/page';
import { t } from 'core/resources/strings';
import { ActivityTypes } from 'core/resources/enums/activityTypes';
import { useCurrentUser } from 'core/features/user/store';

export default function ReportIntroduction({
  schoolName,
  typeId,
  analysisType,
  competenceName,
  skillName,
}: {
  schoolName: string;
  typeId: ActivityTypes;
  analysisType?: number;
  competenceName?: string;
  skillName?: string;
}) {
  const { colorPrimary, colorSecondary } = useU4heroColors();
  const { user } = useCurrentUser();

  const analysisTypeMessage = {
    0: t('app.conhecimentoIntroducaoMensagem'),
    1: t('app.comportamentoIntroducaoMensagem'),
    2: t('app.percepcaoIntroducaoMensagem'),
  };
  return (
    <>
      <ReportPage pageNumber={1}>
        <Title primary>{t('app.introducao')}</Title>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.esteRelatorioApresentaOsResultadosObtidosPelosEstudantesDa')}
          <strong> {schoolName} </strong>
          {typeId === ActivityTypes.SelfAvaliation
            ? t('app.introducaoMensagemAvaliacaoInicial')
            : typeId === ActivityTypes.Avaliation ||
              typeId === ActivityTypes.IndividualAvaliation
            ? t('app.introducaoMensagemAvaliacao')
            : ''}
          {(typeId === ActivityTypes.Normal ||
            typeId === ActivityTypes.Individual) && (
            <>
              {' '}
              {t('app.naAtividadeVoltadaParaDesenvolvimentoDaCompetencia')}
              <strong> {competenceName}. </strong> {t('app.oFocoEsteveEm')}
              <strong> {skillName} </strong>{' '}
              {t('app.utilizandoUmaAbordagemBaseadaEmSituacoesProblemas')}{' '}
              {analysisType !== undefined &&
                analysisTypeMessage[
                  analysisType as keyof typeof analysisTypeMessage
                ]}
            </>
          )}
          <br />
          <br /> {t('app.esteRelatorioFoiDivididoEmTresPartes')}
          <br />
          <br /> {t('app.naSegundaParte')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={2}>
        <img
          src={leftDecoration}
          alt="left-decoration"
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <img
          src={rightDecoration}
          alt="right-decoration"
          style={{
            position: 'absolute',
            top: 70,
            right: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <Flex vertical align="center" justify="center">
          <Title primary style={{ textTransform: 'uppercase' }}>
            {t('app.metodologiaU4hero')}
          </Title>
          <img src={spaceship} alt="Foguete" width={68} />
        </Flex>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.metodologiaExplicacao1')}
          <br />
          <br /> {t('app.metodologiaExplicacao2')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={3}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.metodologiaExplicacao3')}
          <br />
          <br />
          {t('app.metodologiaExplicacao4')}
        </Text>
        <Title primary style={{ textTransform: 'uppercase' }}>
          {t('app.oQueSaoCompetenciasSocioemocionais')}
        </Title>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.competenciasExplicacao1')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={4}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.competenciasExplicacao2')}
          <br />
          <br />
          {t('app.competenciasExplicacao3')}
          <br />
          <br />
          {t('app.competenciasExplicacao4')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={5}>
        <Title primary align="center" style={{ textTransform: 'uppercase' }}>
          {t(
            'app.quaisAreasSaoImpactadasPeloSubdesenvolvimentoDasCompetenciasEmocionais'
          )}
        </Title>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.areasImpactadasExplicacao1')}
          <br /> <br />{' '}
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.desempenhoEscolar')}:
          </span>{' '}
          {t('app.areasImpactadasExplicacao2')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={6}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.areasImpactadasExplicacao3')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.saudeMentalEFisica')}:
          </span>{' '}
          {t('app.areasImpactadasExplicacao4')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.relacoesInterpessoais')}:
          </span>{' '}
          {t('app.areasImpactadasExplicacao5')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={7}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.empregabilidadeEVidaProfissional')}:
          </span>{' '}
          {t('app.areasImpactadasExplicacao6')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.vidaSocialEConomica')}:
          </span>{' '}
          {t('app.areasImpactadasExplicacao7')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={8}>
        <Title primary>{t('app.indicadoresClimaEscolar')}</Title>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.indicadoresClimaEscolarExplicacao1')}
          <br />
          <br />
          {t('app.indicadoresClimaEscolarExplicacao2')}
          <br />
          <br />
          {t('app.indicadoresClimaEscolarExplicacao3')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={9}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.indicadoresClimaEscolarExplicacao4')}
          <br />
          <br />
          {t('app.indicadoresClimaEscolarExplicacao5')}
        </Text>
        <Title primary style={{ textTransform: 'uppercase' }}>
          {t('app.tiposDeAnalises')}
        </Title>

        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.tiposDeAnalisesExplicacao1')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            1. {t('app.conhecimento')}:
          </span>{' '}
          {t('app.tiposDeAnalisesExplicacao2')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={10}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.tiposDeAnalisesExplicacao3')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            2. {t('app.percepcao')}:
          </span>{' '}
          {t('app.tiposDeAnalisesExplicacao4')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            3. {t('app.comportamento')}:
          </span>{' '}
          {t('app.tiposDeAnalisesExplicacao5')}
          <br />
          <br />
          {t('app.tiposDeAnalisesExplicacao6')}
        </Text>
      </ReportPage>
    </>
  );
}
