import { useQuery } from 'react-query';
import axios from 'infra/http';
import { Game } from '../typings';
import { useEffect, useState } from 'react';

export interface UseGamesParams {
  page: number;
  limit?: number;
  search?: string;
  minAge?: number;
  maxAge?: number;
  ageClassification?: number;
  durations?: number[];
  questionLimits?: number[];
  startDate?:string;
  genres?:string[];
  types?:string[];
  favorites?:boolean;
  disabled?:boolean;
}

function useGames(params: UseGamesParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);
  const fetchData = async (
    params: UseGamesParams
  ): Promise<{ data: Game[]; total: number }> => {
    const { disabled, ...rest } = params;
    const { data, headers } = await axios.get<Game[]>(`/v2/game`, {
      params: {
        ...rest,
        search: debouncedQuery,
      },
    });

    return { data: data, total: Number(headers['total']) };
  };

  return useQuery(
    [`/v2/game`, { ...params, search: debouncedQuery }],
    () => fetchData(params),
    {
      keepPreviousData: true,
      enabled: !params.disabled,
    }
  );
}

export default useGames;
