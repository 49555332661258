import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Row } from 'antd';
import cosmo from 'assets/images/lumi-in-room.png';
import emptyPanel from 'assets/images/white-label/person-looking-at-graphs.png';
import Button from 'components/buttons/button';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import useIndicatorsProfile from 'core/features/indicators-profile/hooks/useIndicatorsProfile';
import { useSaveIndicatorsProfile } from 'core/features/indicators-profile/hooks/useSaveIndicatorsProfile';
import { IndicatorsProfileForm } from 'core/features/indicators-profile/typings';
import { useCurrentUser } from 'core/features/user/store';
import {
  notificateError,
  notificateSucess,
  showConfirm,
} from 'infra/helpers/notifications';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import ModalCustomizePanel from '../../components/modal-customize-panel';
import CustomGraph from '../components/customGraph';
import EmotionalGraph from '../components/emotionalGraph';
import StudentFrequencyGraph from '../components/studentFrequencyGraph';
import TotalClasses from '../components/totalClasses';
import TotalCollaborators from '../components/totalCollaborators';
import TotalEducators from '../components/totalEducators';
import TotalStudents from '../components/totalStudents';
import { v4 as uuidv4 } from 'uuid';
import { t } from 'core/resources/strings';

function CustomPanel() {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useCurrentUser();
  const { data } = useIndicatorsProfile(user?.id);
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const queryClient = useQueryClient();
  const { mutate: handleSave, isLoading: isLoadingSave } =
    useSaveIndicatorsProfile();
  const [graphIndex, setGraphIndex] = useState<number>();

  const [profile, setProfile] = useState<IndicatorsProfileForm>();
  const onRemoveIndicator = ({
    name,
    label,
    value,
  }: {
    name: string;
    label: string;
    value: any;
  }) => {
    let options = data?.profile.options;
    let graphs = data?.profile.graphs ?? [];

    if (name === 'graph') {
      graphs = graphs.filter((item) => item.id !== value);
    } else {
      if (options && name in options) {
        options[name] = false;
      }
    }

    showConfirm({
      message: `${t('app.temCertezaQueDesejaExcluirOIndicador')} “${label}”?`,
      colorPrimary,
      isMobile,
    }).then(({ isConfirmed }) => {
      if (isConfirmed) {
        handleSave(
          {
            id: data?.id,
            profile: {
              graphs: graphs,
              options: options!,
            },
          },
          {
            onSuccess: () => {
              notificateSucess(`${t('app.indicadorExcluidoComSucesso')}`);
              queryClient.invalidateQueries(`/v2/indicators-profile/`);
            },
            onError: () => {
              notificateError(`${t('app.mensagemErroSalvarIndicador')}`);
            },
          }
        );
      }
    });
  };

  useEffect(() => {
    if (data) {
      setProfile(data);
    }
  }, [data]);

  return (
    <>
      {isOpen && (
        <ModalCustomizePanel
          data={profile}
          graphIndex={graphIndex}
          onClose={() => {
            setIsOpen(false);
            setGraphIndex(undefined);
          }}
        />
      )}

      {data ? (
        <>
          {data.profile?.options && (
            <Row gutter={[47, isMobile ? 16 : 0]} justify="space-between">
              {data.profile?.options?.totalStudents && (
                <Col span={isMobile ? 12 : 6}>
                  <TotalStudents
                    onRemove={() => {
                      onRemoveIndicator({
                        name: 'totalStudents',
                        label: t('app.totalDeEstudantes'),
                        value: null,
                      });
                    }}
                  />
                </Col>
              )}

              {data.profile?.options?.totalClasses && (
                <Col span={isMobile ? 12 : 6}>
                  <TotalClasses
                    onRemove={() => {
                      onRemoveIndicator({
                        name: 'totalClasses',
                        label: t('app.totalDeTurmas'),
                        value: null,
                      });
                    }}
                  />
                </Col>
              )}

              {data.profile?.options?.totalEducators && (
                <Col span={isMobile ? 12 : 6}>
                  <TotalEducators
                    onRemove={() => {
                      onRemoveIndicator({
                        name: 'totalEducators',
                        label: t('app.totalDeProfessores'),
                        value: null,
                      });
                    }}
                  />
                </Col>
              )}

              {data.profile?.options?.totalCollaborators && (
                <Col span={isMobile ? 12 : 6}>
                  <TotalCollaborators
                    onRemove={() => {
                      onRemoveIndicator({
                        name: 'totalCollaborators',
                        label: t('app.totalDeColaboradores'),
                        value: null,
                      });
                    }}
                  />
                </Col>
              )}
            </Row>
          )}
          <Flex justify="end">
            <Link to="/tags">
              <Text size="sm" style={{ textDecoration: 'underline' }}>
                {t('app.entendaSobreAsCompetenciasEHabilidades')}
              </Text>
            </Link>
          </Flex>

          <Row align="stretch" gutter={[40, isMobile ? 16 : 20]}>
            {data.profile.options.emotionometer && (
              <Col span={isMobile ? 24 : 12}>
                <EmotionalGraph
                  onRemove={() => {
                    onRemoveIndicator({
                      name: 'emotionometer',
                      label: t('app.emocionometroDosUltimos7Dias'),
                      value: null,
                    });
                  }}
                />
              </Col>
            )}
            {data.profile.options.studentFrequency && (
              <Col span={isMobile ? 24 : 12}>
                <StudentFrequencyGraph />
              </Col>
            )}
            {profile?.profile?.graphs?.map((graph, index) => (
              <Col key={index} span={isMobile ? 24 : 12}>
                <CustomGraph
                  graph={graph}
                  onDuplicate={() => {
                    setIsOpen(true);
                    const newGraphId = uuidv4();
                    const nexIndex = profile.profile.graphs.length;
                    if (profile) {
                      setProfile({
                        ...profile,
                        profile: {
                          graphs: [
                            ...profile.profile.graphs,
                            { ...graph, id: newGraphId },
                          ],
                          options: profile.profile.options,
                        },
                      });
                    }
                    setGraphIndex(nexIndex);

                    notificateSucess(t('app.graficoDuplicadoComSucesso'));
                  }}
                  onEdit={() => {
                    setIsOpen(true);
                    setGraphIndex(index);
                  }}
                  onRemove={() => {
                    onRemoveIndicator({
                      name: 'graph',
                      label: graph.name,
                      value: graph.id,
                    });
                  }}
                />
              </Col>
            ))}
          </Row>
          <Flex justify="center">
            <Button size="md" onClick={() => setIsOpen(true)}>
              {t('app.maisGraficos')}
            </Button>
          </Flex>
        </>
      ) : (
        <Flex vertical justify="center" align="center" gap={16}>
          <img src={user?.whiteLabel ? emptyPanel : cosmo} />
          <Flex vertical style={{ maxWidth: '841px' }}>
            <SubTitle align="center">
              {t('app.personalizeSeuPainelDeIndicadores')}
            </SubTitle>
            <Text align="justify">
              {t('app.exploreAPersonalizacaoDoSeuPainelDeIndicadores')}
            </Text>
          </Flex>

          <Button size="md" onClick={() => setIsOpen(true)}>
            {t('app.iniciarPersonalizacao')}
          </Button>
        </Flex>
      )}
    </>
  );
}

export default CustomPanel;
