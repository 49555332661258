import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import { useCurrentUser } from 'core/features/user/store';
import { Pluralizar } from 'infra/helpers/plural';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import bgIcons from 'assets/images/white-label/bg-icons.png';
import womanWithBinoculars from 'assets/images/white-label/woman-with-binoculars.png';

function ViewCard() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { user } = useCurrentUser();
  return (
    <Flex
      style={{
        justifyContent: 'space-between',
        marginTop: isMobile ? '8px' : '24px',
        borderRadius: '10px',
        width: '100%',
        background: colorPrimary,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {!user?.whiteLabel && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background:
              "url('https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/faa8a4a9-76fe-4211-8c68-5c3744f5cd5b.png')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            borderRadius: '10px',
          }}
        ></div>
      )}

      <Flex justify={'space-between'} style={{ width: '100%', height: '97px' }}>
        {user?.whiteLabel ? (
          <>
            <Flex style={{ height: '100%' }} align="start">
              <img
                style={{ zIndex: 0, width: '123px', height: '118px' }}
                src={womanWithBinoculars}
                alt="Mulher com binoculos"
              />
            </Flex>
            <Flex style={{ height: '100%' }} align="end">
              <img
                style={{ zIndex: 0 }}
                width={'100%'}
                src={bgIcons}
                alt="planetas no espaço observados pelo lumi"
              />
            </Flex>
          </>
        ) : (
          <>
            <Flex style={{ height: '100%' }} align="start">
              <img
                style={{ zIndex: 0 }}
                height={'50%'}
                src={
                  'https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/e3b6280a-88a8-4a52-9115-e94e8a98ed31.png'
                }
                alt="Lumi com binoculo no rosto"
              />
            </Flex>
            <Flex style={{ height: '100%' }}>
              <img
                style={{ zIndex: 0 }}
                height={'100%'}
                src="https://u4hero-files.s3.sa-east-1.amazonaws.com/helpers/84001cf8-6d47-48cb-ae42-38ba485acb4c.png"
                alt="planetas no espaço observados pelo lumi"
              />
            </Flex>
          </>
        )}
      </Flex>
    </Flex>
  );
}

export default ViewCard;
