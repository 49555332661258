import { Flex } from 'antd';
import { t } from 'core/resources/strings';
import moment from 'moment';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import Text from 'components/typography/text';
import { RxReload } from 'react-icons/rx';
import { useReClass } from 'core/features/safeSpace/hooks/useReClass';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { safeSpaceFlags } from 'infra/helpers/types';
import {
  PiChatCenteredDots,
  PiCircleDashedLight,
  PiMagnifyingGlassLight,
} from 'react-icons/pi';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import React from 'react';
import { PiHeart } from 'react-icons/pi';
import SubTitle from 'components/typography/subTitle';
import { notificateError, notificateSucess } from 'infra/helpers/notifications';
import { useQueryClient } from 'react-query';
import { Link } from 'react-scroll';

export default function SafeSpaceRecord({
  message,
  date,
  classification = 0,
  id,
  classificationJustification,
}: {
  message?: string;
  date?: string;
  id?: string;
  classification?: number;
  classificationJustification?: string;
}) {
  const { colorGreyBorderAndFont } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { mutate: handleReClass } = useReClass();
  const queryClient = useQueryClient();

  const classifications = [
    ...safeSpaceFlags,
    {
      label: t('app.emProcessamento'),
      bg: hex2rgba('#ED810F', 0.6),
      color: '#ED810F',
      icon: PiCircleDashedLight,
      value: 6,
      shortDescription: t('app.emProcessamentoDescricao'),
    },
  ];

  const currentClassification = classifications.find(
    (item) => item.value === classification
  );

  const actions = [
    {
      label: t('app.verDetalhesDasExpressoes'),
      icon: PiMagnifyingGlassLight,
      onClick: () => {},
      value: 'expressions-details',
    },
    {
      label: t('app.comentar'),
      icon: PiChatCenteredDots,
      onClick: () => {},
      value: 'comment',
    },
    {
      label: t('app.acolher'),
      icon: PiHeart,
      onClick: () => {},
      value: 'support',
    },
  ];

  return (
    <GenericCard width="100%" height="100%" padding="16px 0px">
      <Flex vertical justify="space-between" style={{ width: '100%' }}>
        <SubTitle primary style={{ paddingLeft: '16px', marginBottom: '8px' }}>
          {t('app.registroMeuCantinho')}
        </SubTitle>
        <div style={{ padding: '0px 16px', marginBottom: '32px' }}>
          <GenericCard padding="8px" width="100%">
            <Flex vertical gap="4px" style={{ width: '100%' }}>
              <p
                style={{
                  fontSize: isMobile ? '14px' : '16px',
                  fontWeight: 400,
                  color: 'black',
                }}
              >
                {message}
              </p>
              <p
                style={{
                  textAlign: 'end',
                  color: colorGreyBorderAndFont,
                  fontWeight: 500,
                  fontSize: isMobile ? '12px' : '14px',
                }}
              >
                {moment.utc(date).format('DD/MM/YYYY')}
              </p>
            </Flex>
          </GenericCard>
        </div>
        <Flex
          style={{
            borderRadius: '10px 10px 0px 0px',
            background: hex2rgba(currentClassification?.color!, 0.6),
            padding: '20px 16px 4px 16px',
          }}
          vertical
        >
          <Flex gap={8} align="center">
            <Text size="md" color="white">
              {t('app.classificadaPorIA')}:
            </Text>
            <Flex
              style={{
                borderRadius: '4px',
                background: '#F5F5F5',
                padding: '4px 8px',
                width: 'fit-content',
              }}
              align="center"
              gap={8}
            >
              {React.createElement(currentClassification?.icon!, {
                size: 20,
                color: currentClassification?.color,
              })}

              <Text
                style={{ fontSize: '14px', fontWeight: 600 }}
                color={currentClassification?.color}
              >
                {currentClassification?.label}
              </Text>
            </Flex>
          </Flex>
          <Text size="md" color="white">
            {t('app.justificativa')}: {classificationJustification}
          </Text>
          <Flex
            gap={8}
            align="center"
            justify="center"
            style={{ cursor: 'pointer', marginBottom: '8px', marginTop: '8px' }}
            onClick={() =>
              handleReClass(id!, {
                onError: () => {
                  notificateError(t('app.erroAoSalvarAlteracoes'));
                  return;
                },
                onSuccess: () => {
                  queryClient.invalidateQueries(`/v2/safeSpace/${id}`);
                  notificateSucess(t('app.sucessReClass'));
                },
              })
            }
          >
            <RxReload color="white" size={24} />
            <Text
              size="md"
              style={{ textDecoration: 'underline' }}
              color="white"
            >
              {t('app.refazerClassificacaoPorIA')}
            </Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" style={{ padding: '16px 16px 0px 16px' }}>
          {actions.map((action) => (
            <Link
              to={action.value}
              smooth={true}
              duration={500}
              id={`id-tour-button-go-to-${action.label}`}
            >
              <Flex
                key={action.label}
                gap={4}
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={action.onClick}
              >
                <action.icon size={16} color={colorGreyBorderAndFont} />
                <Text size="sm" color={colorGreyBorderAndFont}>
                  {action.label}
                </Text>
              </Flex>
            </Link>
          ))}
        </Flex>
      </Flex>
    </GenericCard>
  );
}
