import { StoryForm } from "core/features/stories/typings";
import http from "infra/http";
import { useMutation } from "react-query";


export function useSaveStory() {
  return useMutation(
    (data: StoryForm) => {
      if (data.id) {
        return http.put(`/v2/stories/`, data);
      }
      return http.post(`/v2/stories`, data);
    },
    { retry: false }
  );
}
