import { useMediaQuery } from '@chakra-ui/media-query';
import { Col, Flex, Form, Radio, Row, Space, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import SearchBar from 'components/V2/SearchBar';
import UseCountGamesByResponsibleId from 'core/features/games/hooks/useCountGamesByResponsibleId';
import useGames from 'core/features/games/hooks/useGames';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import { FilterType } from 'infra/helpers/types';
import { useEffect, useMemo, useState } from 'react';
import GameCard from './GameCard';
import GameFilter from './GameFilter';
import Text from 'components/typography/text';
import LoadingDots from 'components/V2/loading-dots';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

function StepGame({
  value,
  onChange,
  setMaxQuestions,
  isAvaliation,
  setAllowNextStepAvaliation,
}: {
  value: string | null;
  onChange: (v: string | null) => void;
  setMaxQuestions: (v: number | undefined) => void;
  isAvaliation: boolean;
  setAllowNextStepAvaliation: (v: boolean) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [search, setSearch] = useState('');
  const { colorPrimary } = useU4heroColors();
  const [filters, setFilters] = useState<FilterType>({
    limits: [],
    classifications: [],
    age: [],
    durations: [],
    favorite: [],
  });
  const [avaliationFormat, setAvaliationFormat] = useState<
    'game' | 'questionary'
  >();

  const { data, isLoading } = useGames({
    page: 1,
    search,
    ageClassification: filters?.age?.map((item) => item.value)[0] ?? undefined,
    durations: filters?.durations?.map((item) => item.value) ?? undefined,
    questionLimits: filters?.limits?.map((item) => item.value) ?? undefined,
    favorites: filters.favorite.map((item) => item.value)[0] ?? undefined,
    disabled: avaliationFormat !== 'game',
  });
  const user = useCurrentUser();

  const form = Form.useFormInstance();
  const classes = Form.useWatch('classes', form);
  const students = Form.useWatch('students', form);
  const gameId = Form.useWatch('gameId', form);

  const { data: countGames } = UseCountGamesByResponsibleId({
    responsibleId: user?.user?.id,
    classes,
    students,
    enabled:
      (classes !== undefined && classes?.length !== 0) ||
      (students !== undefined && students.length !== 0),
  });

  useEffect(() => {
    if (data?.data && value) {
      setMaxQuestions(
        data?.data?.find((item) => item.id === value)?.maxQuestions
      );
    }
  }, [data]);

  if (data) {
    for (const game of data?.data) {
      game.count = countGames?.find((t) => t.gameId === game.id)?.count ?? 0;
    }
  }

  const filteredGames = useMemo(() => {
    if (!data || !data.data) return [];

    if (
      filters.classifications.map((item) => item.value).includes('used') &&
      filters.classifications.map((item) => item.value).includes('not-used')
    ) {
      return data.data;
    }

    return data.data.filter((game) => {
      if (filters.classifications.map((item) => item.value).includes('used')) {
        return game?.count && game.count > 0;
      } else if (
        filters.classifications.map((item) => item.value).includes('not-used')
      ) {
        return game.count === 0;
      }
      return true;
    });
  }, [data, filters.classifications]);

  useEffect(() => {
    if (!isAvaliation) {
      setAvaliationFormat('game');
    } else if (isAvaliation && !gameId) {
      setAvaliationFormat('questionary');
    } else if (isAvaliation && gameId) {
      setAvaliationFormat('game');
    }
  }, [isAvaliation, gameId]);

  useEffect(() => {
    if (avaliationFormat) {
      if (avaliationFormat === 'game' && !gameId) {
        setAllowNextStepAvaliation(false);
      } else {
        setAllowNextStepAvaliation(true);
      }
    }
  }, [avaliationFormat, gameId]);

  return (
    <Flex vertical gap="8px">
      {isAvaliation ? (
        <>
          <Text size="md">
            {t('app.comoVocêGostariaDeAplicarEstaAvaliacao')} *{' '}
          </Text>
          <Radio.Group
            onChange={(e) => {
              setAvaliationFormat(e.target.value);
              if (e.target.value === 'questionary') {
                form.setFieldValue('gameId', undefined);
              }
            }}
            value={avaliationFormat}
            style={{ marginBottom: '8px' }}
          >
            <Space direction="vertical">
              <Radio value={'game'}>{t('app.noFormatoDeJogo')}</Radio>
              <Radio value={'questionary'}>
                {t('app.noFormatoDeQuestionario')}
              </Radio>
            </Space>
          </Radio.Group>
        </>
      ) : null}
      {avaliationFormat === 'game' && (
        <>
          <p
            style={{
              fontSize: isMobile ? '14px' : '18px',
              fontWeight: 400,
              color: 'black',
            }}
          >
            {t('app.selecioneUmJogoParaATividade')}:
          </p>
          <Flex
            align="center"
            gap="16px"
            style={{ marginBottom: '16px', width: '100%' }}
          >
            <SearchBar width="100%" value={search} onSearch={setSearch} />

            <GameFilter filters={filters} onChangeFilters={setFilters} />
          </Flex>
          <AppliedFilters
            appliedFilters={filters}
            onChangeFilters={setFilters}
          />
          {isLoading ? (
            <Flex
              vertical
              justify="center"
              align="center"
              style={{ marginTop: '40px' }}
              gap={'8px'}
            >
              <LoadingDots />
              <Text size="md" color={colorPrimary}>
                {t('app.preparandoOCatalogoDeJogosParaVoce')}
              </Text>
            </Flex>
          ) : (
            <Row id="id-tour-game-step-row" gutter={[65, 16]} align="stretch">
              {filteredGames.map((item) => (
                <Col key={item.id} style={{ width: isMobile ? '100%' : '20%' }}>
                  <GameCard
                    item={item}
                    isSelected={value === item.id}
                    onSelect={() => {
                      if (value === item.id) {
                        onChange(null);
                        setMaxQuestions(0);
                      } else {
                        onChange(item.id);
                        setMaxQuestions(item.maxQuestions);
                      }
                    }}
                  />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
    </Flex>
  );
}

export default StepGame;
