import { Flex } from 'antd';
import bg from 'assets/images/report/report-bg.png';
import bgWl from 'assets/images/white-label/report-bg.png';
import waveBottom from 'assets/images/report/wave-bottom.png';
import waveTop from 'assets/images/report/wave-top.png';
import lumi from 'assets/images/report/lumi-leaning.png';
import logo from 'assets/images/logo.png';
import styled from 'styled-components';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';
import brainOutline from 'assets/images/white-label/brain-outline.png';

const CoverContainer = styled(Flex)<{ isWhiteLabel: boolean }>`
  position: relative;
  width: 100%;
  height: 100vh;
  background-image: url(${({ isWhiteLabel }) => (isWhiteLabel ? bgWl : bg)});
  background-size: cover;
  background-position: center;

  @media print {
    width: 210mm;
    height: 297mm;
    margin: 0;
    page-break-after: always;
  }
`;

const WaveTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 230px;
  height: 216px;
  background-image: url(${waveTop});
  background-size: cover;
`;

const WaveBottom = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 252px;
  height: 151px;
  background-image: url(${waveBottom});
  background-size: cover;
`;

interface CoverProps {
  modelType: 'simplified' | 'complete';
  className?: string;
  period?: string;
  studentName?: string;
  year: number;
  reportType: 'avaliation' | 'activity' | 'initialAvaliation';
}

export default function ReportCover({
  modelType,
  className,
  period,
  studentName,
  reportType,
  year,
}: CoverProps) {
  const { user } = useCurrentUser();

  const modelText =
    modelType === 'simplified'
      ? t('app.modeloSimplificado')
      : t('app.modeloCompleto');
  const reportText =
    reportType === 'avaliation'
      ? t('app.relatorioDeAvaliacao')
      : reportType === 'activity'
      ? t('app.relatorioDeAtividade')
      : t('app.relatorioAvaliacaoInicial');

  return (
    <CoverContainer
      vertical
      align="center"
      justify="center"
      isWhiteLabel={!!user?.whiteLabel}
    >
      <WaveTop>
        <img
          src={logo}
          alt="Logo"
          width="122px"
          style={{ position: 'absolute', top: 18, right: 16 }}
        />
      </WaveTop>

      <Title
        color="white"
        style={{
          position: 'absolute',
          top: 40,
          left: 40,
        }}
      >
        {year}
      </Title>

      <Text
        size="lg"
        color="white"
        style={{
          position: 'absolute',
          left: 0,
          top: 200,
          transform: 'rotate(-90deg) translateX(-50%)',
          transformOrigin: '0 0',
          whiteSpace: 'nowrap',
        }}
      >
        {modelText}
      </Text>

      <Flex
        vertical
        style={{ position: 'relative' }}
        align="center"
        justify="center"
      >
        <img
          src={user?.whiteLabel ? brainOutline : lumi}
          width={user?.whiteLabel ? '281px' : '168px'}
          style={{
            position: 'relative',
            bottom: user?.whiteLabel ? -80 : -30,
            right: user?.whiteLabel ? -270 : undefined,
            zIndex: user?.whiteLabel ? 16 : 10,
          }}
        />
        <Title
          color="white"
          align="center"
          style={{
            padding: '32px 27px',
            borderRadius: '50px',
            background: user?.whiteLabel ? '#434343' : '#532A88',
            fontSize: '68px',
            textTransform: 'uppercase',
            zIndex: 15,
          }}
        >
          {reportText}
        </Title>
      </Flex>

      <Flex
        style={{
          position: 'absolute',
          bottom: 80,
          width: '100%',
        }}
        vertical
      >
        <Text align="center" size="lg" color="white">
          {user?.tenantName}
        </Text>

        {className && (
          <Text align="center" size="lg" color="white">
            {className} - {period}
          </Text>
        )}

        {studentName && (
          <Text align="center" size="lg" color="white">
            {studentName}
          </Text>
        )}
      </Flex>
      <WaveBottom>
        <img
          src={user?.tenantImage!}
          alt="Logo"
          width="80px"
          style={{ position: 'absolute', bottom: 12, left: 24 }}
        />
      </WaveBottom>
    </CoverContainer>
  );
}
