export interface CompetenceDetails {
  id: string;
  name: {
    en: string;
    es: string;
    'pt-br': string;
  };
  parentId: string | null;
  createdAt: string;
  updatedAt: string;
  icon: string;
  type: number;
  parent?: {
    id: string;
    name: {
      en: string;
      es: string;
      'pt-br': string;
    };
  };
  _name: string;
  active: boolean;
}

export interface TagForm {
  id?: string;
  name: {
    en: string;
    es: string;
    'pt-br': string;
  };
  parentId: string | null;
  color?: string;
  type: TagType;
  description: {
    en: string;
    es: string;
    'pt-br': string;
  };
  adaptativeOrder?: number;
  active?: boolean;
}

export enum TagType {
  Competências = 0,
  'Clima' = 1,
  Treinamento = 2,
  Pesquisa = 3,
}
