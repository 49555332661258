import { CiHeart } from 'react-icons/ci';
import { LiaBombSolid } from 'react-icons/lia';
import { PiHeadphonesLight, PiWarningDiamond } from 'react-icons/pi';
import { GoUnverified } from 'react-icons/go';
import EyesIcon from 'components/icons/EyesIcon';
import { t } from 'core/resources/strings';
import brFlag from 'assets/images/flags/br.svg';
import esFlag from 'assets/images/flags/es.svg';
import usFlag from 'assets/images/flags/us.svg';
import heart from 'assets/images/safespace/heart.png';
import eyes from 'assets/images/safespace/eyes.png';
import attention from 'assets/images/safespace/attention.png';
import problem from 'assets/images/safespace/serious-problem.png';
import notClassified from 'assets/images/safespace/not-classified.png';
import technical from 'assets/images/Lumi_com_microfone.png';


export interface FilterType {
  [key: string]: {
    value: any;
    label: string;
  }[];
}
export interface FilterProps {
  onChangeFilters: (value: any) => void;
  filters: {
    [key: string]: {
      value: any;
      label: string;
    }[];
  };
}


export const safeSpaceFlags = [
  {
    label: t('app.naoClassificado'),
    bg: '#F5F5F5',
    color: '#7B7B7B',
    icon: GoUnverified,
    value: 0,
    shortDescription: t('app.safeSpaceFlag0ShortDescription'),
    description: t('app.safeSpaceFlag0Description'),
    image: notClassified,
  },
  {
    label: t('app.coracaoQuentinho'),
    bg: '#CEEACF',
    color: '#336732',
    icon: CiHeart,
    value: 1,
    shortDescription: t('app.safeSpaceFlag1ShortDescription'),
    description: t('app.safeSpaceFlag1Description'),
    image: heart,
  },
  {
    label: t('app.bomFicarDeOlho'),
    bg: '#B2EBFE',
    color: '#0067AA',
    icon: EyesIcon,
    value: 2,
    shortDescription: t('app.safeSpaceFlag2ShortDescription'),
    description: t('app.safeSpaceFlag2Description'),
    image: eyes,
  },
  {
    label: t('app.redobreAtencao'),
    bg: '#FFE4A9',
    color: '#8A4C00',
    icon: PiWarningDiamond,
    value: 3,
    shortDescription: t('app.safeSpaceFlag3ShortDescription'),
    description: t('app.safeSpaceFlag3Description'),
    image: attention,
  },
  {
    label: t('app.problemaSerio'),
    bg: '#FFCDD3',
    color: '#BB191E',
    icon: LiaBombSolid,
    value: 4,
    shortDescription: t('app.safeSpaceFlag4ShortDescription'),
    description: t('app.safeSpaceFlag4Description'),
    image: problem,
  },
  {
    label: t('app.suporte'),
    bg: '#EFE8F6',
    color: '#452392',
    icon: PiHeadphonesLight,
    value: 5,
    shortDescription: t('app.safeSpaceFlag5ShortDescription'),
    description: t('app.safeSpaceFlag5Description'),
    image: technical,
  },
];

export const languages = [
  {
    name: t('app.portugues'),
    flag: brFlag,
    value: 'pt-br',
  },
  {
    name: t('app.ingles'),
    flag: usFlag,
    value: 'en',
  },
  {
    name: t('app.espanhol'),
    flag: esFlag,
    value: 'es',
  },
];

export const storeItemTypes = [
  {
    label: 'Ícone',
    value: 'icon',
  },
  {
    label: 'Moldura',
    value: 'frame',
  },
  {
    label: 'Fundo',
    value: 'background',
  },
  {
    label: 'Personagem',
    value: 'character',
  },
  {
    label: 'Cenário',
    value: 'scenario',
  },
  {
    label: 'Objeto',
    value: 'object',
  },
]

export interface ComparativeItem {
  type: 'student' | 'class';
  id: string;
  name: string;
}

export enum ContentType {
  DIALOGUE = 0,
  DECISION = 1,
  REINFORCEMENT = 2,
  END = 3,
}

export const contentTypes: {
  label: string;
  value: ContentType;
}[] = [
  {
    label: 'Diálogo/Narração',
    value: 0,
  },
  {
    label: 'Pergunta com Decisão',
    value: 1,
  },
  {
    label: 'Pergunta de Reforço',
    value: 2,
  },
  {
    label: 'Final da História',
    value: 3,
  },
];


export type SoundEffect = {
  id: number;
  audioUrl: string;
  triggerTime: 'start' | 'end';
};
