import { Row, Col, Image, Input, Tooltip } from 'antd';
import Button from 'components/buttons/button';
import { useState } from 'react';
import { PiImage, PiMagnifyingGlassPlus, PiTrash } from 'react-icons/pi';
import { EyeOutlined } from '@ant-design/icons';

function GenerateImage({
  value,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
}) {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const generateImage = async () => {
    // todo: generate image
  };

  const removeImage = () => {
    onChange('');
  };

  return (
    <Row
      gutter={[
        { xs: 0, md: 16 },
        { xs: 16, md: 0 },
      ]}
      style={{ width: '100%', height: '100%' }}
    >
      <Col xs={24} md={8}>
        <div
          style={{
            width: '100%',
            height: '100%',
            minHeight: '100px',
            borderRadius: 8,
            border: '0.5px dashed #262626',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          {value ? (
            <>
              <Image
                src={value}
                style={{
                  maxWidth: '100px',
                  maxHeight: '100px',
                  objectFit: 'contain',
                }}
                preview={{
                  mask: (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <EyeOutlined style={{ fontSize: 24 }} />
                      <PiTrash
                        size={24}
                        color="white"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeImage();
                        }}
                      />
                    </div>
                  ),
                  movable: false,
                }}
              />
            </>
          ) : (
            <PiImage size={32} color={'black'} />
          )}
        </div>
      </Col>
      <Col xs={24} md={16}>
        <div
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <Input.TextArea
            placeholder="Imagine e descreva."
            rows={2}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            style={{ flex: 1, marginBottom: 12, resize: 'none' }}
          />
          <Button
            size="md"
            variant="outline"
            onClick={generateImage}
            loading={isLoading}
            disabled={true}
          >
            Gerar
          </Button>
        </div>
      </Col>
    </Row>
  );
}

export default GenerateImage;
