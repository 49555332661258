import { Flex, Form } from 'antd';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useMediaQuery } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import { useEffect, useState } from 'react';
import {
  notificateSucess,
  openNotification,
  notificateError,
} from 'infra/helpers/notifications';
import { useSaveQuestion } from 'core/features/questions/hooks/useSaveQuestion';
import { useCurrentUser } from 'core/features/user/store';
import useQuestion from 'core/features/questions/hooks/useQuestion';
import {
  OptionsDetails,
  QuestionForm,
} from 'core/features/questions/types/QuestionsDetails';
import { Permissions } from 'core/resources/enums/permissions';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import BackButton from 'components/buttons/back-button';
import Button from 'components/buttons/button';
import Title from 'components/typography/title';
import CustomizedRequiredMark from 'components/required-mark';
import AnswerFormat from '../../components/answerFormat';
import AnalysisArea from '../../components/analysisArea';
import QuestionDetails from '../../components/questionDetails';
import AdditionalInformation from '../../components/additionalInfomration';
import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';

function CreateQuestion() {
  const { colorPrimary } = useU4heroColors();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [form] = Form.useForm();
  const [check] = useCheckPermissions();
  const { user } = useCurrentUser();
  const { id } = useParams<{ id: string }>();
  const { data: questionInfo } = useQuestion(id);
  const { mutate: handleSave, isLoading: isLoadingSave } = useSaveQuestion();
  const history = useHistory();
  const [hasTwoCompetences, setHasTwoCompetences] = useState(false);
  const isU4hero = check([Permissions.U4hero.Questions.Create]);

  useEffect(() => {
    form.resetFields();
  }, [questionInfo, form]);

  const handleOk = () => {
    history.push('/question');
  };

  const onSubmit = (values: QuestionForm) => {
    delete values.ageRange;
    if (id && !form.isFieldsTouched()) {
      notificateError(t('app.mensagemErroSalvarSemAlteracoes'));
      return;
    }
    if (!isOptionsValid(values.options)) {
      notificateError(t('app.mensagemValidacaoAlternativas'));
      return;
    }
    handleSave(
      {
        ...values,
        id,
        tenantId: user?.tenantId,
        authorId: user?.id,
      },
      {
        onError: () => {
          notificateError(
            id ? t('app.erroAoSalvarAlteracoes') : t('app.erroAoCriarPergunta')
          );
        },
        onSuccess: () => {
          notificateSucess(
            id
              ? t('app.alteracaoConcluidaComSucesso')
              : t('app.perguntaCriadaComSucesso')
          );
          handleOk();
        },
      }
    );
  };

  const isOptionsValid = (options: OptionsDetails[]) => {
    let has100 = false;
    let has0 = false;

    for (let option of options) {
      if (option.value === 100) {
        has100 = true;
      }
      if (option.value === 0) {
        has0 = true;
      }
      // If both values are found, we can short-circuit and return true
      if (has100 && has0) {
        return true;
      }
    }

    return has100 && has0;
  };

  const defaultOptions = [
    {
      id: undefined,
      text: undefined,
      orientation: undefined,
      hypothesis: undefined,
      feedback: undefined,
      value: 0,
    },
    {
      id: undefined,
      text: undefined,
      orientation: undefined,
      hypothesis: undefined,
      feedback: undefined,
      value: 50,
    },
    {
      id: undefined,
      text: undefined,
      orientation: undefined,
      hypothesis: undefined,
      feedback: undefined,
      value: 100,
    },
  ];

  const handleBack = () => {
    if (form.isFieldsTouched()) {
      openNotification({ onClose: handleOk, colorPrimary, isMobile });
    } else {
      handleOk();
    }
  };

  return (
    <Layout.Container>
      <Form
        onFinish={onSubmit}
        layout="vertical"
        form={form}
        initialValues={{
          competence: questionInfo
            ? {
                id: questionInfo.competence.id,
                name: questionInfo.competence.name,
              }
            : undefined,
          skill: questionInfo
            ? {
                id: questionInfo.skill.id,
                name: questionInfo.skill.name,
              }
            : undefined,
          analysisTypeId: questionInfo ? questionInfo?.analysisTypeId : 1,
          methodology: questionInfo ? questionInfo.methodology : undefined,
          tagType: questionInfo ? questionInfo.tagType : undefined,
          likertType: questionInfo ? questionInfo?.likertType : undefined,
          analysisEnvironmentId: questionInfo
            ? questionInfo.analysisEnvironmentId
            : 1,
          minAge: questionInfo?.minAge ?? 5,
          maxAge: questionInfo?.maxAge ?? 10,
          ageRange:
            questionInfo?.minAge && questionInfo?.maxAge
              ? [questionInfo.minAge, questionInfo.maxAge]
              : [5, 10],
          text: questionInfo?.text ?? undefined,
          image: questionInfo?.image ?? undefined,
          audio: questionInfo?.audio ?? undefined,
          options: questionInfo ? questionInfo.options : defaultOptions,
          typeId: questionInfo ? questionInfo?.typeId : '',
          privacyId: questionInfo ? questionInfo?.privacyId : isU4hero ? 2 : 0,
          locale: questionInfo ? questionInfo?.locale : currentLocale,
        }}
        requiredMark={CustomizedRequiredMark}
      >
        <Flex gap="23px" style={{ marginBottom: '16px' }}>
          <BackButton onClick={handleBack} />
          <Title primary>
            {id
              ? `${t('app.editar')} ${t('app.pergunta')}`
              : `${t('app.criar')} ${t('app.pergunta')}`}
          </Title>
        </Flex>
        <Flex vertical gap="24px">
          <AnswerFormat
            onChangeCustom={() => form.setFieldValue('options', defaultOptions)}
          />
          <AnalysisArea
            isEditing={questionInfo?.id !== undefined}
            hasTwoCompetences={hasTwoCompetences}
            setHasTwoCompetences={setHasTwoCompetences}
          />
          <QuestionDetails />
          <AdditionalInformation hasTwoCompetences={hasTwoCompetences} />

          <Flex
            gap="8px"
            style={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '43px',
              marginTop: '16px',
            }}
          >
            <Button
              variant="outline"
              size={'md'}
              id="id-tour-create-question-button-cancel"
              onClick={() => handleBack()}
            >
              {t('app.cancelar')}
            </Button>

            <Button
              htmlType="submit"
              size="md"
              id="id-tour-create-question-button-save"
              loading={isLoadingSave}
            >
              {t('app.salvar')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Layout.Container>
  );
}

export default CreateQuestion;
