import { Flex } from 'antd';
import { ApexOptions } from 'apexcharts';
import SadIcon from 'components/icons/SadIcon';
import ReactApexChart from 'react-apexcharts';

function DonutGraph({
  categories,
  series,
  colors,
}: {
  categories: string[];
  series: ApexAxisChartSeries | number[];
  colors?: string[];
}) {
  const options: ApexOptions = {
    chart: {
      type: 'donut',
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    labels: categories,
    colors: colors,
    legend: {
      show: false,
    },
  };

  let totalCount = 0;
  if (Array.isArray(series)) {
    //@ts-ignore
    totalCount = series?.reduce(
      (total: number, item: number) => total + item,
      0
    );
  }
  if (totalCount === 0) {
    return (
      <Flex
        vertical
        align="center"
        justify="center"
        gap="16px"
        style={{ height: '100%' }}
      >
        <SadIcon color="#7B7B7B" size={60} />
        <p
          style={{
            fontSize: '14px',
            color: '#7B7B7B',
            textAlign: 'center',
            fontWeight: 400,
            maxWidth: '267px',
          }}
        >
          Não há dados para exibir
        </p>
      </Flex>
    );
  }

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height={380}
    />
  );
}

export default DonutGraph;
