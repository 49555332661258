import { useMediaQuery } from '@chakra-ui/react';
import { Col, Form, Row } from 'antd';
import SelectSerie from 'components/V2/selects/select-serie';
import SelectClass from 'components/V2/selects/select-class';
import SelectStudent from 'components/V2/selects/select-student';
import { t } from 'core/resources/strings';

function InnerSourceInput({
  index,
  updateForm,
}: {
  index: number;
  updateForm: (
    fieldName: string | number | (string | number)[],
    value: any
  ) => void;
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const form = Form.useFormInstance();
  const graph = Form.useWatch(['graphs', index], form);

  return (
    <>
      {graph?.source === 'serie' ||
        graph?.source === 'class' ||
        graph?.source === 'student' ? (
        <Row
          gutter={
            isMobile
              ? [0, 0]
              : [
                graph?.source === 'class'
                  ? 40
                  : graph?.source === 'student'
                    ? 32
                    : 0,
                0,
              ]
          }
        >
          <Col
            span={
              isMobile
                ? 24
                : graph?.source === 'serie'
                  ? 24
                  : graph?.source === 'class'
                    ? 12
                    : 8
            }
          >
            <Form.Item
              label={t('app.serie')}
              rules={[
                {
                  required: true,
                  message: t('app.mensagemDeErroSerie'),
                },
              ]}
              name={[index, 'serieId']}
            >
              {/* @ts-ignore */}
              <SelectSerie
                id="id-tour-indicators-custom-panel-serie-input"
                cleanDependentFields={() => {
                  updateForm(['graphs', index, 'studentId'], undefined);
                  updateForm(['graphs', index, 'classId'], undefined);
                }}
              />
            </Form.Item>
          </Col>

          {graph?.source === 'class' || graph?.source === 'student' ? (
            <Col span={isMobile ? 24 : graph?.source === 'class' ? 12 : 8}>
              <Form.Item
                label={t('app.turma')}
                rules={[
                  {
                    required: true,
                    message: t('app.mensagemErroTurma'),
                  },
                ]}
                required
                name={[index, 'classId']}
              >
                {/* @ts-ignore */}
                <SelectClass
                  id="id-tour-indicators-custom-panel-input-class"
                  serieId={graph?.serieId}
                  cleanDependentFields={() => {
                    updateForm(['graphs', index, 'studentId'], undefined);
                  }}
                />
              </Form.Item>
            </Col>
          ) : null}
          {graph?.source === 'student' ? (
            <Col span={isMobile ? 24 : 8}>
              <Form.Item
                label={t('app.estudante').slice(0, 1).toUpperCase() + t('app.estudante').slice(1)}
                required
                rules={[
                  {
                    required: true,
                    message: t('app.mensagemErroSelecioneEstudante'),
                  },
                ]}
                name={[index, 'studentId']}
              >
                {/* @ts-ignore */}
                <SelectStudent
                  id="id-tour-indicators-custom-panel-input-student"
                  classId={graph?.classId}
                />
              </Form.Item>
            </Col>
          ) : null}
        </Row>
      ) : null}
    </>
  );
}

export default InnerSourceInput;
