import { useMediaQuery } from '@chakra-ui/react';
import DynamicTable from 'components/V2/dinamic-table';
import useCheckPermissions from 'core/features/user/hooks/useCheckPermissions';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Permissions } from 'core/resources/enums/permissions';
import { Dropdown, Flex, Menu, Tooltip } from 'antd';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';
import { FaEllipsisVertical } from 'react-icons/fa6';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { QuestionsDetails } from 'core/features/questions/types/QuestionsDetails';
import useQuestionTypes from 'core/features/questions/hooks/useQuestionTypes';
import {
  notificateSucess,
  notificateError,
  showConfirm,
} from 'infra/helpers/notifications';
import Status from 'components/status';
import Tag from '../Tag';
import useQuestions from 'core/features/questions/hooks/useQuestions';
import { useToggleQuestion } from 'core/features/questions/hooks/useToggleQuestion';
import HistoricModal from '../historic/historicModal';
import ViewModal from '../viewMode/viewModal';
import { FilterType } from 'infra/helpers/types';
import QuestionFilter from 'ui/pages/app/activities/pages/CreateActivity/components/StepQuestion/QuestionFilter';
import Button from 'components/buttons/button';
import { useCurrentUser } from 'core/features/user/store';
import QuestionsFeedbackModal from '../feedback/questionsFeedbackModal';
import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';

function QuestionsTable() {
  const history = useHistory();

  const [check] = useCheckPermissions();
  if (
    !check([Permissions.CustomQuestion.View, Permissions.U4hero.Questions.View])
  ) {
    history.push('/errors/no-permission');
  }

  const isU4hero = check([Permissions.U4hero.Questions.View]);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const { mutate: handleToggle } = useToggleQuestion();
  const questionTypes = useQuestionTypes();
  const { colorPrimary } = useU4heroColors();
  const [questionId, setQuestionId] = useState('');
  const [filters, setFilters] = useState<FilterType>({
    analysisTypes: [],
    environments: [],
    scopes: [],
    questionTypes: [],
    tags: [],
    age: [],
  });

  const { data, isLoading, isFetching, refetch } = useQuestions({
    page,
    search,
    tags: filters.tags.map((item) => item.value) ?? undefined,
    types: filters.questionTypes.map((item) => item.value) ?? undefined,
    analysisTypes: filters.analysisTypes.map((item) => item.value) ?? undefined,
    environments: filters.environments.map((item) => item.value) ?? undefined,
    minAge: filters.age.find((item) => item.label === 'Idade Mínima')?.value,
    maxAge: filters.age.find((item) => item.label === 'Idade Máxima')?.value,
    scope: filters.scopes.map((item) => item.value) ?? undefined,
    limit: pageSize,
    locale: isU4hero ? undefined : currentLocale,
  });

  const isEachLoading = isLoading || isFetching;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHistoricModalOpen, setIsHistoricModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const { user } = useCurrentUser();

  const checkMessageLength = (message: string) => {
    if (message?.length > 200) {
      const messageTruncated = message.substring(0, 197) + '...';
      return messageTruncated;
    }

    return message;
  };

  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const tableHeaderStyle = {
    fontSize: isMobile ? '14px' : '18px',
    fontWeight: 400,
  };
  const tableRowStyle = {
    fontSize: isMobile ? '14px' : '16px',
    fontWeight: 400,
  };

  const columns = [
    {
      title: t('app.enunciado'),
      name: t('app.enunciado'),
      dataIndex: 'statement',
      key: 'statement',
      mobile: true,
      render: (_: string, record: QuestionsDetails) => (
        <p style={{ fontSize: isMobile ? '14px' : '16px', fontWeight: 400 }}>
          {checkMessageLength(record.text)}
        </p>
      ),
      sorter: (a: any, b: any) => a.text.localeCompare(b.text),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: (
        <p style={tableHeaderStyle}>
          {t('app.habilidadesCamposDeConhecimento')}
        </p>
      ),
      name: t('app.habilidadesCamposDeConhecimento'),
      dataIndex: 'skills',
      key: 'skills',
      mobile: true,
      render: (_: string, record: QuestionsDetails) => (
        <Flex vertical>
          <p style={tableRowStyle}>{record.skill.name}</p>
          <Tag competence={record.competence.name} />
        </Flex>
      ),
      sorter: (a: any, b: any) => a.skill.name.localeCompare(b.skill.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.faixaEtaria')}</p>,
      name: t('app.faixaEtaria'),
      dataIndex: 'ageRange',
      key: 'ageRange',
      render: (_: string, record: QuestionsDetails) => (
        <p style={tableRowStyle}>{`${record.minAge}-${record.maxAge}`}</p>
      ),
      sorter: (a: any, b: any) => a.maxAge - a.minAge - (b.maxAge - b.minAge),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.tipo')}</p>,
      name: t('app.tipo'),
      dataIndex: 'type',
      key: 'type',
      render: (_: string, record: QuestionsDetails) => (
        <p style={tableRowStyle}>
          {questionTypes.data && questionTypes.data[record.typeId].name}
        </p>
      ),
      sorter: (a: any, b: any) =>
        questionTypes.data &&
        questionTypes.data[a.typeId].name.localeCompare(
          questionTypes.data[b.typeId].name
        ),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.escopo')}</p>,
      name: t('app.escopo'),
      dataIndex: 'scope',
      key: 'scope',
      extra: true,
      render: (_: string, record: QuestionsDetails) => (
        <p style={tableRowStyle}>
          {record.privacyId === 0 ? (
            <Tooltip title={t('app.tooltipPrivada')}>Privada</Tooltip>
          ) : null}
          {record.privacyId === 1 ? (
            <Tooltip title={t('app.tooltipPublica')}>Pública</Tooltip>
          ) : null}
          {record.privacyId === 2 ? (
            <Tooltip title={t('app.tooltipGlobal')}>Global</Tooltip>
          ) : null}
        </p>
      ),
      sorter: (a: any, b: any) => a.privacyId - b.privacyId,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.autor')}</p>,
      name: t('app.autor'),
      dataIndex: 'author',
      key: 'author',
      extra: true,
      render: (_: string, record: QuestionsDetails) => (
        <p style={tableRowStyle}>{record.author.name}</p>
      ),
      sorter: (a: any, b: any) => a.author.name.localeCompare(b.author.name),
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: <p style={tableHeaderStyle}>{t('app.status')}</p>,
      name: t('app.status'),
      dataIndex: 'status',
      key: 'status',
      extra: true,
      render: (_: string, record: QuestionsDetails) => (
        <div>
          {record.active ? (
            <Status type={'active'} />
          ) : (
            <Status type={'inactive'} />
          )}
        </div>
      ),
      sorter: (a: any, b: any) => a.active - b.active,
      sortIcon: (sortOrder: any) =>
        sortOrder.sortOrder === 'descend' ? (
          <MdExpandLess size={25} />
        ) : (
          <MdExpandMore size={25} />
        ),
    },
    {
      title: t('app.acoes'),
      key: 'action',
      mobile: true,
      dataIndex: 'action',
      name: t('app.acoes'),
      render: (_: string, record: QuestionsDetails) => (
        <Dropdown
          overlay={
            <Menu
              onClick={(event) => {
                if (event.key === 'edit') {
                  history.push(`/question/edit/${record.id}`);
                }
                if (event.key === 'toggle') {
                  showConfirm({
                    message: `${t('app.temCertezaQueDeseja')} ${
                      record.active ? t('app.desativar') : t('app.ativar')
                    } ${t('app.aPergunta')}?`,
                    colorPrimary,
                    isMobile,
                  }).then(({ isConfirmed }) => {
                    if (isConfirmed) {
                      if (
                        check([
                          Permissions.CustomQuestion.Activate,
                          Permissions.U4hero.Questions.Activate,
                        ])
                      ) {
                        handleToggle(
                          {
                            id: record.id,
                            status: record.active,
                          },
                          {
                            onSuccess: () => {
                              notificateSucess(
                                `${t('app.aPerguntaFoi')} ${
                                  record.active
                                    ? t('app.desativada')
                                    : t('app.ativada')
                                } ${t('app.comSucesso')}`
                              );
                              refetch();
                            },
                            onError: () =>
                              notificateError(
                                `${t('app.houveUmProblemaAo')} ${
                                  record.active
                                    ? t('app.desativar')
                                    : t('app.ativar')
                                } ${t('app.aPergunta')}. ${t(
                                  'app.verifiqueSuaConexaoETenteNovamente'
                                )}`
                              ),
                          }
                        );
                      } else {
                        notificateError(
                          `${t(
                            'app.voceNaoTemPermissaoParaAtivarEstaPergunta'
                          )}`
                        );
                      }
                    }
                  });
                }
                if (event.key === 'historic') {
                  setQuestionId(record.id);
                  setIsHistoricModalOpen(true);
                }
                if (event.key === 'feedback') {
                  setQuestionId(record.id);
                  setIsModalOpen(true);
                }
                if (event.key === 'view') {
                  setQuestionId(record.id);
                  setIsViewModalOpen(true);
                }
              }}
            >
              <Menu.Item
                key="edit"
                disabled={
                  (record.privacyId === 2 &&
                    !check([Permissions.U4hero.Questions.Edit])) ||
                  (record.author.id !== user?.id &&
                    !check([Permissions.CustomQuestion.EditAll]) &&
                    record.privacyId !== 2) ||
                  !check([
                    Permissions.CustomQuestion.Edit,
                    Permissions.U4hero.Questions.Edit,
                  ])
                }
              >
                <p>{t('app.editar')}</p>
              </Menu.Item>
              <Menu.Item
                key={'toggle'}
                disabled={
                  (record.privacyId === 2 &&
                    !check([Permissions.U4hero.Questions.Activate])) ||
                  (record.author.id !== user?.id &&
                    !check([Permissions.CustomQuestion.EditAll]) &&
                    record.privacyId !== 2) ||
                  !check([
                    Permissions.CustomQuestion.Activate,
                    Permissions.U4hero.Questions.Activate,
                  ])
                }
              >
                <p>{record.active ? t('app.desativar') : t('app.ativar')}</p>
              </Menu.Item>
              <Menu.Item key="historic">
                <p>{t('app.historico')}</p>
              </Menu.Item>
              <Menu.Item
                key="feedback"
                hidden={!check([Permissions.U4hero.Questions.View])}
              >
                <p>{t('app.feedback')}</p>
              </Menu.Item>
              <Menu.Item key="view">
                <p>{t('app.visualizar')}</p>
              </Menu.Item>
            </Menu>
          }
          trigger={['click']}
        >
          <FaEllipsisVertical
            size={24}
            style={{ cursor: 'pointer' }}
            id="id-tour-action-class-table"
          />
        </Dropdown>
      ),
    },
  ];

  return (
    <Flex gap={8} vertical>
      <Flex
        justify={'flex-end'}
        gap={isMobile ? 8 : 16}
        vertical={isMobile}
        align={'center'}
      >
        {isU4hero && (
          <Button
            id="id-tour-create-question-button-generate-with-ai"
            size={'md'}
            onClick={() => history.push('/question/create-with-ai')}
            style={{
              alignSelf: 'end',
            }}
            variant={'outline'}
          >
            {t('app.gerarPerguntaComIA')}
          </Button>
        )}

        <Button
          id="id-tour-create-question-button-add-class"
          size={'md'}
          onClick={() => history.push('/question/create')}
          style={{
            alignSelf: 'end',
          }}
        >
          {t('app.criar')} {t('app.pergunta')}
        </Button>
      </Flex>

      {isModalOpen ? (
        <QuestionsFeedbackModal
          id={questionId}
          onClose={() => setIsModalOpen(false)}
        />
      ) : null}
      {isHistoricModalOpen ? (
        <HistoricModal
          id={questionId}
          onClose={() => setIsHistoricModalOpen(false)}
        />
      ) : null}

      {isViewModalOpen ? (
        <ViewModal id={questionId} onClose={() => setIsViewModalOpen(false)} />
      ) : null}

      <DynamicTable
        id="id-tour-my-school-selection-questions-table"
        itemName={t('app.pergunta').toLowerCase()}
        data={data?.data ?? []}
        onChangePage={setPage}
        onChangePageSize={setPageSize}
        title=""
        total={data?.total ?? 0}
        page={page}
        pageSize={pageSize}
        onChangeFilters={setFilters}
        appliedFilters={filters}
        filters={
          <QuestionFilter filters={filters} onChangeFilters={setFilters} />
        }
        isLoading={isEachLoading}
        defaultColumns={
          isMobile
            ? columns.filter((column) => column.mobile)
            : columns.filter((column) => !column.extra)
        }
        allColumns={columns}
        onSearch={setSearch}
      />
    </Flex>
  );
}

export default QuestionsTable;
