import { ReactNode } from 'react';
import { Typography } from 'antd';
import { TypographyProps } from 'antd/es/typography/Typography';
import { useMediaQuery } from '@chakra-ui/media-query';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';

const { Title: AntdTitle } = Typography;

interface GenericTitleProps extends TypographyProps<'div'> {
  children: ReactNode;
  primary?: boolean;
  align?: 'left' | 'center' | 'right' | 'justify' | 'end';
  color?: string;
}

function SubTitle({
  children,
  primary,
  align,
  color,
  ...rest
}: GenericTitleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();

  const titleStyle = {
    margin: rest.style?.margin ?? '0px',
    ...rest.style,
    color: color ? color : primary ? colorPrimary : rest.style?.color,
    fontSize: rest.style?.fontSize
      ? rest.style.fontSize
      : isMobile
      ? '18px'
      : '24px',
    fontWeight: 700,
    textAlign: align,
  };

  return (
    <AntdTitle {...rest} style={titleStyle} level={2}>
      {children}
    </AntdTitle>
  );
}

export default SubTitle;
