import { Flex } from 'antd';
import Button from 'components/buttons/button';
import InstitutionHeader from 'components/institution-header';
import Layout from 'components/layout';
import Title from 'components/typography/title';
import { useHistory, useParams } from 'react-router-dom';
import ProfilesTable from './components/ProfilesTable';
import HeaderImage from 'assets/images/profiles-header-image.png';
import HeaderImageWl from 'assets/images/white-label/profiles.png';
import BackButton from 'components/buttons/back-button';
import { useMediaQuery } from '@chakra-ui/react';
import { useTenant } from 'core/features/tenants/hooks/useTenant';
import { useCurrentUser } from 'core/features/user/store';
import { t } from 'core/resources/strings';

function Profiles() {
  const history = useHistory();
  const { tenantId } = useParams<{ tenantId: string }>();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { data: tenant } = useTenant(tenantId);
  const { user } = useCurrentUser();

  return (
    <Layout.Container>
      <Flex gap={isMobile ? '12px' : '24px'} align="center">
        <BackButton
          onClick={() => {
            if (tenant) {
              history.push(`/schools?tab=${tenant?.master ? 1 : 2}`);
            } else {
              history.push('/my-school');
            }
          }}
        />
        <Title primary>{t('app.gerenciarPerfis')}</Title>
      </Flex>
      {tenant ? (
        <InstitutionHeader
          image={user?.whiteLabel ? HeaderImageWl : HeaderImage}
          tenantName={tenant.name}
        />
      ) : null}
      <Flex style={{ marginTop: '16px', marginBottom: '17px' }} justify="end">
        <Button
          id="id-tour-create-profile-button-add-profile"
          size="md"
          onClick={() => {
            if (tenantId) {
              history.push(`/schools/${tenantId}/profiles/create`);
            } else {
              history.push('/my-school/profiles/create');
            }
          }}
        >
          {t('app.criar')} {t('app.perfil')}
        </Button>
      </Flex>

      <ProfilesTable />
    </Layout.Container>
  );
}

export default Profiles;
