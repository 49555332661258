import { useQuery } from "react-query";
import axios from "infra/http";
import { QuestionDto } from "../types";
import { useEffect, useState } from "react";

export interface UseQuestionsParams {
  page: number;
  tags?: string[]
  authors?: string[]
  limit?: number;
  search?: string;
  typeId?: string;
  privacyId?: number;
  authorId?:string;
  methodology?:number;
  tenantId?:string;
  minAge?: number;
  maxAge?: number;
  analysisTypes?:string[];
  environments?: string[];
  locale?:string;
  enabled:boolean
}

function useQuestionsSimple(params: UseQuestionsParams) {
  const [debouncedQuery, setDebouncedQuery] = useState<string>(
    params.search ?? ''
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedQuery(params.search ?? '');
    }, 500);
    return () => clearTimeout(timeout);
  }, [params.search]);

  const fetchQuestions = async (
    params: UseQuestionsParams
  ): Promise<{ data: QuestionDto[]; total: number }> => {
    const { data, headers } = await axios.get<QuestionDto[]>(`/v2/questions/paginate`, {
      params: {
        ...params,
        search: debouncedQuery,
      },
    });

    return { data: data, total: Number(headers["total"]) };
  };

  return useQuery(
    [
      `/v2/questions/paginate`,
      { ...params, search: debouncedQuery },
    ],
    () => fetchQuestions(params),
    {
      enabled:params.enabled
    }
  );
}

export default useQuestionsSimple;
