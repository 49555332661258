import { useQuery } from "react-query";
import axios from "infra/http";
import { StoryDetails } from "core/features/stories/typings";

function useStory(id?: string) {
  const fetchData = async (id: string): Promise<StoryDetails> => {
    const { data } = await axios.get<StoryDetails>(`/v2/stories/${id}`);

    return data;
  };

  return useQuery([`/v2/stories/${id}`], () => fetchData(id!), {
    enabled: !!id,
  });
}

export default useStory;
