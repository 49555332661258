import { Col, Flex, Row } from 'antd';
import SubTitle from 'components/typography/subTitle';
import Text from 'components/typography/text';
import { t } from 'core/resources/strings';
import warmHeart from 'assets/images/safespace/safespace-warm-heart.png';
import attention from 'assets/images/safespace/safespace-attention.png';
import danger from 'assets/images/safespace/safespace-danger.png';
import eyes from 'assets/images/safespace/safespae-eyes.png';
import { useMediaQuery } from '@chakra-ui/media-query';
import CustomCarousel from 'components/custom-carousel';

function SocialExpressions(expressions: {
  Problema_serio: number;
  Coracao_quentinho: number;
  Redobre_a_atencao: number;
  E_bom_ficar_de_olho: number;
  Expressoes_Problema_serio: string[];
  Expressoes_Coracao_quentinho: string[];
  Expressoes_Redobre_a_atencao: string[];
  Expressoes_E_bom_ficar_de_olho: string[];
}) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const flags = [
    {
      label: t('app.coracaoQuentinho'),
      value: expressions.Coracao_quentinho,
      expressions: expressions.Expressoes_Coracao_quentinho ?? [],
      color: '#CEEACF',
      textColor: '#336732',
      image: warmHeart,
    },
    {
      label: t('app.bomFicarDeOlho'),
      value: expressions.E_bom_ficar_de_olho,
      expressions: expressions.Expressoes_E_bom_ficar_de_olho ?? [],
      color: '#B2EBFE',
      textColor: '#0067AA',
      image: eyes,
    },
    {
      label: t('app.redobreAtencao'),
      value: expressions.Redobre_a_atencao,
      expressions: expressions.Expressoes_Redobre_a_atencao ?? [],
      color: '#FCE8C3',
      image: attention,
      textColor: '#8A4C00',
    },
    {
      label: t('app.problemaSerio'),
      value: expressions.Problema_serio,
      expressions: expressions.Expressoes_Problema_serio ?? [],
      color: '#FFCDD3',
      image: danger,
      textColor: '#BB191E',
    },
  ];
  if (isMobile) {
    return (
      <div
        className="box-shadow-generic-card"
        style={{
          padding: '16px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}
      >
        <SubTitle primary>
          {t('app.detalhesDasExpressesSocioemocionais')}
        </SubTitle>
        <CustomCarousel slidesToScroll={1} slidesToShow={1}>
          {flags.map((flag, index) => (
            <div style={{ width: '100%' }} key={index}>
              <Flex
                vertical
                style={{
                  padding: '16px 8px',
                  position: 'relative',
                  backgroundColor: flag.color,
                  borderRadius: '10px',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Text size="lg" bold align="center" color={flag.textColor}>
                  {flag.label}
                </Text>
                <Text size="lg" bold align="center" color={flag.textColor}>
                  {flag.value}
                </Text>
                {flag.expressions.map((expression, index) => (
                  <Text
                    size="md"
                    key={index}
                    color={flag.textColor}
                    style={{ zIndex: 100 }}
                  >
                    "{expression}"
                    {index !== flag.expressions.length - 1 ? ',' : ''}
                  </Text>
                ))}
              </Flex>
            </div>
          ))}
        </CustomCarousel>
      </div>
    );
  }
  return (
    <div
      className="box-shadow-generic-card"
      style={{
        padding: '16px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
      }}
    >
      <SubTitle primary>
        {t('app.detalhesDasExpressesSocioemocionais')}
      </SubTitle>
      <Row gutter={[16, 16]} align="stretch">
        {flags.map((flag, index) => (
          <Col span={6} key={index}>
            <Flex
              vertical
              style={{
                padding: '16px 8px',
                position: 'relative',
                backgroundColor: flag.color,
                borderRadius: '10px',
                height: '100%',
                overflow: 'hidden',
              }}
            >
              <Text size="lg" bold align="center" color={flag.textColor}>
                {flag.label}
              </Text>
              <Text size="lg" bold align="center" color={flag.textColor}>
                {flag.value}
              </Text>
              {flag.expressions.map((expression, index) => (
                <Text
                  size="md"
                  key={index}
                  color={flag.textColor}
                  style={{ zIndex: 100 }}
                >
                  "{expression}"
                  {index !== flag.expressions.length - 1 ? ',' : ''}
                </Text>
              ))}
            </Flex>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default SocialExpressions;
