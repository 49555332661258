import { Flex, Radio, Collapse, Space, Form } from 'antd';
import SvgIcon from 'components/SvgIcon';
import { TagType } from 'core/features/competence/typings';
import { TagsEnum } from 'core/features/graphs/enums/tags';
import useTagsWithoutParent from 'core/features/tags/hooks/useTagsWithoutParent';
import { t } from 'core/resources/strings';
import { currentLocale } from 'core/resources/strings/polyglot';
import { hex2rgba } from 'infra/helpers/hex2Rgba';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';

function PickTag({
  value,
  onChange,
  setHasTwoCompetences,
  isEditing,
}: {
  value: { id: string; name: string };
  onChange: (v: { id: string; name: string }) => void;
  setHasTwoCompetences: (v: boolean) => void;
  isEditing: boolean;
}) {
  const [selected, setSelected] = useState<string>();
  const { colorPrimary } = useU4heroColors();
  const form = Form.useFormInstance();
  const competence = Form.useWatch('competence', form);
  const tagType = Form.useWatch('tagType', form);

  const { data } = useTagsWithoutParent({
    type: tagType,
    disabled: tagType === undefined,
  });

  const antiBullying = data?.find((item) => item.id === TagsEnum.Bullying);
  const securityEducational = data?.find(
    (item) => item.id === TagsEnum.SegurancaEducacional
  );
  const autonomy = data?.find((item) => item.id === TagsEnum.AutoGestao);
  const consciousnessSocial = data?.find(
    (item) => item.id === TagsEnum.ConscienciaSocial
  );
  const relationship = data?.find(
    (item) => item.id === TagsEnum.Relacionamento
  );
  const consciousness = data?.find(
    (item) => item.id === TagsEnum.AutoConsciencia
  );

  const combined = [
    {
      label1: antiBullying?.name?.[currentLocale],
      label2: securityEducational?.name?.[currentLocale],
      value: 'combination 1',
      combination: true,
      id1: antiBullying?.id,
      icon1: antiBullying?.icon,
      id2: securityEducational?.id,
      icon2: securityEducational?.icon,
    },
    {
      label1: autonomy?.name?.[currentLocale],
      label2: consciousness?.name?.[currentLocale],
      value: 'combination 2',
      combination: true,
      id1: autonomy?.id,
      icon1: autonomy?.icon,
      id2: consciousness?.id,
      icon2: consciousness?.icon,
    },
    {
      label1: consciousnessSocial?.name?.[currentLocale],
      label2: relationship?.name?.[currentLocale],
      value: 'combination 3',
      combination: true,
      id1: consciousnessSocial?.id,
      icon1: consciousnessSocial?.icon,
      id2: relationship?.id,
      icon2: relationship?.icon,
    },
  ];

  const hasTwoCompentences = data?.find((item) => item.id === value?.id);
  const defaultActiveKey = hasTwoCompentences
    ? combined.find(
        (combination) =>
          combination.id2 === value?.id || combination.id1 === value?.id
      )?.value
    : competence?.id;

  return (
    <Flex vertical gap="8px" style={{ width: '100%' }}>
      {tagType === TagType.Competências &&
        combined?.map((combination) =>
          defaultActiveKey || !isEditing ? (
            <Collapse
              expandIconPosition="end"
              key={combination.value}
              defaultActiveKey={defaultActiveKey}
              style={{ width: '100%' }}
            >
              <Collapse.Panel
                header={
                  <Flex
                    vertical
                    gap="12px"
                    onClick={() => {
                      onChange({
                        id: combination?.id2 as string,
                        name: combination?.label2 as string,
                      });
                      form.setFieldValue('competence', {
                        id: combination.id1 as string,
                        name: combination.label1 as string,
                      });
                      form.setFieldValue('typeId', 1);
                      setSelected(combination.value);
                      setHasTwoCompetences(true);
                    }}
                    style={{ width: '100%' }}
                  >
                    <Flex gap="8px" align="center">
                      {combination.icon1 ? (
                        <SvgIcon
                          url={combination.icon1}
                          color="black"
                          size={28}
                        />
                      ) : null}
                      <p
                        style={{
                          fontSize: '14px',
                          color: 'black',
                          fontWeight: 400,
                        }}
                      >
                        {combination.label1}
                      </p>
                    </Flex>
                    <Flex gap="8px" align="center">
                      {combination.icon2 ? (
                        <SvgIcon
                          url={combination.icon2}
                          color="black"
                          size={28}
                        />
                      ) : null}
                      <p
                        style={{
                          fontSize: '14px',
                          color: 'black',
                          fontWeight: 400,
                        }}
                      >
                        {combination.label2}
                      </p>
                    </Flex>
                  </Flex>
                }
                style={{
                  background:
                    selected === combination.value ||
                    defaultActiveKey === combination.value
                      ? hex2rgba(colorPrimary, 0.5)
                      : undefined,
                }}
                key={combination.value}
              >
                <Flex style={{ padding: '8px' }}>
                  <p style={{ fontSize: '12px' }}>
                    {t('app.mensagemDuasCompetencias')}
                  </p>
                </Flex>
              </Collapse.Panel>
            </Collapse>
          ) : null
        )}
      {data?.map((item) =>
        defaultActiveKey || !isEditing ? (
          <Collapse
            defaultActiveKey={defaultActiveKey}
            style={{ width: '100%' }}
            items={[
              {
                key: item.id,
                label: (
                  <Flex gap="8px" align="center">
                    {item.icon ? (
                      <SvgIcon url={item.icon} color="black" size={28} />
                    ) : null}
                    <p
                      style={{
                        fontSize: '14px',
                        color: 'black',
                        fontWeight: 400,
                      }}
                    >
                      {item.name?.[currentLocale]}
                    </p>
                  </Flex>
                ),
                children: (
                  <Radio.Group
                    id="id-tour-question-creation-input-skill"
                    style={{ width: '100%', marginTop: '5px' }}
                    onChange={(e) => {
                      const skill = item.children.find(
                        (item) => item.id === e.target.value
                      );
                      onChange({
                        id: skill?.id as string,
                        name: skill?.name?.[currentLocale] as string,
                      });
                      form.setFieldValue('competence', {
                        id: item.id,
                        name: item.name?.[currentLocale],
                      });
                      form.setFieldValue('typeId', 0);
                      setSelected('');
                      setHasTwoCompetences(false);
                    }}
                    value={value?.id}
                  >
                    <Space direction="vertical" style={{ width: '100%' }}>
                      {item.children.map((child, index) => (
                        <Radio
                          style={{ paddingLeft: '8px', width: '100%' }}
                          value={child.id}
                          key={child.id}
                        >
                          <Flex gap="8px" align="center">
                            {child.icon ? (
                              <SvgIcon
                                url={child.icon}
                                color="black"
                                size={28}
                              />
                            ) : null}

                            {child.name?.[currentLocale]}
                          </Flex>
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>
                ),
              },
            ]}
            expandIconPosition="end"
          />
        ) : null
      )}
    </Flex>
  );
}

export default PickTag;
