import { Image } from '@chakra-ui/image';
import safeSpace from 'assets/images/icons/MeuCantinhoBrancoContorno.svg';
import { Permissions } from '../../../core/resources/enums/permissions';
import {
  MdOutlineBarChart,
  MdOutlineDashboard,
  MdOutlinePieChart,
} from 'react-icons/md';
import {
  PiHouseLineLight,
  PiPaletteLight,
  PiStudentLight,
  PiSpeedometerLight,
  PiLetterCirclePLight,
  PiQuestionLight,
  PiBrainLight,
  PiGameControllerLight,
  PiChartBarLight,
  PiBuildingApartmentLight,
  PiLightbulbFilamentLight,
  PiClipboardTextLight,
  PiProjectorScreenChartLight,
  PiTreasureChestLight,
  PiGearLight,
} from 'react-icons/pi';
import { t } from 'core/resources/strings';

export interface Menu {
  title: string;
  icon: () => JSX.Element;
  action: string;
  isActive: (pathname: string) => boolean;
  permission: string;
  tabs?: any;
  feature?: string;
}

export const MENUS: Menu[] = [
  {
    title: t('app.inicio'),
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.U4hero.Dashboard.View,
  },
  {
    title: t('app.inicio'),
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.Dashboard,
  },
  {
    title: t('app.inicio'),
    icon: () => (
      <PiHouseLineLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/',
    isActive: (pathname) => pathname === '/',
    permission: Permissions.Org.Dashboard.View,
  },
  {
    title: t('app.metricas'),
    icon: () => (
      <PiSpeedometerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/metrics',
    isActive: (pathname) => pathname.includes('/metrics'),
    permission: Permissions.U4hero.Metrics.View,
  },
  {
    title: t('app.perguntas'),
    icon: () => (
      <PiLetterCirclePLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/question',
    isActive: (pathname) => pathname.includes('/questions'),
    permission: Permissions.U4hero.Questions.View,
  },
  {
    title: t('app.minhaEscola'),
    icon: () => (
      <PiStudentLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/my-school',
    isActive: (pathname) => pathname.includes('/my-school'),
    permission: Permissions.Tenant.View,
  },
  {
    title: t('app.organizacao'),
    icon: () => (
      <PiStudentLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/my-school',
    isActive: (pathname) => pathname.includes('/my-school'),
    permission: Permissions.Org.Tenant.View,
  },
  {
    title: t('app.jogos'),
    icon: () => (
      <PiGameControllerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/games',
    isActive: (pathname) => pathname.includes('/games'),
    permission: Permissions.Games.View,
  },
  {
    title: t('app.jogos'),
    icon: () => (
      <PiGameControllerLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/games-u4hero',
    isActive: (pathname) => pathname.includes('/games-u4hero'),
    permission: Permissions.U4hero.Games.View,
  },
  {
    title: t('app.perguntas'),
    icon: () => (
      <PiLetterCirclePLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/question',
    isActive: (pathname) => pathname.includes('/question'),
    permission: Permissions.CustomQuestion.View,
  },
  {
    title: t('app.atividades'),
    icon: () => (
      <PiClipboardTextLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/activities',
    isActive: (pathname) => pathname.includes('/activities'),
    permission: Permissions.Activity.View,
  },
  {
    title: t('app.visaoGeral'),
    icon: () => (
      <MdOutlineDashboard
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/macro-view',
    isActive: (pathname) => pathname.includes('/inkluzen/macro-view'),
    permission: Permissions.Inkluzen.Macro.View,
  },
  {
    title: t('app.explorar'),
    icon: () => (
      <MdOutlineBarChart
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/exploration-view',
    isActive: (pathname) => pathname.includes('/inkluzen/exploration-view'),
    permission: Permissions.Inkluzen.Geographic.View,
  },
  {
    title: t('app.detalhes'),
    icon: () => (
      <MdOutlinePieChart
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/inkluzen/students',
    isActive: (pathname) => pathname.includes('/inkluzen/students'),
    permission: Permissions.Inkluzen.Student.View,
  },
  {
    title: t('app.meuCantinho'),
    icon: () => <Image w="3vh" src={safeSpace} />,
    action: '/safe-space',
    isActive: (pathname) => pathname.includes('/safe-space'),
    permission: Permissions.SafeSpace.View,
    feature: 'Meu cantinho',
  },
  {
    title: t('app.indicadores'),
    icon: () => (
      <PiChartBarLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/indicators',
    isActive: (pathname) => pathname.includes('/indicators'),
    permission: Permissions.Indicators.View,
  },
  {
    title: t('app.indicadores'),
    icon: () => (
      <PiChartBarLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/indicators',
    isActive: (pathname) => pathname.includes('/indicators'),
    permission: Permissions.Org.Indicators.View,
  },
  {
    title: t('app.competencias'),
    icon: () => (
      <PiBrainLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/competences',
    isActive: (pathname) => pathname.includes('/competences'),
    permission: Permissions.U4hero.Competences.Edit,
  },
  {
    title: t('app.instituicoes'),
    icon: () => (
      <PiBuildingApartmentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/schools?tab=0',
    isActive: (pathname) => pathname.includes('/schools'),
    permission: Permissions.U4hero.Tenant.Edit,
  },
  {
    title: t('app.instituicoes'),
    icon: () => (
      <PiBuildingApartmentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/schools?tab=0',
    isActive: (pathname) => pathname.includes('/schools'),
    permission: Permissions.Org.Tenant.Edit,
  },
  {
    title: t('app.inspiracoes'),
    icon: () => (
      <PiLightbulbFilamentLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/practices',
    isActive: (pathname) => pathname.includes('/practices'),
    permission: Permissions.Practices.View,
  },
  {
    title: t('app.conteudos'),
    icon: () => <PiPaletteLight color="white" size={'3vh'} />,
    action: '/posts-hub',
    isActive: (pathname) => pathname.includes('posts'),
    permission: Permissions.U4hero.Posts.View,
  },
  {
    title: t('app.loja'),
    icon: () => (
      <PiTreasureChestLight
        color="white"
        style={{ width: '3vh', height: '3vh' }}
      />
    ),
    action: '/manager-store',
    isActive: (pathname) => pathname.includes('/manager-store'),
    permission: Permissions.U4hero.Posts.View,
  },
  {
    title: t('app.configuracoes'),
    icon: () => (
      <PiGearLight color="white" style={{ width: '3vh', height: '3vh' }} />
    ),
    action: '/settings-u4hero?tab=1',
    isActive: (pathname) => pathname.includes('/settings-u4hero'),
    permission: Permissions.U4hero.Tenant.Edit,
  },
  /*
  {
    title: "Configurações",
    icon: () => <Image w="25px" src={jogosImage} />,
    action: "/settings",
    isActive: (pathname) => pathname.includes("/settings"),
    permission: Permissions.Settings
  },*/
];
