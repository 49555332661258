import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Form, Input, Row } from 'antd';
import Button from 'components/buttons/button';
import Text from 'components/typography/text';
import { GenericCard } from 'components/V2/generic-card-styled-component';
import { t } from 'core/resources/strings';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState, useEffect } from 'react';
import ChangeDirectorModal from 'ui/pages/app/mySchool/pages/SchoolProfile/components/ChangeDiretorModal';

const generateTempPassword = () => {
  const length = 12;
  const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const lowercase = 'abcdefghijklmnopqrstuvwxyz';
  const numbers = '0123456789';
  const special = '!@#$%^&*()';

  let password = '';

  password += uppercase[Math.floor(Math.random() * uppercase.length)];
  password += lowercase[Math.floor(Math.random() * lowercase.length)];
  password += numbers[Math.floor(Math.random() * numbers.length)];
  password += special[Math.floor(Math.random() * special.length)];

  const allChars = uppercase + lowercase + numbers + special;
  for (let i = password.length; i < length; i++) {
    password += allChars[Math.floor(Math.random() * allChars.length)];
  }

  return password
    .split('')
    .sort(() => Math.random() - 0.5)
    .join('');
};

function ContactInfoSection({ tenantId }: { tenantId?: string }) {
  const { colorPrimary } = useU4heroColors();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const form = Form.useFormInstance();
  const tempMode = Form.useWatch('tempMode', form);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (tempMode && !tenantId) {
      form.setFieldValue('tempPassword', generateTempPassword());
    }
  }, [tempMode, form, tenantId]);

  return (
    <>
      {isOpenModal ? (
        <ChangeDirectorModal
          tenantId={tenantId}
          onClose={() => setIsOpenModal(false)}
        />
      ) : null}
      <GenericCard style={{ position: 'relative' }}>
        {tenantId ? (
          <Button
            size="sm"
            style={{ position: 'absolute', right: 16, top: 16 }}
            variant="outline"
            onClick={() => setIsOpenModal(true)}
          >
            TROCAR DIREÇÃO
          </Button>
        ) : null}

        <Flex vertical style={{ width: '100%' }}>
          <Text
            size="lg"
            color={colorPrimary}
            style={{ marginBottom: '16px', fontWeight: 700 }}
          >
            Direção
          </Text>
          <Form.Item
            label="Nome do Diretor"
            name="adminName"
            rules={[
              {
                required: !tempMode,
                message: 'Por favor, insira o nome do diretor!',
              },
            ]}
          >
            <Input
              id="id-tour-instituitions-create-input-admin-name"
              placeholder="Insira o nome do diretor"
              disabled={tenantId !== undefined || tempMode}
              size={isMobile ? 'middle' : 'large'}
            />
          </Form.Item>
          <Form.Item
            label="E-mail"
            rules={[
              {
                required: !tempMode,
                message: 'Por favor, insira o e-mail do diretor!',
              },
            ]}
            name="adminEmail"
          >
            <Input
              type="email"
              style={{
                width: '100%',
              }}
              size={isMobile ? 'middle' : 'large'}
              id="id-tour-instituitions-create-input-admin-email"
              placeholder="Insira o e-mail do diretor"
              disabled={tenantId !== undefined || tempMode}
            />
          </Form.Item>

          {tempMode && (
            <Row
              gutter={[
                { xs: 0, md: 32 },
                { xs: 0, md: 0 },
              ]}
            >
              <Col xs={24} md={12}>
                <Form.Item
                  label={t('app.senhaDeAcesso')}
                  name="tempPassword"
                  initialValue=""
                >
                  <Input
                    id="id-tour-schools-create-temp-password"
                    size={isMobile ? 'middle' : 'large'}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Flex>
      </GenericCard>
    </>
  );
}

export default ContactInfoSection;
