import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import Button from 'components/buttons/button';
import ModalTag from 'components/modals/modal-tag';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import SkillsTable from '../../SkillsTable';

function SkillTab() {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [id, setId] = useState<string>();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const history = useHistory();

  return (
    <Flex vertical gap="16px">
      {isOpenModal ? (
        <ModalTag
          id={id}
          type="skill"
          onClose={() => {
            setId(undefined);
            setIsOpenModal(false);
          }}
        />
      ) : null}
      <Flex gap="16px" justify="end">
        <Button
          size="md"
          id="id-tour-create-tour-initial-tour"
          variant="outline"
          style={{
            display: 'flex',
            gap: isMobile ? '4px' : '16px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onClick={() => history.push('/competences/adaptative-mode-order')}
        >
          <FiSettings size={isMobile ? 16 : 28} color={colorPrimary} />
          ORDEM ADAPTATIVA
        </Button>
        <Button
          id="id-tour-create-skill-button-add-skill"
          size={'md'}
          onClick={() => setIsOpenModal(true)}
          style={{
            alignSelf: 'end',
          }}
        >
          CRIAR HABILIDADE
        </Button>
      </Flex>
      <SkillsTable
        onEdit={(v) => {
          setId(v);
          setIsOpenModal(true);
        }}
      />
    </Flex>
  );
}

export default SkillTab;
