import { useMediaQuery } from '@chakra-ui/react';
import { Flex } from 'antd';
import React from 'react';
import defaultIcon from 'assets/images/default-icon-avatar.png';
import defaultBackground from 'assets/images/default-background.png';
import defaultFrame from 'assets/images/default-frame.png';

interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  frame?: string;
  icon: string;
  background?: string;
  size: 'large' | 'medium' | 'small';
}

const U4Avatar: React.FC<AvatarProps> = ({
  frame,
  icon,
  background,
  size,
  style,
  ...divProps
}) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const dimensions = {
    large: { box: isMobile ? 80 : 112, img: isMobile ? 68 : 100 },
    medium: { box: isMobile ? 60 : 80, img: isMobile ? 48 : 68 },
    small: { box: isMobile ? 40 : 50, img: isMobile ? 28 : 38 },
  };
  const dimension = dimensions[size];

  return (
    <Flex
      style={{
        width: dimension.box,
        height: dimension.box,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        ...style,
      }}
      {...divProps}
    >
      {background && (
        <img
          src={background}
          draggable={false}
          style={{
            position: 'absolute',
            zIndex: 10,
            width: dimension.img,
            height: dimension.img,
            borderRadius: '50%',
          }}
        />
      )}

      <img
        src={icon}
        draggable={false}
        style={{
          position: 'absolute',
          zIndex: 20,
          width: dimension.img,
          height: dimension.img,
          borderRadius: '50%',
        }}
      />

      {frame && (
        <img
          src={frame}
          draggable={false}
          style={{
            position: 'absolute',
            zIndex: 30,
            width: dimension.box,
            height: dimension.box,
          }}
        />
      )}
    </Flex>
  );
};

export default U4Avatar;
