import { Flex } from 'antd';
import Title from 'components/typography/title';
import Text from 'components/typography/text';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import leftDecoration from 'assets/images/report/report-decoration-top-left.png';
import rightDecoration from 'assets/images/report/report-decoration-top-right.png';
import book from 'assets/images/report/book.png';
import ReportPage from '../page';
import { t } from 'core/resources/strings';
import { useCurrentUser } from 'core/features/user/store';

export default function ReportGuide() {
  const { colorPrimary, colorSecondary } = useU4heroColors();
  const { user } = useCurrentUser();

  return (
    <>
      <ReportPage pageNumber={11}>
        <img
          src={leftDecoration}
          alt="left-decoration"
          style={{
            position: 'absolute',
            top: 60,
            left: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <img
          src={rightDecoration}
          alt="right-decoration"
          style={{
            position: 'absolute',
            top: 70,
            right: 0,
            filter: user?.whiteLabel ? 'grayscale(100%)' : undefined,
          }}
        />
        <Flex vertical align="center" justify="center">
          <Title primary style={{ textTransform: 'uppercase' }} align="center">
            {t('app.guiaParaInterpretarOsResultadosDesteRelatorio')}
          </Title>
          <img src={book} alt="Icone livro" width={68} />
        </Flex>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.guiaExplicacao')}
        </Text>
        <Title primary style={{ textTransform: 'uppercase' }}>
          {t('app.resumo')}
        </Title>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.resumoExplicacao')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.principaisDescobertas')}
          </span>{' '}
          {t('app.principaisDescobertasExplicacao')}
        </Text>
      </ReportPage>

      <ReportPage pageNumber={12}>
        <Text
          size="md"
          align="justify"
          style={{ margin: '0 auto', lineHeight: '40px' }}
        >
          {t('app.precisamDeMaisFoco')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.categoriasDeAnalise')}
          </span>{' '}
          {t('app.categoriasDeAnaliseExplicacao')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.interpretacaoDosResultados')}
          </span>{' '}
          {t('app.interpretacaoDosResultadosExplicacao')}
          <br />
          <br />
          <span style={{ fontWeight: 'bold', color: colorSecondary }}>
            {t('app.conclusao')}
          </span>{' '}
          {t('app.conclusaoExplicacao')}
        </Text>
      </ReportPage>
    </>
  );
}
