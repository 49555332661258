import { useMediaQuery } from '@chakra-ui/react';
import { Col, Flex, Pagination, Row, Spin } from 'antd';
import AppliedFilters from 'components/applied-filters';
import SearchBar from 'components/V2/SearchBar';
import useStudentsV2 from 'core/features/students/hooks/useStudentsV2';
import { FilterType } from 'infra/helpers/types';
import useU4heroColors from 'infra/packages/antd/u4heroTokens';
import { useState } from 'react';
import { useParams } from 'react-router';
import StudentCard from 'ui/pages/app/indicators/tabs/components/studentCard';
import StudentFilter from 'ui/pages/app/mySchool/pages/Students/components/StudentFilter';

function StudentView() {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const { colorPrimary } = useU4heroColors();
  const { tenantId } = useParams<{ tenantId: string }>();

  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(8);

  const [filters, setFilters] = useState<FilterType>({
    types: [],
    series: [],
    classes: [],
    lastAccess: [],
  });

  const { data, isLoading, isFetching, refetch } = useStudentsV2({
    page,
    search,
    series: filters?.series?.map((item: any) => item.value) ?? undefined,
    classes: filters?.classes?.map((item: any) => item.value) ?? undefined,
    disableConcat: true,
    deleted: filters.types.map((item) => item.value).includes('deleted'),
    active: filters.types.map((item) => item.value).includes('active'),
    classId: filters.types.map((item) => item.value).includes('without-class')
      ? 'null'
      : undefined,
    limit: pageSize,
    tenantId,
    lastAccess: filters?.lastAccess.map((item) => item.value)[0] ?? undefined,
  });

  return (
    <Flex vertical gap={16}>
      <div>
        <Flex
          align="center"
          gap="16px"
          style={{ marginBottom: '16px', width: '100%' }}
        >
          <SearchBar width="100%" value={search} onSearch={setSearch} />
          <StudentFilter filters={filters} onChangeFilters={setFilters} />
        </Flex>
        <AppliedFilters appliedFilters={filters} onChangeFilters={setFilters} />
      </div>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Row
          gutter={[
            { xs: 0, md: 20 },
            { xs: 24, md: 20 },
          ]}
        >
          {data?.data.map((student) => (
            <Col xs={24} md={6}>
              <StudentCard {...student} />
            </Col>
          ))}
        </Row>
      )}

      <Flex justify="center" style={{ marginBottom: '18px' }}>
        <Pagination
          defaultCurrent={1}
          showSizeChanger
          current={page}
          pageSize={pageSize}
          total={data?.total}
          onChange={(v, size) => {
            setPage(v);
            setPageSize(size);
          }}
        />
      </Flex>
    </Flex>
  );
}

export default StudentView;
